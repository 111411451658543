<template>
    <section class="HealthCareShow pt-1 pb-5">
        <!-- <PagesBackground :hexColor="navbarFillHexColor" /> -->
        <div class="container p-1">
            <h1 class="my-4 title">{{ $t("healthCareDetails") }}</h1>
            <div
                class="row d-flex justify-content-center my-5 text-center"
                v-if="noData"
            >
                <h1 style="height: 290px">{{ $t("noData") }}</h1>
                <router-link to="/" class="btn btn-danger w-100">{{
                    $t("backToHome")
                }}</router-link>
            </div>
            <div class="row">
                <div class="col-lg-6 my-2 text-center">
                    <div class="postImage">
                        <img
                            :src="$filters.getImageUrl(healthcare.image)"
                            alt="img"
                        />
                    </div>
                </div>
                <div class="col-lg-6 my-2 p-3">
                    <div
                        class="admin"
                        v-if="this.$store.getters['auth/isLoggedIn']"
                    >
                        <router-link
                            :to="{
                                name: 'HealthCareEdit',
                                params: { id: `${this.$route.params.id}` },
                            }"
                            v-if="this.$store.getters['auth/isLoggedIn']"
                            class="btn btn-secondary w-100 mt-2 mb-4"
                            style="display: block"
                            >{{ $t("editNews") }}</router-link
                        >
                        <button
                            @click="deleteHealthCare"
                            class="btn btn-danger w-100 mt-2 mb-4"
                            style="display: block"
                            :disabled="isLoading"
                            v-if="this.$store.getters['auth/isLoggedIn']"
                        >
                            {{ $t("deleteHealthCare") }}
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                    </div>
                    <h2>
                        <b>
                            {{
                                healthcare[
                                    "title" +
                                        $filters.ucFirst(
                                            $store.getters["language/language"]
                                        )
                                ]
                            }}
                        </b>
                    </h2>
                    <br />
                    <small>{{ $filters.timeConverter(healthcare.date) }}</small>
                    <br /><br />
                    <p
                        v-html="
                            healthcare[
                                'desc' +
                                    $filters.ucFirst(
                                        $store.getters['language/language']
                                    )
                            ]
                        "
                        class="postDesc"
                    ></p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import app from "@/firebase";
//login
import { getFirestore, getDoc, doc, deleteDoc } from "firebase/firestore/lite";
import PagesBackground from "@/components/PagesBackground.vue";
import { deleteObject, getStorage, ref } from "@firebase/storage";

export default {
    name: "HealthCareShow",
    props: ["navbarFillHexColor"],
    data() {
        return {
            healthcare: {},
            noData: false,
            isLoading: false,
            error: false,
        };
    },
    methods: {
        async deleteHealthCare() {
            this.isLoading = true;
            const db = getFirestore(app);
            const docRef = doc(db, "healthcare", this.$route.params.id);
            try {
                const storage = getStorage(app);
                await deleteObject(ref(storage, this.healthcare.image));
            } catch (error) {
                console.log(error);
            }
            const docSnap = await deleteDoc(docRef)
                .then(() => {
                    this.isLoading = false;
                    this.$router.push({
                        name: "HealthCareList",
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.isLoading = false;
                    this.error = true;
                });
        },
    },
    async mounted() {
        const db = getFirestore(app);
        const docRef = doc(db, "healthcare", this.$route.params.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            this.healthcare = docSnap.data();
        } else {
            this.noData = true;
        }
    },
    components: { PagesBackground },
};
</script>

<style scoped>
.HealthCareShow {
    position: relative;
}

svg {
    filter: drop-shadow(0px 0px 27px #ddd8d8);
}
.title {
    color: #e30613;
    font-weight: 900;
    font-size: 65px;
}
.postImage {
    height: 400px;
}
.postImage img {
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    height: 400px;
}
.postDesc {
    word-break: break-all !important;
}

@media only screen and (max-width: 600px) {
    .HealthCareShow {
        overflow-x: hidden;
    }
    .title {
        font-size: 35px;
    }
}
</style>
