<template>
    <section class="sliders pb-5 pt-1">
        <div class="container">
            <div class="d-flex justify-content-between">
                <h1 class="text-start my-5 d-inline-block">
                    {{ $t("sliderU") }}
                </h1>
                <h1 class="text-start my-5 d-inline-block">
                    <router-link
                        :to="{
                            name: 'SliderAdd',
                        }"
                        class="btn btn-secondary"
                        style="position: relative; z-index: 8"
                    >
                        {{ $t("addSlider") }}
                    </router-link>
                </h1>
            </div>
            <div class="row" v-if="sliders.length < 1">
                <div class="col-lg-12 d-flex justify-content-center">
                    <h1>{{ $t("noData") }}</h1>
                </div>
            </div>
            <div class="row" v-else>
                <div
                    class="col-lg-12"
                    v-if="this.$store.getters['auth/isLoggedIn']"
                >
                    <!-- <router-link
            :to="{
              name: 'SliderEdit',
              params: { id: item.id },
            }"
          > -->
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">{{ $t("no") }}</th>
                                    <th scope="col">
                                        {{ $t("sliderPosition") }}
                                    </th>
                                    <th scope="col">{{ $t("sliderImg") }}</th>
                                    <th scope="col">{{ $t("editSlider") }}</th>
                                    <th scope="col">
                                        {{ $t("deleteSlider") }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(item, index) in sliders.slice(
                                        _start,
                                        _end
                                    )"
                                    :key="index"
                                >
                                    <th>{{ item.id }}</th>
                                    <td>{{ item.slidePositionNum }}</td>
                                    <td>
                                        <img
                                            :src="
                                                $filters.getImageUrl(item.image)
                                            "
                                            width="150"
                                            alt="si"
                                        />
                                    </td>
                                    <td>
                                        <router-link
                                            :to="{
                                                name: 'SliderEdit',
                                                params: { id: item.id },
                                            }"
                                            class="btn btn-warning"
                                        >
                                            {{ $t("editSlider") }}
                                        </router-link>
                                    </td>
                                    <td>
                                        <button
                                            class="btn btn-danger"
                                            @click="deleteProduct(item)"
                                        >
                                            {{ $t("deleteSlider") }}
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- </router-link> -->
                </div>
            </div>
            <div class="row my-5">
                <div
                    class="col-lg-6 d-flex justify-content-center text-center col-6"
                >
                    <button
                        class="btn btn-danger"
                        :disabled="_start == 0"
                        @click="prevPagination"
                    >
                        {{ $t("prev") }}
                    </button>
                </div>
                <div
                    class="col-lg-6 d-flex justify-content-center text-center col-6"
                >
                    <button
                        class="btn btn-danger"
                        :disabled="_end >= sliders.length"
                        @click="nextPagination"
                    >
                        {{ $t("next") }}
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {
    doc,
    getDocs,
    getFirestore,
    collection,
    deleteDoc,
} from "firebase/firestore";
import { app } from "@/firebase";
import { deleteObject, getStorage, ref } from "@firebase/storage";

export default {
    name: "SliderList",
    data() {
        return {
            sliders: [],
            _start: 0,
            _end: 12,
            perPage: 12,
        };
    },
    async mounted() {
        const db = getFirestore(app);
        const docSnap = await getDocs(collection(db, "slider"));
        this.sliders = docSnap.docs.map((e) => {
            return e.data();
        });
        this.sliders.sort((b, a) =>
            a.slidePositionNum > b.slidePositionNum
                ? 1
                : b.slidePositionNum > a.slidePositionNum
                ? -1
                : 0
        );
    },
    methods: {
        async deleteProduct(e) {
            this.isLoading = true;
            const db = getFirestore(app);
            const docRef = doc(db, "slider", e.id);
            try {
                const storage = getStorage(app);
                await deleteObject(ref(storage, e.image));
            } catch (error) {}
            const docSnap = await deleteDoc(docRef)
                .then(async () => {
                    this.isLoading = false;
                    const db = getFirestore(app);
                    const docSnap = await getDocs(collection(db, "slider"));
                    if (docSnap.docs != undefined) {
                        this.sliders = docSnap.docs.map((e) => {
                            return e.data();
                        });
                        this.sliders.sort((b, a) =>
                            a.slidePositionNum > b.slidePositionNum
                                ? 1
                                : b.slidePositionNum > a.slidePositionNum
                                ? -1
                                : 0
                        );
                    } else {
                        this.sliders = [];
                    }
                })
                .catch((err) => {
                    console.error(err);
                    this.isLoading = false;
                    this.error = true;
                });
        },
        nextPagination() {
            this._start += this.perPage;
            this._end = this._start + this.perPage;
        },
        prevPagination() {
            this._start -= this.perPage;
            this._end -= this.perPage;
        },
    },
};
</script>

<style scoped>
.title {
    color: #e30613;
    font-weight: 900;
    font-size: 95px;
}
@media only screen and (max-width: 600px) {
    .title {
        font-size: 35px;
    }
}
</style>
