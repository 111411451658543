<template>
    <div
        class="footer pt-5"
        :style="[
            this.$helpers.checkIsNotNull(footerFillHexColor)
                ? 'background-color: ' + footerFillHexColor + '!important'
                : '#e30613',
        ]"
    >
        <!--Waves start-->
        <div>
            <svg
                class="waves"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="100vw"
                viewBox="0 24 150 28"
                preserveAspectRatio="none"
                shape-rendering="auto"
            >
                <defs>
                    <path
                        id="gentle-wave"
                        d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                    />
                </defs>
                <g class="parallax">
                    <use
                        xlink:href="#gentle-wave"
                        x="48"
                        y="0"
                        fill="rgba(255,255,255,0.7"
                    />
                    <use
                        xlink:href="#gentle-wave"
                        x="48"
                        y="3"
                        fill="rgba(255,255,255,0.5)"
                    />
                    <use
                        xlink:href="#gentle-wave"
                        x="48"
                        y="5"
                        fill="rgba(255,255,255,0.3)"
                    />
                    <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
                </g>
            </svg>
        </div>
        <!--Waves end-->

        <div class="container">
            <h2
                class="my-4 pt-5 centerOnMobile"
                style="font-weight: 900"
                :style="[
                    footerAllTextColor
                        ? `color:${footerAllTextColor} !important`
                        : `color: white `,
                ]"
                data-aos="fade-in"
                data-aos-duration="800"
            >
                "{{ $t("footerText") }}"
            </h2>
            <div class="row pt-3 d-flex justify-content-center sm">
                <div class="col-lg-8 d-flex hideOnMObile">
                    <router-link :to="{ name: 'RecipesList' }"
                        ><p
                            class="px-2"
                            :style="[
                                footerAllTextColor
                                    ? `color:${footerAllTextColor} !important`
                                    : `color: white `,
                            ]"
                            data-aos="fade-up"
                            data-aos-duration="1300"
                        >
                            {{ $t("recipes") }}
                        </p></router-link
                    >
                    <router-link :to="{ name: 'NewsList' }"
                        ><p
                            class="px-2"
                            :style="[
                                footerAllTextColor
                                    ? `color:${footerAllTextColor} !important`
                                    : `color: white `,
                            ]"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                        >
                            {{ $t("news") }}
                        </p></router-link
                    >
                    <router-link :to="{ name: 'VideoList' }"
                        ><p
                            class="px-2"
                            :style="[
                                footerAllTextColor
                                    ? `color:${footerAllTextColor} !important`
                                    : `color: white `,
                            ]"
                            data-aos="fade-up"
                            data-aos-duration="1300"
                        >
                            {{ $t("videos") }}
                        </p></router-link
                    >
                    <router-link :to="{ name: 'HealthCareList' }"
                        ><p
                            class="px-2"
                            :style="[
                                footerAllTextColor
                                    ? `color:${footerAllTextColor} !important`
                                    : `color: white `,
                            ]"
                            data-aos="fade-up"
                            data-aos-duration="1600"
                        >
                            {{ $t("healthCare") }}
                        </p></router-link
                    >
                    <router-link :to="{ name: 'JobsList' }"
                        ><p
                            class="px-2"
                            :style="[
                                footerAllTextColor
                                    ? `color:${footerAllTextColor} !important`
                                    : `color: white `,
                            ]"
                            data-aos="fade-up"
                            data-aos-duration="1900"
                        >
                            {{ $t("careers") }}
                        </p></router-link
                    >
                    <router-link :to="{ name: 'ContactUs' }"
                        ><p
                            class="px-2"
                            :style="[
                                footerAllTextColor
                                    ? `color:${footerAllTextColor} !important`
                                    : `color: white `,
                            ]"
                            data-aos="fade-up"
                            data-aos-duration="2000"
                        >
                            {{ $t("contactUs") }}
                        </p></router-link
                    >
                    <router-link :to="{ name: 'AboutUs' }"
                        ><p
                            class="px-2"
                            :style="[
                                footerAllTextColor
                                    ? `color:${footerAllTextColor} !important`
                                    : `color: white `,
                            ]"
                            data-aos="fade-up"
                            data-aos-duration="2300"
                        >
                            {{ $t("aboutUs") }}
                        </p></router-link
                    >
                </div>
                <div class="col-lg-4 d-flex justify-content-end centerOnMobile">
                    <a
                        href="https://www.facebook.com/AlenGroup"
                        target="_blank"
                        class="px-3"
                        :style="[
                            footerAllTextColor
                                ? `color:${footerAllTextColor} !important`
                                : `color: white `,
                        ]"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        ><i class="fa-brands fa-facebook fa-xl"></i
                    ></a>
                    <!-- <a href="https://www.facebook.com/AlenGroup" target="_blank" class="px-3"><i class="fa-brands fa-twitter fa-xl"></i></a> -->
                    <a
                        href="https://www.instagram.com/alen_factory/?hl=en"
                        target="_blank"
                        class="px-3"
                        :style="[
                            footerAllTextColor
                                ? `color:${footerAllTextColor} !important`
                                : `color: red `,
                        ]"
                        data-aos="fade-up"
                        data-aos-duration="1300"
                        ><i class="fa-brands fa-instagram fa-xl"></i
                    ></a>
                    <a
                        href="https://www.youtube.com/@alenfactory"
                        target="_blank"
                        class="px-3"
                        :style="[
                            footerAllTextColor
                                ? `color:${footerAllTextColor} !important`
                                : `color: red `,
                        ]"
                        data-aos="fade-up"
                        data-aos-duration="1600"
                        ><i class="fa-brands fa-youtube fa-xl"></i
                    ></a>
                    <a
                        href="https://iq.linkedin.com/company/alenfactory?original_referer=https%3A%2F%2Fwww.google.com%2F"
                        target="_blank"
                        class="px-3"
                        :style="[
                            footerAllTextColor
                                ? `color:${footerAllTextColor} !important`
                                : `color: red `,
                        ]"
                        data-aos="fade-up"
                        data-aos-duration="1900"
                        ><i class="fa-brands fa-linkedin fa-xl"></i
                    ></a>
                    <img
                        alt="lang"
                        @click.prevent="changeLanguage('en')"
                        class="mx-3 mb-4 lang animate__animated animate__fadeIn"
                        width="22"
                        style="border-radius: 100px !important"
                        src="../assets/images/flags/en.svg"
                        data-aos="fade-up"
                        data-aos-duration="2100"
                    />
                    <img
                        alt="lang"
                        @click.prevent="changeLanguage('ar')"
                        class="mx-3 mb-4 lang animate__animated animate__fadeIn"
                        width="22"
                        style="border-radius: 100px !important"
                        src="../assets/images/flags/ar.svg"
                        data-aos="fade-up"
                        data-aos-duration="2300"
                    />
                    <img
                        alt="lang"
                        @click.prevent="changeLanguage('ku')"
                        class="mx-3 mb-4 lang animate__animated animate__fadeIn"
                        width="22"
                        style="border-radius: 100px !important"
                        src="../assets/images/flags/ku.svg"
                        data-aos="fade-up"
                        data-aos-duration="2500"
                    />
                </div>
            </div>
            <div class="row pt-5 rowFooter" style="direction: ltr">
                <!-- <div class="col-lg-6 col-md-6 d-flex centerOnMobile">
                    <img
                        alt="lang"
                        @click.prevent="changeLanguage('en')"
                        class="mx-1 lang animate__animated animate__fadeIn"
                        width="18"
                        src="../assets/images/flags/en.svg"
                    />
                    <img
                        alt="lang"
                        @click.prevent="changeLanguage('ar')"
                        class="mx-1 lang animate__animated animate__fadeIn"
                        width="18"
                        src="../assets/images/flags/ar.svg"
                    />
                    <img
                        alt="lang"
                        @click.prevent="changeLanguage('ku')"
                        class="mx-1 lang animate__animated animate__fadeIn"
                        width="18"
                        src="../assets/images/flags/ku.svg"
                    />
                </div> -->
                <div
                    class="col-lg-12 col-md-12 centerOnMobile d-flex justify-content-end"
                >
                    <a
                        href="https://tech-eye.com/"
                        class="px-3 pt-4"
                        target="_blank"
                        style="color: red !important; font-size: 15px"
                    >
                        {{ $t("cr") }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import footerBack from "../assets/images/footerBack.png";
export default {
    name: "Footer",
    props: ["footerFillHexColor", "footerAllTextColor"],
    data() {
        return {
            footerBack,
        };
    },
    methods: {
        changeLanguage(e) {
            document.body.setAttribute("transition-style", "in:circle:center");
            setTimeout(() => {
                document.body.removeAttribute("transition-style");
            }, 500);
            this.$i18n.locale = e;
            this.$store.dispatch("language/CHANGE_LANGUAGE", e).then(() => {
                this.lang =
                    this.$store.getters["language/language"].toUpperCase();
            });
            if (
                document
                    .getElementById("navbarToggleExternalContent")
                    .classList.contains("show")
            ) {
                document
                    .getElementById("navbarToggleExternalContent")
                    .classList.remove("show");
            }
        },
    },
};
</script>

<style scoped src="../assets/css/footer/footer.css"></style>
