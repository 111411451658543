<template>
    <div class="Edit">
        <div class="container">
            <div
                class="row d-flex justify-content-center my-5 text-center"
                v-if="noData"
            >
                <h1 style="height: 290px">{{ $t("noData") }}</h1>
                <router-link to="/" class="btn btn-danger w-100">{{
                    $t("backToHome")
                }}</router-link>
            </div>
            <div v-else class="row d-flex justify-content-center">
                <div class="col-lg-8">
                    <br /><br /><br /><br /><br />
                    <h1>{{ $t("editVideo") }}</h1>
                    <form @submit.prevent="edit">
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("videoTitleKu") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="descKu"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("videoTitleAr") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="descAr"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("videoTitleEn") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="descEn"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="link" class="form-label">{{
                                $t("videoURL")
                            }}</label>
                            <input
                                type="url"
                                v-model="videoURL"
                                class="form-control"
                                id="link"
                            />
                        </div>

                        <button
                            type="submit"
                            class="btn btn-primary w-100"
                            :disabled="isLoading"
                        >
                            Edit
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                        <br /><br /><br />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { app } from "@/firebase";
import { uid } from "uid";
//login
import {
    getFirestore,
    collection,
    getDoc,
    doc,
    Timestamp,
    setDoc,
} from "firebase/firestore/lite";
import { getStorage, uploadBytes, getDownloadURL, ref } from "firebase/storage";

export default {
    name: "VideoEdit",
    data() {
        return {
            descKu: "",
            descAr: "",
            descEn: "",
            videoURL: "",
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            isLoading: false,
            error: false,
            noData: false,
        };
    },
    methods: {
        async edit() {
            this.isLoading = true;

            var rx =
                /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
            const a = this.videoURL.match(rx);

            const db = getFirestore(app);
            let id = this.$route.params.id;
            const groceriesColRef = doc(collection(db, "videos"), id);
            return setDoc(groceriesColRef, {
                id: id,
                descKu: this.descKu,
                descAr: this.descAr,
                descEn: this.descEn,
                videoID: a[1],
                date: Timestamp.now(),
            })
                .then(() => {
                    this.isLoading = false;
                    this.$router.push({
                        name: "VideoList",
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.isLoading = false;
                    this.error = false;
                });
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.img = files[0];
        },
    },
    async mounted() {
        const db = getFirestore(app);

        const docRef = doc(db, "videos", this.$route.params.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            this.descKu = data.descKu;
            this.descEn = data.descEn;
            this.descAr = data.descAr;
            this.videoURL = `https://www.youtube.com/embed/${data.videoID}`;
        } else {
            // doc.data() will be undefined in this case
            // console.log("No such document!");
            this.noData = true;
        }
    },
};
</script>

<style></style>
