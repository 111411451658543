<template>
    <div class="add">
        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-8">
                    <br /><br /><br /><br /><br />
                    <h1>{{ $t("settings") }}</h1>
                    <br /><br />
                    <form @submit.prevent="save">
                        <!-- <div class="mb-3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-lg-4">
                                    <label
                                        for="titleDesignKu"
                                        class="form-label"
                                        >{{
                                            $t("recipesDesignTitleKu")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="titleDesignKu"
                                        class="form-control"
                                        id="titleDesignKu"
                                    />
                                </div>

                                <div class="col-lg-4">
                                    <label
                                        for="titleDesignAr"
                                        class="form-label"
                                        >{{
                                            $t("recipesDesignTitleAr")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="titleDesignAr"
                                        class="form-control"
                                        id="titleDesignAr"
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <label
                                        for="titleDesignEn"
                                        class="form-label"
                                        >{{
                                            $t("recipesDesignTitleEn")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="titleDesignEn"
                                        class="form-control"
                                        id="titleDesignEn"
                                    />
                                </div>
                            </div>
                        </div> -->
                        <div class="mb-3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-lg-4">
                                    <label
                                        for="subTitleDesignKu"
                                        class="form-label"
                                        >{{
                                            $t("recipesDesignSubTitleKu")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="subTitleDesignKu"
                                        class="form-control"
                                        id="subTitleDesignKu"
                                    />
                                </div>

                                <div class="col-lg-4">
                                    <label
                                        for="subTitleDesignAr"
                                        class="form-label"
                                        >{{
                                            $t("recipesDesignSubTitleAr")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="subTitleDesignAr"
                                        class="form-control"
                                        id="subTitleDesignAr"
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <label
                                        for="subTitleDesignEn"
                                        class="form-label"
                                        >{{
                                            $t("recipesDesignSubTitleEn")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="subTitleDesignEn"
                                        class="form-control"
                                        id="subTitleDesignEn"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-lg-4">
                                    <label
                                        for="descDesignKu"
                                        class="form-label"
                                        >{{ $t("recipesDesignDescKu") }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="descDesignKu"
                                        class="form-control"
                                        id="descDesignKu"
                                    />
                                </div>

                                <div class="col-lg-4">
                                    <label
                                        for="descDesignAr"
                                        class="form-label"
                                        >{{ $t("recipesDesignDescAr") }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="descDesignAr"
                                        class="form-control"
                                        id="descDesignAr"
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <label
                                        for="descDesignEn"
                                        class="form-label"
                                        >{{ $t("recipesDesignDescEn") }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="descDesignEn"
                                        class="form-control"
                                        id="descDesignEn"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-lg-6">
                                    <label for="image" class="form-label"
                                        >{{ $t('topAttachment') }}</label
                                    >
                                    <input
                                        type="file"
                                        @change="onFileChangeTop"
                                        class="form-control"
                                        id="image"
                                    />
                                </div>
                                <div class="col-lg-6">
                                    <label for="image" class="form-label"
                                        >{{ $t('bottomAttachment') }}</label
                                    >
                                    <input
                                        type="file"
                                        @change="onFileChangeBottom"
                                        class="form-control"
                                        id="image"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- loading and errors show here -->
                        <button
                            type="submit"
                            class="btn btn-danger w-100"
                            :disabled="isLoading"
                        >
                            {{ $t("saveSettings") }}
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                        <br /><br /><br />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { app } from "@/firebase";
import {
    getFirestore,
    collection,
    getDoc,
    doc,
    Timestamp,
    setDoc,
} from "firebase/firestore/lite";
import { getStorage, uploadBytes, getDownloadURL, ref } from "firebase/storage";

export default {
    name: "RecipesSettings",
    data() {
        return {
            // titleDesignKu: "",
            // titleDesignAr: "",
            // titleDesignEn: "",
            subTitleDesignKu: "",
            subTitleDesignAr: "",
            subTitleDesignEn: "",
            descDesignKu: "",
            descDesignAr: "",
            descDesignEn: "",
            topImg: null,
            topImage: null,
            bottomImg: null,
            bottomImage: null,
            isLoading: false,
            error: false,
            noData: false,
        };
    },
    methods: {
        async save() {
            const id = this.$route.params.id;
            this.isLoading = true;
            const storage = getStorage(app);
            let topImageSendForDatabase = this.topImage;

            if (this.topImg != null && this.topImg) {
                const id = this.$route.params.id + "topImage";
                const storageRef = ref(
                    storage,
                    "/Recipes Design Images/" + id + ".png"
                );
                let topSnapshot = await uploadBytes(storageRef, this.topImg);
                topImageSendForDatabase = await getDownloadURL(topSnapshot.ref);
            }
            
            let bottomImageSendForDatabase = this.bottomImage;
            if (this.bottomImg != null && this.bottomImg) {
                const id = this.$route.params.id + "bottomImage";
                const storageRef = ref(
                    storage,
                    "/Recipes Design Images/" + id + ".png"
                );
                let bottomSnapshot = await uploadBytes(storageRef, this.bottomImg);
                bottomImageSendForDatabase = await getDownloadURL(bottomSnapshot.ref);
            }
            const db = getFirestore(app);
            const groceriesColRef = doc(collection(db, "recipesSettings"), id);
            return setDoc(groceriesColRef, {
                id: id,
                // titleDesignKu: this.titleDesignKu,
                // titleDesignAr: this.titleDesignAr,
                // titleDesignEn: this.titleDesignEn,
                subTitleDesignKu: this.subTitleDesignKu,
                subTitleDesignAr: this.subTitleDesignAr,
                subTitleDesignEn: this.subTitleDesignEn,
                descDesignKu: this.descDesignKu,
                descDesignAr: this.descDesignAr,
                descDesignEn: this.descDesignEn,
                topImage: topImageSendForDatabase ?? null,
                bottomImage: bottomImageSendForDatabase ?? null,
                date: Timestamp.now(),
            })
                .then(() => {
                    this.isLoading = false;
                    this.$router.push({
                        name: "Home",
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.isLoading = false;
                    this.error = false;
                });
        },
        onFileChangeTop(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.topImg = files[0];
        },
        onFileChangeBottom(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.bottomImg = files[0];
        },
    },
    async mounted() {
        const db = getFirestore(app);

        const docRef = doc(db, "recipesSettings", this.$route.params.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            // this.titleDesignKu = data.titleDesignKu;
            // this.titleDesignAr = data.titleDesignAr;
            // this.titleDesignEn = data.titleDesignEn;
            this.subTitleDesignKu = data.subTitleDesignKu;
            this.subTitleDesignAr = data.subTitleDesignAr;
            this.subTitleDesignEn = data.subTitleDesignEn;
            this.descDesignKu = data.descDesignKu;
            this.descDesignAr = data.descDesignAr;
            this.descDesignEn = data.descDesignEn;
            this.topImage = data.topImage;
            this.bottomImage = data.bottomImage;
        } else {
            this.noData = true;
        }
    },
};
</script>

<style></style>
