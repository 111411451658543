<template>
    <div class="add">
        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-8">
                    <br /><br /><br /><br /><br />
                    <h1>{{ $t("settings") }}</h1>
                    <br /><br />
                    <form @submit.prevent="save">
                        
                        <div class="mb-3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-lg-4">
                                    <label
                                        for="titleOneKu"
                                        class="form-label"
                                        >{{ $t("healthCareDesignSubTitleKu") }}</label
                                    >
                                    <textarea
                                        col="6"
                                        type="text"
                                        required
                                        v-model="titleOneKu"
                                        class="form-control"
                                        id="titleOneKu"
                                    />
                                </div>

                                <div class="col-lg-4">
                                    <label
                                        for="titleOneAr"
                                        class="form-label"
                                        >{{ $t("healthCareDesignSubTitleAr") }}</label
                                    >
                                    <textarea
                                        col="6"
                                        type="text"
                                        required
                                        v-model="titleOneAr"
                                        class="form-control"
                                        id="titleOneAr"
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <label
                                        for="titleOneEn"
                                        class="form-label"
                                        >{{ $t("healthCareDesignSubTitleEn") }}</label
                                    >
                                    <textarea
                                        col="6"
                                        type="text"
                                        required
                                        v-model="titleOneEn"
                                        class="form-control"
                                        id="titleOneEn"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-lg-4">
                                    <label
                                        for="titleTwoKu"
                                        class="form-label"
                                        >{{ $t("healthCareDesignDescKu") }}</label
                                    >
                                    <textarea
                                        col="6"
                                        type="text"
                                        required
                                        v-model="titleTwoKu"
                                        class="form-control"
                                        id="titleTwoKu"
                                    />
                                </div>

                                <div class="col-lg-4">
                                    <label
                                        for="titleTwoAr"
                                        class="form-label"
                                        >{{ $t("healthCareDesignDescAr") }}</label
                                    >
                                    <textarea
                                        col="6"
                                        type="text"
                                        required
                                        v-model="titleTwoAr"
                                        class="form-control"
                                        id="titleTwoAr"
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <label
                                        for="titleTwoEn"
                                        class="form-label"
                                        >{{ $t("healthCareDesignDescEn") }}</label
                                    >
                                    <textarea
                                        col="6"
                                        type="text"
                                        required
                                        v-model="titleTwoEn"
                                        class="form-control"
                                        id="titleTwoEn"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- loading and errors show here -->
                        <button
                            type="submit"
                            class="btn btn-danger w-100"
                            :disabled="isLoading"
                        >
                            {{ $t("saveSettings") }}
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                        <br /><br /><br />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { app } from "@/firebase";
    import {
        getFirestore,
        collection,
        getDoc,
        doc,
        Timestamp,
        setDoc,
    } from "firebase/firestore/lite";

    export default {
        name: "HealthCareSettings",
        data() {
            return {
                titleOneKu: "",
                titleOneAr: "",
                titleOneEn: "",
                titleTwoKu: "",
                titleTwoAr: "",
                titleTwoEn: "",
                isLoading: false,
                error: false,
                noData: false,
            };
        },
        methods: {
            async save() {
                const id = this.$route.params.id;
                this.isLoading = true;
                const db = getFirestore(app);
                const groceriesColRef = doc(collection(db, "healthCareSettings"), id);
                return setDoc(groceriesColRef, {
                    id: id,
                    titleOneKu: this.titleOneKu,
                    titleOneAr: this.titleOneAr,
                    titleOneEn: this.titleOneEn,
                    titleTwoKu: this.titleTwoKu,
                    titleTwoAr: this.titleTwoAr,
                    titleTwoEn: this.titleTwoEn,
                    date: Timestamp.now(),
                })
                    .then(() => {
                        this.isLoading = false;
                        this.$router.push({
                            name: "Home",
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                        this.isLoading = false;
                        this.error = false;
                    });
            },
        },
        async mounted() {
            const db = getFirestore(app);
    
            const docRef = doc(db, "healthCareSettings", this.$route.params.id);
            const docSnap = await getDoc(docRef);
    
            if (docSnap.exists()) {
                const data = docSnap.data();
                this.titleOneKu = data.titleOneKu;
                this.titleOneAr = data.titleOneAr;
                this.titleOneEn = data.titleOneEn;
                this.titleTwoKu = data.titleTwoKu;
                this.titleTwoAr = data.titleTwoAr;
                this.titleTwoEn = data.titleTwoEn;
            } else {
                this.noData = true;
            }
        },
    };
    </script>

<style></style>
