<template>
    <section class="RecipesShow pt-1 pb-5">
        <!-- <PagesBackground :hexColor="navbarFillHexColor" /> -->
        <div class="container p-1">
            <h1 class="my-4 title">{{ $t("recipesDetails") }}</h1>
            <div
                class="row d-flex justify-content-center my-5 text-center"
                v-if="noData"
            >
                <h1 style="height: 290px">{{ $t("noData") }}</h1>
                <router-link to="/" class="btn btn-danger w-100">{{
                    $t("backToHome")
                }}</router-link>
            </div>
            <div class="row">
                <div class="col-lg-6 my-2 text-center">
                    <div class="postImage">
                        <img
                            :src="$filters.getImageUrl(recipes.image)"
                            alt="img"
                        />
                    </div>
                </div>
                <div class="col-lg-6 my-2 p-3">
                    <div
                        class="admin"
                        v-if="this.$store.getters['auth/isLoggedIn']"
                    >
                        <router-link
                            :to="{
                                name: 'RecipesEdit',
                                params: { id: `${this.$route.params.id}` },
                            }"
                            v-if="this.$store.getters['auth/isLoggedIn']"
                            class="btn btn-secondary w-100 mt-2 mb-4"
                            style="display: block"
                            >{{ $t("editRecipes") }}</router-link
                        >
                        <button
                            @click="deleteRecipes"
                            class="btn btn-danger w-100 mt-2 mb-4"
                            style="display: block"
                            :disabled="isLoading"
                            v-if="this.$store.getters['auth/isLoggedIn']"
                        >
                            {{ $t("deleteRecipes") }}
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                    </div>
                    <h2>
                        <b>
                            {{
                                recipes[
                                    "title" +
                                        $filters.ucFirst(
                                            $store.getters["language/language"]
                                        )
                                ]
                            }}
                        </b>
                    </h2>
                    <br />
                    <small>{{ $filters.timeConverter(recipes.date) }}</small>
                    <br />
                    <h3>
                        <b>Alen Products Used in this recipes :- </b>
                    </h3>
                    <br />
                    <li
                        v-for="(item, index) in productsUsed"
                        :key="index"
                        class="px-3"
                    >
                        <img
                            :src="$filters.getImageUrl(item.image)"
                            alt="pui"
                            width="80"
                        />
                        {{
                            item[
                                "title" +
                                    $filters.ucFirst(
                                        $store.getters["language/language"]
                                    )
                            ]
                        }}
                    </li>

                    <br />
                    <p
                        v-html="
                            recipes[
                                'desc' +
                                    $filters.ucFirst(
                                        $store.getters['language/language']
                                    )
                            ]
                        "
                        class="postDesc px-3"
                    ></p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import app from "@/firebase";
//login
import {
    getFirestore,
    collection,
    getDoc,
    getDocs,
    doc,
    deleteDoc,
    setDoc,
    query,
    where,
} from "firebase/firestore/lite";
import PagesBackground from "@/components/PagesBackground.vue";
import { deleteObject, getStorage, ref } from "@firebase/storage";

export default {
    name: "RecipesShow",
    props: ["navbarFillHexColor"],
    data() {
        return {
            recipes: {},
            noData: false,
            isLoading: false,
            error: false,
            productsUsed: [],
        };
    },
    methods: {
        async deleteRecipes() {
            this.isLoading = true;
            const db = getFirestore(app);
            const docRef = doc(db, "recipes", this.$route.params.id);
            try {
                const storage = getStorage(app);
                await deleteObject(ref(storage, this.recipes.image));
            } catch (error) {
                console.log(error);
            }
            const docSnap = await deleteDoc(docRef)
                .then(() => {
                    this.isLoading = false;
                    this.$router.push({
                        name: "Dashboard",
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.isLoading = false;
                    this.error = true;
                });
        },
        async getOne() {
            const db = getFirestore(app);
            const docRef = doc(db, "recipes", this.$route.params.id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                this.recipes = docSnap.data();
                this.getAllProductsUsed(docSnap.data().productsUsed);
            } else {
                this.noData = true;
            }
        },
        async getAllProductsUsed(id) {
            const db = getFirestore(app);
            const productsRef = collection(db, "products");
            const productsList = query(productsRef, where("id", "in", id));
            const docSnap = await getDocs(productsList);
            this.productsUsed = docSnap.docs.map((e) => {
                return e.data();
            });
            this.productsUsed.sort((b, a) =>
                a.date > b.date ? 1 : b.date > a.date ? -1 : 0
            );
        },
    },
    async mounted() {
        this.getOne();
    },
    components: { PagesBackground },
};
</script>

<style scoped>
.RecipesShow {
    position: relative;
}

svg {
    filter: drop-shadow(0px 0px 27px #ddd8d8);
}
.title {
    color: #e30613;
    font-weight: 900;
    font-size: 65px;
}
.postImage {
    height: 400px;
}
.postImage img {
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    height: 400px;
}

@media only screen and (max-width: 600px) {
    .RecipesShow {
        overflow-x: hidden;
    }
    .title {
        font-size: 35px;
    }
}
</style>
