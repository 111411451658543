<template>
    <section class="NewsList">
        <div class="container">
            <h2 class="my-4 title">{{ $t("videos") }}</h2>
            <div class="row d-flex justify-content-center">
                <div
                    class="col-lg-12 d-flex justify-content-center"
                    v-if="videos.length < 1"
                >
                    <br /><br /><br />
                    <h1>{{ $t("noVideos") }}</h1>
                </div>
            </div>
            <div class="row d-flex justify-content-center">
                <div
                    class="col-lg-3 my-2 d-flex justify-content-center"
                    v-for="(item, index) in videos.slice(_start, _end)"
                    :key="index"
                >
                    <!-- <router-link
            :to="{
              name: 'VideoShow',
              params: { id: item.id },
            }"
          > -->
                    <div class="card" v-if="!Loading">
                        <div class="sayda">
                            <YouTube
                                :id="item.videoID"
                                width="320"
                                height="240"
                                :src="`https://www.youtube.com/embed/${
                                    item && item.videoID
                                }`"
                                ref="youtube"
                            />
                        </div>
                        <div class="card-body text-center">
                            <b
                                class="text-center"
                                v-html="
                                    $filters.postDesLenght(
                                        item[
                                            'desc' +
                                                $filters.ucFirst(
                                                    $store.getters[
                                                        'language/language'
                                                    ]
                                                )
                                        ]
                                    )
                                "
                            ></b>
                            <div
                                class="admin"
                                v-if="this.$store.getters['auth/isLoggedIn']"
                            >
                                <router-link
                                    :to="{
                                        name: 'VideoEdit',
                                        params: { id: item.id },
                                    }"
                                    v-if="
                                        this.$store.getters['auth/isLoggedIn']
                                    "
                                    class="btn btn-secondary text-white w-100 mt-2 mb-4"
                                    style="display: block"
                                    >{{ $t("editVideo") }}</router-link
                                >
                                <button
                                    @click="deleteVideo(item.id)"
                                    class="btn btn-danger w-100 mt-2 mb-4"
                                    style="display: block"
                                    :disabled="isLoading"
                                    v-if="
                                        this.$store.getters['auth/isLoggedIn']
                                    "
                                >
                                    {{ $t("deleteVideo") }}
                                </button>
                                <p class="text-center" v-show="isLoading">
                                    {{ $t("loading") }}
                                </p>
                                <p
                                    class="text-danger text-center"
                                    v-show="error"
                                >
                                    {{ $t("error4") }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- </router-link> -->
                </div>
            </div>
            <div class="row my-5">
                <div
                    class="col-lg-6 d-flex justify-content-center text-center col-6"
                >
                    <button
                        class="btn btn-danger"
                        :disabled="_start == 0"
                        @click="prevPagination"
                    >
                        {{ $t("prev") }}
                    </button>
                </div>
                <div
                    class="col-lg-6 d-flex justify-content-center text-center col-6"
                >
                    <button
                        class="btn btn-danger"
                        :disabled="_end >= videos.length"
                        @click="nextPagination"
                    >
                        {{ $t("next") }}
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {
    doc,
    getDocs,
    getFirestore,
    collection,
    orderBy,
    deleteDoc,
} from "firebase/firestore";
import { app } from "@/firebase";

import YouTube, { Player } from "vue3-youtube";

export default {
    name: "VideoList",
    components: { YouTube },
    data() {
        return {
            videos: [],
            _start: 0,
            _end: 12,
            perPage: 12,
            isLoading: false,
            Loading: true,
            error: false,
        };
    },
    async mounted() {
        await this.getAllVideo();
    },
    methods: {
        async getAllVideo() {
            const db = getFirestore(app);
            const docSnap = await getDocs(collection(db, "videos"));
            this.videos = docSnap.docs.map((e) => {
                return e.data();
            });
            this.videos.sort((b, a) =>
                a.date > b.date ? 1 : b.date > a.date ? -1 : 0
            );
            this.Loading = false;
        },
        async deleteVideo(id) {
            this.isLoading = true;
            const db = getFirestore(app);
            const docRef = doc(db, "videos", id);
            const docSnap = await deleteDoc(docRef)
                .then(() => {
                    this.isLoading = false;
                    this.getAllVideo();
                })
                .catch((err) => {
                    console.error(err);
                    this.isLoading = false;
                    this.error = true;
                });
        },
        nextPagination() {
            this._start += this.perPage;
            this._end = this._start + this.perPage;
        },
        prevPagination() {
            this._start -= this.perPage;
            this._end -= this.perPage;
        },
    },
};
</script>

<style scoped>
.card {
    border: none;
}
.card a {
    text-decoration: none;
    color: black;
}
.card-text p {
    text-decoration: none;
    color: black;
}
.card img {
    height: 280px;
    object-fit: cover;
    border-radius: 25px;
    width: 100%;
}

.card-img-top {
    transition: transform 0.8s ease !important;
}
.sayda {
    border-radius: 25px;
    border: none;
    background-color: transparent;
    overflow: hidden;
}
.card:hover .card-img-top {
    transform: scale(1.35);
}
.imgCard {
    min-height: 325px;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.title {
    color: #e30613;
    font-weight: 900;
    font-size: 95px;
}
@media only screen and (max-width: 600px) {
    .title {
        font-size: 35px;
    }
}
</style>
