<template>
    <div class="add">
        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-8">
                    <br /><br /><br /><br /><br />
                    <h1>{{ $t("addCategory") }}</h1>
                    <form @submit.prevent="add">
                        <div class="row">
                            <div class="col-6">
                                <div class="mb-3">
                                    <label
                                        for="categoryColor"
                                        class="form-label"
                                        >{{
                                            $t("categoryBackgroundColor")
                                        }}</label
                                    >
                                    <input
                                        type="color"
                                        v-model="categoryColor"
                                        class="form-control"
                                        id="categoryColor"
                                        aria-describedby="emailHelp"
                                    />
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="mb-3">
                                    <label
                                        for="categoryTextColor"
                                        class="form-label"
                                        >{{ $t("categoryTextColor") }}</label
                                    >
                                    <input
                                        type="color"
                                        v-model="categoryTextColor"
                                        class="form-control"
                                        id="categoryTextColor"
                                        aria-describedby="emailHelp"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="categoryColor" class="form-label">{{
                                $t("demoOfYourColors")
                            }}</label>
                            <div
                                class="py-3"
                                :style="'background-color:' + categoryColor"
                            >
                                <p
                                    class="h3 text-center"
                                    :style="'color:' + categoryTextColor"
                                >
                                    Alen Spices
                                </p>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("categoryTitleKu") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleKu"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("categoryTitleAr") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleAr"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("categoryTitleEn") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleEn"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>

                        <!-- image upload  -->
                        <div class="mb-3">
                            <div class="row">
                                <div class="col-6">
                                    <label for="image" class="form-label">{{
                                        $t("newsImg")
                                    }}</label>
                                    <input
                                        type="file"
                                        @change="onFileChange"
                                        class="form-control"
                                        id="image"
                                    />
                                </div>
                                <div class="col-6">
                                    <label
                                        for="thumbImages"
                                        class="form-label"
                                        >{{ $t("thumbnail") }}</label
                                    >
                                    <input
                                        type="file"
                                        @change="onThumbFileChange"
                                        class="form-control"
                                        id="thumbImages"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- loading and errors show here -->
                        <button
                            type="submit"
                            class="btn btn-danger w-100"
                            :disabled="isLoading"
                        >
                            {{ $t("addCategory") }}
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                        <br /><br /><br />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import app from "@/firebase";
import { uid } from "uid";
//login
import {
    getFirestore,
    collection,
    Timestamp,
    doc,
    setDoc,
    getDocs,
    query,
    where,
    deleteDoc,
} from "firebase/firestore/lite";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default {
    name: "CategoryAdd",
    data() {
        return {
            categoryColor: "#000000",
            categoryTextColor: "#FFFFFF",
            titleKu: null,
            titleAr: null,
            titleEn: null,
            img: null,
            thumbFileImage: null,
            isLoading: false,
            error: false,
            duplicate: false,
        };
    },
    async mounted() {},
    methods: {
        async checkNull() {
            const db = getFirestore(app);
            const citiesRef = collection(db, "category");

            const q = query(citiesRef, where("titleEn", "==", this.titleEn));

            const docSnap = await getDocs(q);
            if (docSnap.docs.length > 0) {
                this.duplicate = true;
            } else {
                this.duplicate = false;
            }
            return docSnap.docs.length;
        },
        async add() {
            await this.checkNull();
            if (this.duplicate) {
                console.log("This item is already exist");
            } else {
                this.isLoading = true;
                let image = null;
                const storage = getStorage(app);
                const docId = uid(16);
                if (this.img != null && this.img) {
                    const storageRef = ref(
                        storage,
                        "/Category Images/" + docId + ".png"
                    );
                    let snapshot = await uploadBytes(storageRef, this.img);
                    image = await getDownloadURL(snapshot.ref);
                }

                let thumbImage;
                if (this.thumbFileImage != null && this.thumbFileImage) {
                    // console.log("thumbImage = ", this.thumbFileImage);
                    const id = docId + "thumbnail";
                    let storageRef = ref(
                        storage,
                        "/Category Images/" + id + ".png"
                    );
                    let snapshot = await uploadBytes(
                        storageRef,
                        this.thumbFileImage
                    );
                    let url = await getDownloadURL(snapshot.ref);
                    thumbImage = url;
                }

                const db = getFirestore(app);
                const groceriesColRef = doc(collection(db, "category"), docId);
                return setDoc(groceriesColRef, {
                    id: docId,
                    categoryColor: this.categoryColor,
                    categoryTextColor: this.categoryTextColor,
                    titleKu: this.titleKu,
                    titleAr: this.titleAr,
                    titleEn: this.titleEn,
                    image: image ?? null,
                    thumbImage: thumbImage ?? null,
                    date: Timestamp.now(),
                })
                    .then(() => {
                        this.isLoading = false;
                        this.$router.push({
                            name: "CategoryList",
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.isLoading = false;
                        this.error = false;
                    });
            }
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.img = files[0];
        },
        onThumbFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.thumbFileImage = files[0];
        },
    },
};
</script>

<style></style>
