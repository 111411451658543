<template>
    <div class="home" style="overflow-x: hidden">
        <Jumbotron />
        <div class="d-sm-block d-md-none d-lg-none">
            <MobileJumbotron />
        </div>
        <!-- <Categories /> -->
        <Categories />
        <UnderProductSection />
        <News />
        <UnderNewsSection />
        <div class="d-sm-block d-md-none d-lg-none">
            <UnderNewsMobile />
        </div>
        <InfoSection />
    </div>
</template>

<script>
// @ is an alias to /src
import Jumbotron from "@/components/jumbotron.vue";
import MobileJumbotron from "@/components/MobileJumbotron.vue";
// import Products from "@/components/Products.vue";
import Categories from "@/components/Categories.vue";
import UnderProductSection from "@/components/UnderProductSection.vue";
import News from "@/components/News.vue";
import UnderNewsSection from "@/components/UnderNewsSection.vue";
import UnderNewsMobile from "@/components/UnderNewsMobile.vue";
import InfoSection from "@/components/InfoSection.vue";

export default {
    name: "Home",
    components: {
        Jumbotron,
        MobileJumbotron,
        // Products,
        Categories,
        UnderProductSection,
        News,
        UnderNewsSection,
        UnderNewsMobile,
        InfoSection,
    },
    data: () => {
        return {};
    },
    methods: {},
};
</script>

<style></style>
