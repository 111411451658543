<template>
    <div class="Edit">
        <div class="container">
            <div
                class="row d-flex justify-content-center my-5 text-center"
                v-if="noData"
            >
                <h1 style="height: 290px">{{ $t("noData") }}</h1>
                <router-link to="/" class="btn btn-danger w-100">{{
                    $t("backToHome")
                }}</router-link>
            </div>
            <div v-else class="row d-flex justify-content-center">
                <div class="col-lg-8">
                    <br /><br /><br /><br /><br />
                    <h1>{{ $t("editSubCategory") }}</h1>
                    <form @submit.prevent="edit">
                        <div class="mb-3">
                            <label for="category" class="form-label">{{
                                $t("category")
                            }}</label>
                            <select
                                class="form-select"
                                v-model="categoryId"
                                aria-label="category"
                                @change="myCategorySelectEvent"
                            >
                                <option
                                    v-for="(
                                        item, index
                                    ) in myCategoryOptions"
                                    :value="item.id"
                                    :selected="item.id === categoryId"
                                >
                                    {{ item.text }}
                                </option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("subCategoryTitleKu") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleKu"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("subCategoryTitleAr") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleAr"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("subCategoryTitleEn") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleEn"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>

                        <!-- <div class="mb-3">
              <label for="image" class="form-label">Upload image</label>
              <input
                type="file"
                @change="onFileChange"
                class="form-control"
                id="image"
              />
            </div> -->
                        <button
                            type="submit"
                            class="btn btn-danger w-100"
                            :disabled="isLoading"
                        >
                            {{ $t("editSubCategory") }}
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                        <br /><br /><br />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { app } from "@/firebase";
import { uid } from "uid";
//login
import {
    getFirestore,
    collection,
    getDoc,
    doc,
    Timestamp,
    setDoc,
} from "firebase/firestore/lite";
import { getStorage, uploadBytes, getDownloadURL, ref } from "firebase/storage";
import { getDocs } from "@firebase/firestore";

export default {
    name: "subCategoryEdit",
    data() {
        return {
            categoryId: null,
            titleKu: null,
            titleAr: null,
            titleEn: null,
            img: null,
            image: null,
            isLoading: false,
            error: false,
            noData: false,
            myCategoryOptions: [],
        };
    },
    methods: {
        async edit() {
            this.isLoading = true;
            const storage = getStorage(app);
            const id = this.$route.params.id;
            // if (this.img != null && this.img) {
            //   const storageRef = ref(storage, id + ".png");
            //   let snapshot = await uploadBytes(storageRef, this.img);
            //   this.image = await getDownloadURL(snapshot.ref);
            // }

            const db = getFirestore(app);
            const groceriesColRef = doc(collection(db, "subCategories"), id);
            return setDoc(groceriesColRef, {
                id: id,
                categoryId: this.categoryId,
                titleKu: this.titleKu,
                titleAr: this.titleAr,
                titleEn: this.titleEn,
                date: Timestamp.now(),
                // image: this.image,
            })
                .then(() => {
                    this.isLoading = false;
                    this.$router.push({
                        name: "subCategoryList",
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.isLoading = false;
                    this.error = false;
                });
        },
        async getAllCategories() {
            const db = getFirestore(app);
            const docSnap = await getDocs(collection(db, "category"));
            this.myCategoryOptions = docSnap.docs.map((e) => {
                return {
                    text:
                        e.data().titleEn +
                        ` / ` +
                        e.data().titleKu +
                        ` / ` +
                        e.data().titleAr,
                    id: e.data().id,
                    // selected: e.data().id == this.categoryId ? true : false,
                };
            });
        },
        // onFileChange(e) {
        //   var files = e.target.files || e.dataTransfer.files;
        //   if (!files.length) return;
        //   this.img = files[0];
        // },
    },
    async mounted() {
        const db = getFirestore(app);

        const docRef = doc(db, "subCategories", this.$route.params.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            this.categoryId = data.categoryId;
            this.titleKu = data.titleKu;
            this.titleEn = data.titleEn;
            this.titleAr = data.titleAr;
            // this.image = data.image;
        } else {
            this.noData = true;
        }
        await this.getAllCategories();
    },
};
</script>

<style></style>
