import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "./plugins/i18n";
import CKEditor from "@ckeditor/ckeditor5-vue";
import filters from "./filters";
import helpers from "./helpers/index";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

import Vue3Autocounter from "vue3-autocounter";
// importing AOS css style globally
import "aos/dist/aos.css";
import "animate.css";

import VueParticles from "vue-particles";
import "aos/dist/aos.css";

const app = createApp(App).use(store).use(router);

app.use(i18n);
app.use(VueParticles);
app.use(CKEditor);
app.component("vue3-autocounter", Vue3Autocounter);

app.config.globalProperties.$filters = filters;
app.config.globalProperties.$helpers = helpers;
app.mount("#app");
// createApp(App).use(store).use(router).mount('#app')
