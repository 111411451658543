export default {
    alen: "Alen",
    // navbar (navbar && MobileNavbar)
    menu: "MENU",
    close: "CLOSE",
    home: "Home",
    menuText:
        "We’ve been placing smiles on everyone’s faces, one snack at a time, since 1944. Want to discover our story?",
    followUs: "Follow us",
    news: "News",
    recipes: "Recipes",
    healthCare: "Health Care",
    careers: "Careers",
    products: "Products",
    contactUs: "Contact Us",
    aboutUs: "About Us",

    // Note => (U = upper case)
    //products (Products & ProductList && ProductAdd && ProductEdit && ProductShow)
    productsU: "PRODUCTS",
    productText1: "A global leader in the food and industrial segments",
    productText2:
        "We in the Alen family attract the best experts from other countries and use the best raw materials in the world to get the best kinds of food, our staff is constantly working to provide the highest quality and production.",
    allProducts: "All Products",
    searchForProducts: "Search for Products",
    resetFilters: "Reset Filters",
    backToHome: "Back to Home",
    qrCode: "QR Code",
    editProduct: "Edit Product",
    deleteProduct: "Delete Product",
    productBarcode: "Product Barcode",
    productTitleKu: "Kurdish Product Title",
    productTitleAr: "Arabic Product Title",
    productTitleEn: "English Product Title",
    productDescrKu: "Kurdish Product Description",
    productDescrAr: "Arabic Product Description",
    productDescrEn: "English Product Description",
    productImgHD: "Products Image ( HD )",

    //recipes (underProductSection & RecipesList && RecipesAdd && RecipesEdit && RecipesShow )
    recipesU: "RECIPES",
    recipesText1: "Alen has something for everyone!",
    recipesText2:
        "Alen captures different kinds of tastes with hundreds of brands.Add sweetness to your life for every moment.Meet our large family.",
    editRecipes: "Edit Recipes",
    deleteRecipes: "Delete Recipes",
    recipesTitleKu: "Kurdish Recipes Title",
    recipesTitleAr: "Arabic Recipes Title",
    recipesTitleEn: "English Recipes Title",
    recipesDescrKu: "Kurdish Recipes Description",
    recipesDescrAr: "Arabic Recipes Description",
    recipesDescrEn: "English Recipes Description",
    recipesImg: "Upload Images",
    productsUsedInRecipes: "Select Products that is used in the recipes",
    recipesDesignSubTitleKu: "Kurdish Recipes Design SubTitle",
    recipesDesignSubTitleAr: "Arabic Recipes Design SubTitle",
    recipesDesignSubTitleEn: "English Recipes Design SubTitle",
    recipesDesignDescKu: "Kurdish Recipes Design Description",
    recipesDesignDescAr: "Arabic Recipes Design Description",
    recipesDesignDescEn: "English Recipes Design Description",
    topAttachment: "Top Attachment",
    bottomAttachment: "Bottom Attachment",

    //news (News && NewsList && NewsAdd && NewsEdit && NewsShow)
    newsU: "NEWS",
    seeOur: "See Our Latest News.",
    otherNews: "Other News ",
    editNews: "Edit News",
    deleteNews: "Delete News",
    newsTitleKu: "Kurdish News Title",
    newsTitleAr: "Arabic News Title",
    newsTitleEn: "English News Title",
    newsDescrKu: "Kurdish News Description",
    newsDescrAr: "Arabic News Description",
    newsDescrEn: "English News Description",
    newsImg: "Upload Images",

    //videos (UnderNewsSection && UnderNewsMobile && VideoAdd && VideoEdit)
    videos: "Videos",
    otherVideos: "Other Videos",
    editVideo: "Edit Video",
    deleteVideo: "Delete Video",
    videoBarcode: "Video Barcode",
    videoTitleKu: "Kurdish Video Title",
    videoTitleAr: "Arabic Video Title",
    videoTitleEn: "English Video Title",
    videoURL: "Video URL",

    //health care (infoSection && HealthCareList && HealthCareAdd && HealthCareEdit && HealthCareShow)
    healthCareU: "HEALTH CARE",
    healthCareText1:
        "Since our foundation, we have seen sustainability as an integral part of our business through our understanding of making happy becomes happiness. We work together with all our stakeholders in our value chain to develop and grow. Our work in this field can be categorized under six pillars: Environment, Value Chain, Employees, Innovation, Social Responsability and Leadership.",
    healthCareText2:
        "On this journey, we refer to the goals we set for 2024 and the sustainability principles developed by us under these six pillars. We closely monitor national and international agendas and global trends with the pupose of contributing to the United Nations 2030 Sustainable Development Goals. We believe that sustainability efforts will only be successful if all companies and employees can adopt them.",
    editHealthCare: "Edit Health Care",
    deleteHealthCare: "Delete Health care",
    healthcareTitleKu: "Kurdish Health care Title",
    healthcareTitleAr: "Arabic Health care Title",
    healthcareTitleEn: "English Health care Title",
    healthcareDescrKu: "Kurdish Health care Description",
    healthcareDescrAr: "Arabic Health care Description",
    healthcareDescrEn: "English Health care Description",
    healthcareImg: "Upload Images",
    healthCareDesignSubTitleKu: "Kurdish Health care Design Sub Title",
    healthCareDesignSubTitleAr: "Arabic Health care Design Sub Title",
    healthCareDesignSubTitleEn: "English Health care Design Sub Title",
    healthCareDesignDescKu: "Kurdish Health care Design Description",
    healthCareDesignDescAr: "Arabic Health care Design Description",
    healthCareDesignDescEn: "English Health care Design Description",

    //footer (Footer && MobileFooter)
    footerText: "Alen is the title of success",
    cr: "2022 TechEye All right reserved",

    //dashboard
    dashboardText: "Welcome Dear Admin",
    category: "Category",
    jobs: "Jobs",
    sliders: "Sliders",
    subCategory: "Sub Category",
    addProduct: "Add Product",
    listProducts: "List Products",
    addNews: "Add News",
    listNews: "List News",
    addRecipes: "Add Recipes",
    listRecipes: "List Recipes",
    addCategory: "Add Category",
    listCategories: "List Categories",
    addJob: "Add Job",
    listJobs: "List Jobs",
    addSlider: "Add Slider",
    listSliders: "List Slider",
    addSubCategory: "Add Sub Category",
    listSubCategories: "List Sub Categories",
    addVideo: "Add Video",
    listVideos: "List Videos",
    addHealthCare: "Add Health Care",
    listHealthCares: "List Health Cares",

    //Category (categoryList && categoryAdd && categoryEdit)
    categoryU: "CATEGORIES",
    addCategory: "Add Category",
    categoryBackgroundColor: "Category Background Color",
    categoryTextColor: "Category Text Color",
    demoOfYourColors: "Demo of your color",
    editCategory: "Edit Category",
    deleteCategory: "Delete Category",
    categoryTitleKu: "Kurdish Category Title",
    categoryTitleAr: "Arabic Category Title",
    categoryTitleEn: "English Category Title",
    categoryDesignSubTitleKu: "Kurdish Category Design Sub Title",
    categoryDesignSubTitleAr: "Arabic Category Design Sub Title",
    categoryDesignSubTitleEn: "English Category Design Sub Title",
    categoryDesignDescKu: "Kurdish Category Design Description",
    categoryDesignDescAr: "Arabic Category Design Description",
    categoryDesignDescEn: "English Category Design Description",

    //subCategory (subCategoryList && subCategoryAdd && subCategoryEdit)
    subCategoryU: "SUB CATEGORIES",
    addSubCategory: "Add Sub Category",
    editSubCategory: "Edit Sub Category",
    deleteSubCategory: "Delete Sub Category",
    subCategoryTitleKu: "Kurdish Sub Category Title",
    subCategoryTitleAr: "Arabic Sub Category Title",
    subCategoryTitleEn: "English Sub Category Title",

    //Slider (SliderList && SliderAdd && SliderEdit)
    sliderU: "SLIDERS",
    addSlider: "Add Slider",
    editSlider: "Edit Slider",
    deleteSlider: "Delete Slider",
    sliderPosition: "Slider position",
    sliderImg: "Slider Attachment",
    sliderTitleKu: "Kurdish Slider Title",
    sliderTitleAr: "Arabic Slider Title",
    sliderTitleEn: "English Slider Title",
    sliderDescrKu: "Kurdish Slider Description",
    sliderDescrAr: "Arabic Slider Description",
    sliderDescrEn: "English Slider Description",

    //Job (JobList && JobAdd && JobEdit)
    jobU: "JOBS",
    addJob: "Add Job",
    editJob: "Edit Job",
    deleteJob: "Delete Job",
    jobTitleKu: "Kurdish Job Title",
    jobTitleAr: "Arabic Job Title",
    jobTitleEn: "English Job Title",
    jobDescrKu: "Kurdish Job Description",
    jobDescrAr: "Arabic Job Description",
    jobDescrEn: "English Job Description",
    jobImg: "Job Attachment",

    //Password words
    resetPassword: "Reset Password",
    resetPasswordSend: "Send reset Email",
    resetPasswordSuccess:
        "Please check your Email (check spam if it is not exist in inbox)",
    resetPasswordFail: "Email not valid",
    changePassword: "Change Password",
    newPassword: "New Password",
    changePasswordSuccess: "Password changed successfully",
    email: "Email Address",
    password: "Password",

    //global words
    seeMore: "See More",
    next: "Next",
    prev: "Prev",
    noData: "No Data Available",
    error4: "Opps! please try again later",
    thumbnail: "Thumbnail",
    loading: "Loading ...",
    no: "#",
    logout: "Logout",
    attachment: "Attachment",
    language: "Language",
    settings: "Settings",
    saveSettings: "Save Settings",
    pages: "pages",
    barcode: "Barcode",

    //awanay dway nardn dastkary krawn

    //aboutUS
    alenFamilly: "Alen Familiy",
    aboutUsPart1:
        "We in the Eelin family attract the best experts from other countries and use the best raw materials in the world to get the best kinds of food, our staff is constantly working to provide the highest quality and production. Our warehouses are ideally designed to maintain the highest degree of protection for products produced with all necessary machinery under the supervision of the best experts. We have distribution agents throughout Iraq. Recently we started exporting to Europe. Our goal is to reach every kitchen and touch the heart of all food lovers with the best taste.",
    newsDetails: "News Details",
    healthCareDetails: "Health Care Details",
    recipesDetails: "Recipes Details",
    office: "Office",
    ourLocation: "Our Location",
    productBio: "ALEN HAS A FAVORITE TASTE FOR EVERYONE!",
    productBioDesc:
        "Alen catches different kinds of tastes with dozens of brands sweetening your life in every moment. Meet our large family.",
    address: "Iraq, Erbil, Kirkuk Road",
    noProducts: "No Products Available",
    noHealthcare: "No HealthCare Available",
    noJobs: "No Jobs Available",
    noNews: "No News Available",
    noRecipes: "No Recipes Available",
    noVideos: "No Videos Available",
    spiceImage: "Spice Image",
};
