<template>
    <section class="ProductShow pt-1 pb-5">
        <!-- <PagesBackground :hexColor="navbarFillHexColor" /> -->

        <div class="container p-1">
            <br />
            <br />
            <br />
            <!-- <h1 class="my-4 title">Product Details</h1> -->
            <div
                class="row d-flex justify-content-center my-5 text-center"
                v-if="noData"
            >
                <h1 style="height: 290px">{{ $t("noData") }}</h1>
                <button @click="$router.go(-1)" class="btn btn-danger w-100">
                    {{ $t("back") }}
                </button>
            </div>

            <div v-else class="cardShow bg-danger radius-20 rowBoxShadow">
                <div class="row d-flex justify-content-center text-light p-3">
                    <div class="col-lg-12 justify-content-center">
                        <div class="d-flex justify-content-between">
                            <h2 class="px-4 py-3">
                                <b>
                                    {{
                                        product[
                                            "title" +
                                                $filters.ucFirst(
                                                    $store.getters[
                                                        "language/language"
                                                    ]
                                                )
                                        ]
                                    }}
                                </b>
                            </h2>
                            <h2 class="px-4 py-3">
                                <button
                                    class="text-danger btn btn-light"
                                    @click="$router.go(-1)"
                                >
                                    <b>{{ $t("back") }}</b>
                                </button>
                            </h2>
                        </div>

                        <swiper
                            :effect="'coverflow'"
                            :grabCursor="true"
                            :centeredSlides="true"
                            :breakpoints="{
                                '640': {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                '768': {
                                    slidesPerView: 1,
                                    spaceBetween: 40,
                                },
                                '1024': {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }"
                            :autoplay="{
                                delay: 2500,
                                disableOnInteraction: false,
                            }"
                            :coverflowEffect="{
                                rotate: 50,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: true,
                            }"
                            :pagination="false"
                            :modules="modules"
                            class="mySwiper"
                        >
                            <swiper-slide
                                v-for="(item, index) in product.images"
                                :key="index"
                            >
                                <div class="postImage">
                                    <img
                                        :src="$filters.getImageUrl(item)"
                                        alt="img"
                                    />
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                    <div
                        class="col-lg-4 justify-content-center text-center p-4"
                    >
                        <h1 class="mb-5">
                            <b>
                                {{
                                    product[
                                        "title" +
                                            $filters.ucFirst(
                                                $store.getters[
                                                    "language/language"
                                                ]
                                            )
                                    ]
                                }}
                            </b>
                        </h1>
                        <h3>
                            <b>{{ $t("barcode") }}</b>
                        </h3>
                        <h3 class="mb-3">
                            <vue-barcode
                                :value="product.barcode"
                                :options="{
                                    displayValue: false,
                                }"
                            ></vue-barcode>
                            <b>{{ product.barcode }} </b>
                        </h3>
                        <h3>
                            <b><i class="fas fa-qrcode"></i> QR Code</b>
                        </h3>
                        <qrcode-vue
                            :value="
                                value +
                                `barcode-product-show/${product.barcode}`
                            "
                            :size="size"
                            level="H"
                        />
                    </div>
                    <div
                        class="col-lg-8 justify-content-start align-items-center p-4"
                    >
                        <span
                            v-html="
                                product[
                                    'desc' +
                                        $filters.ucFirst(
                                            $store.getters['language/language']
                                        )
                                ]
                            "
                            class="postDesc"
                        ></span>
                        <!-- </h5> -->
                        <!-- start admin -->
                        <div
                            class="admin"
                            v-if="this.$store.getters['auth/isLoggedIn']"
                        >
                            <p class="text-center" v-show="isLoading">
                                {{ $t("loading") }}
                            </p>
                            <p class="text-danger text-center" v-show="error">
                                {{ $t("error4") }}
                            </p>
                        </div>
                        <!-- end admin -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import VueBarcode from "@chenfengyuan/vue-barcode";
import QrcodeVue from "qrcode.vue";
import app from "@/firebase";
//login
import {
    getFirestore,
    collection,
    ref,
    getDoc,
    doc,
    deleteDoc,
    setDoc,
} from "firebase/firestore/lite";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination, Autoplay } from "swiper";
import { getDocs, query, where } from "@firebase/firestore";

export default {
    name: "ProductShowByBarcode",
    props: ["navbarFillHexColor"],
    components: {
        QrcodeVue,
        Swiper,
        SwiperSlide,
        VueBarcode,
    },
    setup() {
        return {
            modules: [EffectCoverflow, Pagination, Autoplay],
        };
    },
    data() {
        return {
            product: {},
            noData: false,
            isLoading: false,
            error: false,
            value: process.env.VUE_APP_CLIENT_URL,
            size: 150,
        };
    },
    methods: {
        async deleteProduct() {
            this.isLoading = true;
            const db = getFirestore(app);
            const docRef = doc(db, "products", this.$route.params.barcode);
            const docSnap = await deleteDoc(docRef)
                .then(() => {
                    this.isLoading = false;
                    this.$router.push({
                        name: "Dashboard",
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.isLoading = false;
                    this.error = true;
                });
        },
    },
    async mounted() {
        const db = getFirestore(app);
        const barcodeRef = collection(db, "products");
        const q = query(
            barcodeRef,
            where("barcode", "==", this.$route.params.barcode)
        );
        const docSnap = await getDocs(q);
        if (docSnap.docs.length > 0) {
            this.product = docSnap.docs[0].data();
            this.product.images.sort((b, a) =>
                a.order > b.order ? 1 : b.order > a.order ? -1 : 0
            );
        } else {
            this.noData = true;
        }
    },
};
</script>

<style scoped>
.ProductShow {
    position: relative;
}

svg {
    filter: drop-shadow(0px 0px 27px #ddd8d8);
}
.title {
    color: #e30613;
    font-weight: 900;
    font-size: 65px;
}
.postImage {
    height: 400px;
    background-image: url(../../assets/images/productShowBack.png);
    background-repeat: no-repeat;
    background-position: center;
}
/* .bg-danger {
  background-color: #cc2f3a !important;
} */
.postImage img {
    width: 100%;
    object-fit: contain;
    border-radius: 20px;
    height: 400px;
}
.postDesc {
    word-break: break-all !important;
}
@media only screen and (max-width: 600px) {
    .ProductShow {
        overflow-x: hidden;
    }
    .title {
        font-size: 35px;
    }
    .rowBoxShadow {
        box-shadow: 0 0 0 transparent;
    }
}
.rowBoxShadow {
    box-shadow: 0 0 36px -2px #e30613;
}

/*waves****************************/
.cardShow {
    position: relative;
    background-image: url(../../assets/images/bodyPattern.png);
    /* background-repeat: no-repeat; */
    background-position: center;
    background-size: contain;
}
.btn-danger {
    border: 1px solid white;
}
</style>
