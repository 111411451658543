<template>
    <div class="jumbotron" style="direction: ltr !important">
        <div class="layerGradientTop"></div>
        
        <swiper
            :spaceBetween="30"
            :effect="'fade'"
            :loop="false"
            :navigation="true"
            :autoplay="{
                delay: 7000,
                disableOnInteraction: false,
            }"
            :pagination="{
                clickable: true,
            }"
            :modules="modules"
            @slideChange="onSlideChange"
            class="mySwiper"
        >
            <swiper-slide v-for="(item, index) in sliders" :key="index">
                <div class="layerGradient"></div>

                <div
                    class="jumbotronContent"
                    :style="[
                        $store.getters['language/language'] !== 'en'
                            ? 'direction: rtl !important;'
                            : '',
                    ]"
                >
                    <div class="container">
                        <h1
                            class="my-3 jumbotronHead animate__animated animate__bounceInDown animate__slow"
                        >
                            {{
                                item[
                                    "title" +
                                        $filters.ucFirst(
                                            $store.getters["language/language"]
                                        )
                                ]
                            }}
                        </h1>
                        <h6
                            class="my-5 w-75 jumbotronDesc animate__animated animate__bounceInDown animate__slower"
                            v-html="
                                $filters.postDesLenght(
                                    item[
                                        'desc' +
                                            $filters.ucFirst(
                                                $store.getters[
                                                    'language/language'
                                                ]
                                            )
                                    ],
                                    1000
                                )
                            "
                        ></h6>
                    </div>
                </div>
                <img
                    :src="$filters.getImageUrl(item.image)"
                    alt="alen company product"
                    class="animate__animated animate__fadeIn animate__slower"
                />
            </swiper-slide>
            <!-- <div class="shapeBottom">
        <img src="../assets/images/shapeTop.png" alt="topShape" />
      </div> -->
        </swiper>
        <br /><br />
        <!-- 
    <div class="shapeTop">
      <img src="../assets/images/bottomShape.png" alt="bottomShape" />
    </div> -->
    </div>
</template>

<script>
import AOS from "aos";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { EffectFade, Navigation, Pagination, Autoplay } from "swiper";
import {
    getDocs,
    getFirestore,
    collection,
} from "firebase/firestore";
import { app } from "@/firebase";

export default {
    name: "Jumbotron",
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [EffectFade, Navigation, Pagination, Autoplay],
        };
    },
    methods: {
        onSlideChange() {
            let head = document.querySelectorAll(".jumbotronHead");
            let desc = document.querySelectorAll(".jumbotronDesc");

            if (this.$store.getters["language/language"] === "en") {
                for (let i = 0; i < head.length; i++) {
                    head[i].classList.remove("animate__bounceInDown");
                    head[i].classList.add("animate__bounceOutDown");
                    setTimeout(() => {
                        head[i].classList.remove("animate__bounceOutDown");
                        head[i].classList.add("animate__bounceInDown");
                    }, 1000);
                }
                for (let j = 0; j < desc.length; j++) {
                    desc[j].classList.remove("animate__bounceInDown");
                    desc[j].classList.add("animate__bounceOutDown");
                    setTimeout(() => {
                        desc[j].classList.remove("animate__bounceOutDown");
                        desc[j].classList.add("animate__bounceInDown");
                    }, 1000);
                }
            } else {
                for (let i = 0; i < head.length; i++) {
                    head[i].classList.remove("animate__bounceInUp");
                    head[i].classList.add("animate__bounceOutDown");
                    setTimeout(() => {
                        head[i].classList.remove("animate__bounceOutDown");
                        head[i].classList.add("animate__bounceInUp");
                    }, 1000);
                }
                for (let j = 0; j < desc.length; j++) {
                    desc[j].classList.remove("animate__bounceInUp");
                    desc[j].classList.add("animate__bounceOutDown");
                    setTimeout(() => {
                        desc[j].classList.remove("animate__bounceOutDown");
                        desc[j].classList.add("animate__bounceInUp");
                    }, 1000);
                }
            }
        },
    },
    data: () => {
        return {
            sliders: [],
        };
    },
    async mounted() {
        const db = getFirestore(app);
        const docSnap = await getDocs(collection(db, "slider"));
        this.sliders = docSnap.docs.map((e) => {
            return e.data();
        });
        this.sliders.sort((a, b) => {
            if (a.slidePositionNum > b.slidePositionNum) {
                return 1;
            } else if (b.slidePositionNum > a.slidePositionNum) {
                return -1;
            } else if (a.slidePositionNum == b.slidePositionNum) {
                if (a.date > b.date) {
                    return -1;
                } else if (b.date > a.date) {
                    return 1;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        });
        AOS.init();
    },
};
</script>

<style src="../assets/css/home/jumbotron.css" scoped></style>
