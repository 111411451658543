<template>
    <div class="add">
        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-8">
                    <br /><br /><br /><br /><br />
                    <h1>{{ $t("addSlider") }}</h1>
                    <form @submit.prevent="add">
                        <div class="mb-3">
                            <label for="forLabel" class="form-label">{{
                                $t("sliderPosition")
                            }}</label>
                            <input
                                type="number"
                                required
                                v-model="slidePositionNum"
                                class="form-control"
                                id="forLabel"
                                aria-describedby="textHelp"
                            />
                        </div>

                        <!-- image upload  -->
                        <div class="mb-3">
                            <label for="image" class="form-label">{{
                                $t("sliderImg")
                            }}</label>
                            <input
                                type="file"
                                @change="onFileChange"
                                class="form-control"
                                id="image"
                            />
                        </div>

                        <div class="mb-3">
                            <label for="forLabel" class="form-label">{{
                                $t("sliderTitleKu")
                            }}</label>
                            <input
                                type="text"
                                required
                                v-model="titleKu"
                                class="form-control"
                                id="forLabel"
                                aria-describedby="textHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="forLabel" class="form-label">{{
                                $t("sliderTitleAr")
                            }}</label>
                            <input
                                type="text"
                                required
                                v-model="titleAr"
                                class="form-control"
                                id="forLabel"
                                aria-describedby="textHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="forLabel" class="form-label">{{
                                $t("sliderTitleEn")
                            }}</label>
                            <input
                                type="text"
                                required
                                v-model="titleEn"
                                class="form-control"
                                id="forLabel"
                                aria-describedby="textHelp"
                            />
                        </div>

                        <div class="mb-3">
                            <label for="addKu">{{ $t("sliderDescrKu") }}</label>
                            <div id="addKu">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descKu"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="addAr">{{ $t("sliderDescrAr") }}</label>
                            <div id="addAr">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descAr"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="addEn">{{ $t("sliderDescrEn") }}</label>
                            <div id="addEn">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descEn"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>

                        <!-- loading and errors show here -->
                        <button
                            type="submit"
                            class="btn btn-danger w-100"
                            :disabled="isLoading"
                        >
                            {{ $t("addSlider") }}
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                        <br /><br /><br />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import app from "@/firebase";
import { uid } from "uid";
//login
import {
    getFirestore,
    collection,
    Timestamp,
    doc,
    setDoc,
    getDocs,
    query,
    where,
    deleteDoc,
} from "firebase/firestore/lite";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
    name: "SliderAdd",
    data() {
        return {
            slidePositionNum: null,
            img: null,
            isLoading: false,
            error: false,
            titleKu: null,
            titleAr: null,
            titleEn: null,
            descKu: "",
            descAr: "",
            descEn: "",
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
        };
    },
    async mounted() {},
    methods: {
        async add() {
            this.isLoading = true;
            let image = null;
            const storage = getStorage(app);
            const id = uid(16);
            if (this.img != null && this.img) {
                const storageRef = ref(
                    storage,
                    "/Slider Images/" + id + ".png"
                );
                let snapshot = await uploadBytes(storageRef, this.img);
                image = await getDownloadURL(snapshot.ref);
            }

            const db = getFirestore(app);
            const groceriesColRef = doc(collection(db, "slider"), id);
            return setDoc(groceriesColRef, {
                id: id,
                slidePositionNum: this.slidePositionNum,
                image: image,
                titleKu: this.titleKu,
                titleAr: this.titleAr,
                titleEn: this.titleEn,
                descKu: this.descKu,
                descAr: this.descAr,
                descEn: this.descEn,
                date: Timestamp.now(),
            })
                .then(() => {
                    this.isLoading = false;
                    this.$router.push({
                        name: "SliderList",
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoading = false;
                    this.error = false;
                });
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.img = files[0];
        },
    },
};
</script>

<style></style>
