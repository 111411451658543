<template>
    <div class="add">
        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-8">
                    <br /><br /><br /><br /><br />
                    <h1>{{ $t("settings") }}</h1>
                    <br /><br />
                    <form @submit.prevent="save">
                        <!-- <div class="mb-3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-lg-4">
                                    <label
                                        for="titleDesignKu"
                                        class="form-label"
                                        >{{ $t("categoryDesignTitleKu") }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="titleDesignKu"
                                        class="form-control"
                                        id="titleDesignKu"
                                    />
                                </div>

                                <div class="col-lg-4">
                                    <label
                                        for="titleDesignAr"
                                        class="form-label"
                                        >{{ $t("categoryDesignTitleAr") }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="titleDesignAr"
                                        class="form-control"
                                        id="titleDesignAr"
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <label
                                        for="titleDesignEn"
                                        class="form-label"
                                        >{{ $t("categoryDesignTitleEn") }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="titleDesignEn"
                                        class="form-control"
                                        id="titleDesignEn"
                                    />
                                </div>
                            </div>
                        </div> -->
                        <div class="mb-3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-lg-4">
                                    <label
                                        for="subTitleDesignKu"
                                        class="form-label"
                                        >{{ $t("categoryDesignSubTitleKu") }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="subTitleDesignKu"
                                        class="form-control"
                                        id="subTitleDesignKu"
                                    />
                                </div>

                                <div class="col-lg-4">
                                    <label
                                        for="subTitleDesignAr"
                                        class="form-label"
                                        >{{ $t("categoryDesignSubTitleAr") }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="subTitleDesignAr"
                                        class="form-control"
                                        id="subTitleDesignAr"
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <label
                                        for="subTitleDesignEn"
                                        class="form-label"
                                        >{{ $t("categoryDesignSubTitleEn") }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="subTitleDesignEn"
                                        class="form-control"
                                        id="subTitleDesignEn"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-lg-4">
                                    <label
                                        for="descDesignKu"
                                        class="form-label"
                                        >{{ $t("categoryDesignDescKu") }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="descDesignKu"
                                        class="form-control"
                                        id="descDesignKu"
                                    />
                                </div>

                                <div class="col-lg-4">
                                    <label
                                        for="descDesignAr"
                                        class="form-label"
                                        >{{ $t("categoryDesignDescAr") }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="descDesignAr"
                                        class="form-control"
                                        id="descDesignAr"
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <label
                                        for="descDesignEn"
                                        class="form-label"
                                        >{{ $t("categoryDesignDescEn") }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="descDesignEn"
                                        class="form-control"
                                        id="descDesignEn"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- loading and errors show here -->
                        <button
                            type="submit"
                            class="btn btn-danger w-100"
                            :disabled="isLoading"
                        >
                            {{ $t("saveSettings") }}
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                        <br /><br /><br />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { app } from "@/firebase";
    import {
        getFirestore,
        collection,
        getDoc,
        doc,
        Timestamp,
        setDoc,
    } from "firebase/firestore/lite";

    export default {
        name: "CategorySettings",
        data() {
            return {
                // titleDesignKu: "",
                // titleDesignAr: "",
                // titleDesignEn: "",
                subTitleDesignKu: "",
                subTitleDesignAr: "",
                subTitleDesignEn: "",
                descDesignKu: "",
                descDesignAr: "",
                descDesignEn: "",
                isLoading: false,
                error: false,
                noData: false,
            };
        },
        methods: {
            async save() {
                const id = this.$route.params.id;
                this.isLoading = true;
                const db = getFirestore(app);
                const groceriesColRef = doc(collection(db, "categorySettings"), id);
                return setDoc(groceriesColRef, {
                    id: id,
                    // titleDesignKu: this.titleDesignKu,
                    // titleDesignAr: this.titleDesignAr,
                    // titleDesignEn: this.titleDesignEn,
                    subTitleDesignKu: this.subTitleDesignKu,
                    subTitleDesignAr: this.subTitleDesignAr,
                    subTitleDesignEn: this.subTitleDesignEn,
                    descDesignKu: this.descDesignKu,
                    descDesignAr: this.descDesignAr,
                    descDesignEn: this.descDesignEn,
                    date: Timestamp.now(),
                })
                    .then(() => {
                        this.isLoading = false;
                        this.$router.push({
                            name: "Home",
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                        this.isLoading = false;
                        this.error = false;
                    });
            },
        },
        async mounted() {
            const db = getFirestore(app);
    
            const docRef = doc(db, "categorySettings", this.$route.params.id);
            const docSnap = await getDoc(docRef);
    
            if (docSnap.exists()) {
                const data = docSnap.data();
                // this.titleDesignKu = data.titleDesignKu;
                // this.titleDesignAr = data.titleDesignAr;
                // this.titleDesignEn = data.titleDesignEn;
                this.subTitleDesignKu = data.subTitleDesignKu;
                this.subTitleDesignAr = data.subTitleDesignAr;
                this.subTitleDesignEn = data.subTitleDesignEn;
                this.descDesignKu = data.descDesignKu;
                this.descDesignAr = data.descDesignAr;
                this.descDesignEn = data.descDesignEn;
            } else {
                this.noData = true;
            }
        },
    };
    </script>

<style></style>
