<template>
    <section class="news pt-0 pb-2">
        <div class="container">
            <br />
            <span class="spanTitle" data-aos="fade-left">
                <router-link
                    :to="{
                        name: 'NewsList',
                    }"
                    class="my-4 title"
                    >{{ $t("newsU") }}</router-link
                >
            </span>
            <br />
            <div class="row my-5 d-flex justify-content-center">
                <!-- dynamic -->
                <div class="col-lg-9">
                    <div class="row">
                        <div
                            class="col-lg-12 d-flex justify-content-center"
                            data-aos="fade-up"
                            v-if="news.length < 1"
                        >
                            <br /><br /><br />
                            <h1 data-aos="fade-down">{{ $t("noData") }}</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div
                            class="col-lg-4 my-2"
                            data-aos="fade-up"
                            v-for="(item, index) in news.slice(0, 3)"
                            :key="index"
                        >
                            <router-link
                                :to="{
                                    name: 'NewsShow',
                                    params: { id: item.id },
                                }"
                            >
                                <div class="card">
                                    <div class="sayda">
                                        <img
                                            :src="
                                                $filters.getImageUrl(item.image)
                                            "
                                            class="card-img-top"
                                            :alt="item.titleEn"
                                        />
                                    </div>
                                    <div
                                        class="card-body"
                                        style="height: 151px"
                                    >
                                        <h5
                                            class="card-title"
                                            style="
                                                text-decoration: none;
                                                color: black;
                                            "
                                        >
                                            {{
                                                item[
                                                    "title" +
                                                        $filters.ucFirst(
                                                            $store.getters[
                                                                "language/language"
                                                            ]
                                                        )
                                                ]
                                            }}
                                        </h5>
                                        <p
                                            v-html="
                                                $filters.postDesLenght(
                                                    item[
                                                        'desc' +
                                                            $filters.ucFirst(
                                                                $store.getters[
                                                                    'language/language'
                                                                ]
                                                            )
                                                    ],
                                                    100
                                                )
                                            "
                                            class="card-text"
                                            style="
                                                text-decoration: none;
                                                color: black;
                                            "
                                        ></p>
                                    </div>
                                    <div class="card-footer">
                                        <p
                                            v-if="
                                                $store.getters[
                                                    'language/language'
                                                ] !== 'en'
                                            "
                                            class="text-start"
                                        >
                                            {{ $t("seeMore") }}
                                            <i
                                                v-if="
                                                    $store.getters[
                                                        'language/language'
                                                    ] !== 'en'
                                                "
                                                class="fa fa-arrow-left"
                                            ></i>
                                            &nbsp;
                                        </p>
                                        <p
                                            v-if="
                                                $store.getters[
                                                    'language/language'
                                                ] === 'en'
                                            "
                                            class="text-end"
                                        >
                                            {{ $t("seeMore") }}
                                            <i
                                                v-if="
                                                    $store.getters[
                                                        'language/language'
                                                    ] === 'en'
                                                "
                                                class="fa fa-arrow-right"
                                            ></i>
                                            &nbsp;
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>

                <!-- static -->
                <div class="col-lg-3" style="text-align: right">
                    <h3
                        class="py-4"
                        style="font-weight: 900"
                        data-aos="fade-right"
                    >
                        {{ $t("seeOur") }}
                    </h3>
                    <router-link
                        :to="{
                            name: 'NewsList',
                        }"
                        class="btn btn-danger w-100"
                        data-aos="fade-left"
                        style="border-radius: 23px; padding: 16px"
                    >
                        <b>{{ $t("otherNews") }}</b> &nbsp;
                        <i
                            v-if="$store.getters['language/language'] === 'en'"
                            class="fa fa-arrow-right"
                        ></i>
                        &nbsp;
                        <i
                            v-if="$store.getters['language/language'] !== 'en'"
                            class="fa fa-arrow-left"
                        ></i>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { getDocs, getFirestore, collection } from "firebase/firestore";
import { app } from "@/firebase";

export default {
    name: "News",
    data() {
        return {
            news: [],
        };
    },
    async mounted() {
        const db = getFirestore(app);
        const docSnap = await getDocs(collection(db, "news"));
        this.news = docSnap.docs.map((e) => {
            return e.data();
        });
        this.news.sort((b, a) =>
            a.date > b.date ? 1 : b.date > a.date ? -1 : 0
        );
    },
};
</script>

<style src="../assets/css/home/news.css" scoped></style>
