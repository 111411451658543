<template>
    <!-- <div v-show="isLoading" class="loading">
        <img
            src="./assets/images/logoAnimated.svg"
            class="logoLoading"
            alt="logo"
        />
    </div> -->
    <!-- v-show="!isLoading" -->
    <div class="content" style="position: relative; min-height: 100vh">
        <div class="navbarLaptop">
            <nav
                class="navbar navbar-expand-lg navbar-light bg-light py-0"
                v-if="this.$store.getters['auth/isLoggedIn']"
                style="z-index: 55"
            >
                <div class="container">
                    <p
                        class="navbar-brand px-2 animate__animated animate__fadeIn animate__slower"
                        style="margin: 0"
                        v-if="this.$store.getters['auth/isLoggedIn']"
                    >
                        <router-link
                            :to="{ name: 'Dashboard' }"
                            class="btn btn-success"
                            >{{ $t("dashboardText") }}
                        </router-link>
                    </p>
                    <!-- <button
                        class="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span class="navbar-toggler-icon"></span>
                    </button> -->
                    <div
                        class="collapse navbar-collapse animate__animated animate__fadeIn animate__slower"
                        id="navbarSupportedContent"
                    >
                        <button
                            v-if="this.$store.getters['auth/isLoggedIn']"
                            class="btn btn-danger px-2 d-flex align-items-center"
                            style="color: white !important"
                            @click="doLogout"
                        >
                            {{ $t("logout") }}
                        </button>
                    </div>
                </div>
            </nav>
            <Navbar
                :navbarFillHexColor="fillColor"
                :navbarAllTextColor="textColor"
            />
        </div>
        <div class="d-sm-block d-md-block d-lg-none">
            <MobileNavbar
                :navbarFillHexColor="fillColor"
                :navbarAllTextColor="textColor"
            />
        </div>
        <router-view v-slot="{ Component }" @changeColor="handleChangeColor">
            <transition name="fade" mode="out-in">
                <component
                    :is="Component"
                    :navbarFillHexColor="fillColor"
                    :categoryAllTextColor="textColor"
                />
            </transition>
        </router-view>
    </div>
    <!-- <Footer
        v-show="!isLoading"
        :footerFillHexColor="fillColor"
        :footerAllTextColor="textColor"
    /> -->
    <Footer :footerFillHexColor="fillColor" :footerAllTextColor="textColor" />
    <div class="toTop">
        <button
            v-show="scY > 300"
            @click="toTop"
            class="toTopClass btn btn-secondary"
            data-aos="fade-right"
            style="border-radius: 100px"
            :style="[
                textColor
                    ? 'color:' + textColor + '!important'
                    : 'color:' + 'white',
            ]"
        >
            <i class="fa fa-arrow-up"></i>
        </button>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import Navbar from "@/components/Navbar";
import MobileNavbar from "@/components/MobileNavbar";
import Footer from "@/components/Footer";
import AOS from "aos";

export default {
    setup() {
        const isLoading = ref(true);
        onMounted(() => {
            AOS.init();
            // setTimeout(() => {
            //     isLoading.value = false;
            // }, 5000);
        });
        return {
            isLoading,
        };
    },
    data: () => {
        return {
            // isLoading: true,
            fillColor: "",
            textColor: "",
            scTimer: 0,
            scY: 0,
        };
    },
    components: {
        Navbar,
        Footer,
        MobileNavbar,
    },
    watch: {
        $route() {
            //set firebase user after reload page
            if (
                this.$store.getters["auth/isLoggedIn"] ||
                localStorage.getItem("isLoggedIn") === "true"
            ) {
                this.$store.dispatch("auth/LOGIN_CHECK");
            } else {
                localStorage.setItem("isLoggedIn", false);
            }
            if (this.$route.name != "ProductsList") {
                this.fillColor = "";
                this.textColor = "#FFFFFF";
            }
        },
    },
    mounted() {
        window.addEventListener("scroll", () => {
            this.handleScroll();
        });
    },
    methods: {
        handleChangeColor(e) {
            this.fillColor = e.backColor;
            this.textColor = e.textColor;
        },
        handleScroll() {
            if (this.scTimer) return;
            this.scTimer = setTimeout(() => {
                this.scY = window.scrollY;
                clearTimeout(this.scTimer);
                this.scTimer = 0;
            }, 10);
        },
        toTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
        doLogout() {
            this.$store
                .dispatch("auth/LOGOUT")
                .then(() => {
                    this.$router.push("/");
                    if (
                        document
                            .getElementById("navbarToggleExternalContent")
                            .classList.contains("show")
                    ) {
                        document
                            .getElementById("navbarToggleExternalContent")
                            .classList.remove("show");
                        this.openCollapse();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style>
@import "./assets/css/app.css";
</style>
