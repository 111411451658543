<template>
    <div class="dashboard">
        <div class="container">
            <div class="d-flex justify-content-between align-items-center">
                <h1 class="text-left my-2 text-danger">
                    <b>{{ $t("dashboardText") }}</b>
                </h1>
                <button
                    v-if="this.$store.getters['auth/isLoggedIn']"
                    class="btn btn-danger px-2 d-flex align-items-center"
                    style="color: white !important"
                    @click="doLogout"
                >
                    {{ $t("logout") }}
                </button>
            </div>
            <hr />
            <div class="row my-5">
                <!-- Product -->
                <div
                    class="col-lg-4"
                    v-if="this.$store.getters['auth/isLoggedIn']"
                >
                    <h3 class="text-danger">
                        <b>{{ $t("products") }}</b>
                    </h3>

                    <router-link
                        :to="{
                            name: 'ProductAdd',
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block"
                        ><i class="fas fa-plus"></i> &nbsp;{{
                            $t("addProduct")
                        }}
                    </router-link>
                    <router-link
                        :to="{
                            name: 'ProductsList',
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block"
                        ><i class="fas fa-eye"></i>&nbsp;
                        {{ $t("listProducts") }}
                    </router-link>
                </div>
                <!-- News -->
                <div
                    class="col-lg-4"
                    v-if="this.$store.getters['auth/isLoggedIn']"
                >
                    <h3 class="text-danger">
                        <b>{{ $t("news") }}</b>
                    </h3>

                    <router-link
                        :to="{
                            name: 'NewsAdd',
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block"
                    >
                        <i class="fas fa-plus"></i> &nbsp;{{ $t("addNews") }}
                    </router-link>
                    <router-link
                        :to="{
                            name: 'NewsList',
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block"
                    >
                        <i class="fas fa-eye"></i>&nbsp; {{ $t("listNews") }}
                    </router-link>
                </div>
                <!-- Recipes -->
                <div
                    class="col-lg-4"
                    v-if="this.$store.getters['auth/isLoggedIn']"
                >
                    <h3 class="text-danger">
                        <b>{{ $t("recipes") }}</b>
                    </h3>

                    <router-link
                        :to="{
                            name: 'RecipesAdd',
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block"
                    >
                        <i class="fas fa-plus"></i> &nbsp;{{ $t("addRecipes") }}
                    </router-link>
                    <router-link
                        :to="{
                            name: 'RecipesList',
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block"
                    >
                        <i class="fas fa-eye"></i>&nbsp; {{ $t("listRecipes") }}
                    </router-link>
                    <router-link
                        :to="{
                            name: 'RecipesSettings',
                            params: { id: 'tLatZVDXo0FrQhV7PnVB' },
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block w-100"
                    >
                        <i class="fas fa-gear"></i>&nbsp;
                        {{ $t("settings") }}
                    </router-link>
                </div>
                <!-- Category -->
                <div
                    class="col-lg-4"
                    v-if="this.$store.getters['auth/isLoggedIn']"
                >
                    <h3 class="text-danger">
                        <b>{{ $t("category") }}</b>
                    </h3>

                    <router-link
                        v-if="categoryAddIsEnable"
                        :to="{
                            name: 'CategoryAdd',
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block w-100"
                    >
                        <i class="fas fa-plus"></i> &nbsp;{{
                            $t("categoryAdd")
                        }}
                    </router-link>
                    <router-link
                        :to="{
                            name: 'CategoryList',
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block w-100"
                    >
                        <i class="fas fa-eye"></i>&nbsp;
                        {{ $t("listCategories") }}
                    </router-link>
                    <router-link
                        :to="{
                            name: 'CategorySettings',
                            params: { id: 'Ptvnp6Uu6Mcgun5l3yAt' },
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block w-100"
                    >
                        <i class="fas fa-gear"></i>&nbsp;
                        {{ $t("settings") }}
                    </router-link>
                </div>
                <!-- Jobs -->
                <div
                    class="col-lg-4"
                    v-if="this.$store.getters['auth/isLoggedIn']"
                >
                    <h3 class="text-danger">
                        <b>{{ $t("jobs") }}</b>
                    </h3>

                    <router-link
                        :to="{
                            name: 'JobAdd',
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block"
                    >
                        <i class="fas fa-plus"></i> &nbsp;{{ $t("addJob") }}
                    </router-link>
                    <router-link
                        :to="{
                            name: 'JobsList',
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block"
                    >
                        <i class="fas fa-eye"></i>&nbsp; {{ $t("listJobs") }}
                    </router-link>
                </div>
                <!-- Slider -->
                <div
                    class="col-lg-4"
                    v-if="this.$store.getters['auth/isLoggedIn']"
                >
                    <h3 class="text-danger">
                        <b>{{ $t("sliders") }}</b>
                    </h3>

                    <router-link
                        :to="{
                            name: 'SliderAdd',
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block"
                    >
                        <i class="fas fa-plus"></i> &nbsp;{{ $t("addSlider") }}
                    </router-link>
                    <router-link
                        :to="{
                            name: 'SliderList',
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block"
                    >
                        <i class="fas fa-eye"></i>&nbsp; {{ $t("listSliders") }}
                    </router-link>
                </div>
                <!-- Sub Category -->
                <div
                    class="col-lg-4"
                    v-if="this.$store.getters['auth/isLoggedIn']"
                >
                    <h3 class="text-danger">
                        <b>{{ $t("subCategory") }}</b>
                    </h3>

                    <router-link
                        :to="{
                            name: 'subCategoryAdd',
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block"
                    >
                        <i class="fas fa-plus"></i> &nbsp;{{
                            $t("addSubCategory")
                        }}
                    </router-link>
                    <router-link
                        :to="{
                            name: 'subCategoryList',
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block"
                    >
                        <i class="fas fa-eye"></i>&nbsp;
                        {{ $t("listSubCategories") }}
                    </router-link>
                </div>
                <!-- videos -->
                <div
                    class="col-lg-4"
                    v-if="this.$store.getters['auth/isLoggedIn']"
                >
                    <h3 class="text-danger">
                        <b>{{ $t("videos") }}</b>
                    </h3>
                    <router-link
                        :to="{
                            name: 'VideoAdd',
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block"
                    >
                        <i class="fas fa-plus"></i> &nbsp;{{ $t("addVideo") }}
                    </router-link>
                    <router-link
                        :to="{
                            name: 'VideoList',
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block"
                    >
                        <i class="fas fa-eye"></i>&nbsp; {{ $t("listVideos") }}
                    </router-link>
                </div>
                <!-- health care -->
                <div
                    class="col-lg-4"
                    v-if="this.$store.getters['auth/isLoggedIn']"
                >
                    <h3 class="text-danger">
                        <b>{{ $t("healthCare") }}</b>
                    </h3>
                    <router-link
                        :to="{
                            name: 'HealthCareAdd',
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block"
                    >
                        <i class="fas fa-plus"></i> &nbsp;
                        {{ $t("addHealthCare") }}
                    </router-link>
                    <router-link
                        :to="{
                            name: 'HealthCareList',
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block"
                    >
                        <i class="fas fa-eye"></i>&nbsp;
                        {{ $t("listHealthCares") }}
                    </router-link>
                    <router-link
                        :to="{
                            name: 'HealthCareSettings',
                            params: { id: 'ZoOOZXESXqFBPhIFlzHA' },
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block w-100"
                    >
                        <i class="fas fa-gear"></i>&nbsp;
                        {{ $t("settings") }}
                    </router-link>
                </div>
                <!-- about us -->
                <div
                    class="col-lg-4"
                    v-if="this.$store.getters['auth/isLoggedIn']"
                >
                    <h3 class="text-danger">
                        <b>{{ $t("aboutUs") }}</b>
                    </h3>
                    <router-link
                        :to="{
                            name: 'AboutUsSettings',
                            params: { id: '4EtpKW2PrXFs8vMoOHuI' },
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block w-100"
                    >
                        <i class="fas fa-gear"></i>&nbsp;
                        {{ $t("settings") }}
                    </router-link>
                </div>
                <!-- contact us -->
                <div
                    class="col-lg-4"
                    v-if="this.$store.getters['auth/isLoggedIn']"
                >
                    <h3 class="text-danger">
                        <b>{{ $t("contactUs") }}</b>
                    </h3>
                    <router-link
                        :to="{
                            name: 'ContactUsSettings',
                            params: { id: 'sYPvBs0s5PJPs6QyXNIu' },
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block w-100"
                    >
                        <i class="fas fa-gear"></i>&nbsp;
                        {{ $t("settings") }}
                    </router-link>
                </div>
                <!-- password  -->
                <div
                    class="col-lg-4"
                    v-if="this.$store.getters['auth/isLoggedIn']"
                >
                    <h3 class="text-danger">
                        <b>{{ $t("password") }}</b>
                    </h3>
                    <router-link
                        :to="{
                            name: 'ResetPassword',
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block"
                    >
                        <i class="fas fa-plus"></i> &nbsp;
                        {{ $t("resetPassword") }}
                    </router-link>
                    <router-link
                        :to="{
                            name: 'ChangePassword',
                        }"
                        class="btn btn-danger mb-3 mt-1 d-block"
                    >
                        <i class="fas fa-eye"></i>&nbsp;
                        {{ $t("changePassword") }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
    name: "Dashboard",
    components: {},
    setup() {
        const categoryAddIsEnable = ref(false);
        onMounted(() => {
            if (process.env.VUE_APP_CATEGORY_ADD === true) {
                categoryAddIsEnable = true;
            }
        });

        return {
            categoryAddIsEnable,
        };
    },
    data() {
        return {};
    },
    methods: {
        doLogout() {
            this.$store
                .dispatch("auth/LOGOUT")
                .then(() => {
                    this.$router.push("/");
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
});
</script>

<style scoped>
.col-lg-4 {
    border: 2px solid #e30613;
    border-radius: 15px;
    position: relative;
    margin: 20px 10px;
    padding: 25px;
    width: 392px;
}
.col-lg-4 h3 {
    position: absolute;
    top: -20px;
    background-color: #e30613;
    padding: 0 10px;
    color: white !important;
    border-radius: 10px;
}
.btn-danger {
    border-radius: 13px;
}
@media only screen and (max-width: 600px) {
    .col-lg-4 {
        width: 95%;
    }
}
</style>
