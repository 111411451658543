<template>
    <router-link
        :to="{
            name: 'ProductsList',
            query: { id: category.id },
        }"
        class="left btn btn-light w-100 px-2 py-4 my-2 d-block"
        :style="[
            $route.query.id == category.id
                ? {
                      'background-color': category.categoryColor + '!important',
                      color: 'white !important',
                  }
                : '',
            categoryAllTextColor
                ? 'color:' + categoryAllTextColor + '!important'
                : 'color:' + 'black',
        ]"
    >
        <b
            :style="[
                categoryAllTextColor && selectedCatId == category.id
                    ? 'color:' + categoryAllTextColor + '!important'
                    : 'color:' + 'black',
            ]"
        >
            {{
                category[
                    "title" +
                        $filters.ucFirst($store.getters["language/language"])
                ]
            }}
        </b>
    </router-link>
</template>

<script>
export default {
    name: "OneCategoryCradForProductList",

    props: ["category", "categoryAllTextColor", "selectedCatId"],
    data() {
        return {};
    },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
    .productFilter a {
        margin-right: 10px !important;
        min-width: 230px;
        padding-top: 40px !important;
        padding-bottom: 40px !important;
        border-radius: 25px;
    }
}
</style>
