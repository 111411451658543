<template>
    <section class="ContactUs pt-1 pb-5">
        <!-- <PagesBackground :hexColor="navbarFillHexColor" /> -->
        <div class="container p-1">
            <h1 class="my-4 title">{{ $t("contactUs") }}</h1>
            <div class="row" style="direction: ltr">
                <div class="col-lg-12">
                    <div
                        class="card mb-3 my-2"
                        style="max-width: 100%; border-radius: 17px"
                    >
                        <div class="row g-0">
                            <div class="col-md-4">
                                <img
                                    :src="$filters.getImageUrl(contactUsSettings[0] && contactUsSettings[0]['blockOneImage'])"
                                    style="
                                        height: 100%;
                                        object-fit: cover;
                                        width: 100%;
                                        border-radius: 17px;
                                    "
                                    class="img-fluid"
                                    alt="imgtest"
                                />
                            </div>
                            <div class="col-md-8">
                                <div
                                    class="card-body py-4"
                                    :style="[
                                        $store.getters['language/language'] !==
                                        'en'
                                            ? 'direction: rtl;'
                                            : '',
                                    ]"
                                >
                                    <h4 class="card-title">
                                        <b>
                                            {{
                                            contactUsSettings[0] &&
                                            contactUsSettings[0][
                                                "subTitleDesign" +
                                                    $filters.ucFirst(
                                                        $store.getters[
                                                            "language/language"
                                                        ]
                                                    )
                                            ]
                                        }}    
                                        </b>
                                    </h4>
                                    <hr width="50" />
                                    <div class="px-5">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <h6 class="d-block py-1">
                                                    <i
                                                        class="fa fa-map-marker px-2"
                                                    ></i>
                                                    &nbsp;&nbsp;{{
                                                        $t("address")
                                                    }}
                                                </h6>
                                                <h6 class="d-block py-1">
                                                    <i
                                                        class="fa fa-envelope px-2"
                                                    ></i>
                                                    &nbsp;&nbsp;
                                                    {{
                                            contactUsSettings[0] &&
                                            contactUsSettings[0]["email"]
                                        }}
                                                </h6>
                                                <h6
                                                    class="d-block py-1"
                                                    :style="[
                                                        $store.getters[
                                                            'language/language'
                                                        ] !== 'en'
                                                            ? 'direction: ltr;text-align: right;'
                                                            : '',
                                                    ]"
                                                >
                                                    <i
                                                        :style="[
                                                            $store.getters[
                                                                'language/language'
                                                            ] !== 'en'
                                                                ? 'float: right;'
                                                                : '',
                                                        ]"
                                                        class="fa fa-phone px-2"
                                                    ></i>
                                                    &nbsp;&nbsp;<span>
                                                        +
                                                        {{ contactUsSettings[0] &&contactUsSettings[0]["phoneOne"] }}
                                                    </span>
                                                </h6>
                                                <h6
                                                    class="d-block py-1"
                                                    :style="[
                                                        $store.getters[
                                                            'language/language'
                                                        ] !== 'en'
                                                            ? 'direction: ltr;text-align: right;'
                                                            : '',
                                                    ]"
                                                >
                                                    <i
                                                        :style="[
                                                            $store.getters[
                                                                'language/language'
                                                            ] !== 'en'
                                                                ? 'float: right;'
                                                                : '',
                                                        ]"
                                                        class="fa fa-phone px-2"
                                                    ></i>
                                                    &nbsp;&nbsp;<span
                                                        >+ 
                                                        {{ contactUsSettings[0] &&contactUsSettings[0]["phoneTwo"] }}
                                                        </span
                                                    >
                                                </h6>
                                            </div>
                                            <div
                                                class="col-lg-6 justify-content-center align-items-center"
                                            >
                                                <center>
                                                    <!-- href="tel:+9647504561053" -->

                                                    <a
                                                        :href="'tel:+' + (contactUsSettings[0] && contactUsSettings[0]['phoneOne'])"
                                                        class="leftt btn btn-secondary mx-2 w-75 d-block"
                                                    >
                                                        <i
                                                            class="fa fa-phone px-2"
                                                        ></i>
                                                        &nbsp;&nbsp;
                                                        {{ $t("contactUs") }}
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                    </a>
                                                    <a
                                                        href="https://goo.gl/maps/Ej7sjJo1cNEJmXeU7"
                                                        target="_blank"
                                                        class="leftt btn btn-secondary mx-2 w-75 d-block"
                                                    >
                                                        <i
                                                            class="fa fa-location px-2"
                                                        ></i>
                                                        &nbsp;&nbsp;
                                                        {{ $t("ourLocation") }}
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                    </a>
                                                </center>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="card mb-3 my-2"
                        style="max-width: 100%; border-radius: 17px"
                    >
                        <div class="row g-0">
                            <div class="col-md-4">
                                <img
                                :src="$filters.getImageUrl(contactUsSettings[0] && contactUsSettings[0]['blockTwoImage'])"
                                    style="
                                        height: 100%;
                                        object-fit: cover;
                                        width: 100%;
                                        border-radius: 17px;
                                    "
                                    class="img-fluid"
                                    alt="imgtest"
                                />
                            </div>
                            <div class="col-md-8">
                                <div
                                    class="card-body py-4"
                                    :style="[
                                        $store.getters['language/language'] !==
                                        'en'
                                            ? 'direction: rtl;'
                                            : '',
                                    ]"
                                >
                                    <h4 class="card-title">
                                        <b>
                                            <!-- {{ $t("productText1") }} -->

                                            {{
                                            contactUsSettings[0] &&
                                            contactUsSettings[0][
                                                "subTitleDesignBlockTwo" +
                                                    $filters.ucFirst(
                                                        $store.getters[
                                                            "language/language"
                                                        ]
                                                    )
                                            ]
                                        }}
                                        </b>
                                    </h4>
                                    <hr width="50" />
                                    <p class="card-text px-5">
                                        <!-- {{ $t("productText2") }} -->
                                        {{
                                            contactUsSettings[0] &&
                                            contactUsSettings[0][
                                                "descDesign" +
                                                    $filters.ucFirst(
                                                        $store.getters[
                                                            "language/language"
                                                        ]
                                                    )
                                            ]
                                        }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 my-3">
                            <h1 class="text-center my-4 title">
                                {{ $t("ourLocation") }}
                            </h1>
                            <br />
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3224.5790148390806!2d44.0305209!3d36.0793711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x400730a55f993a3b%3A0x8a91f738457bed1c!2sAlen%20Spices%20Factory!5e0!3m2!1sen!2siq!4v1660135163598!5m2!1sen!2siq"
                                width="800"
                                height="600"
                                style="border: 0; width: 100%"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import PagesBackground from "@/components/PagesBackground.vue";
import { getDocs, getFirestore, collection } from "firebase/firestore";
import { app } from "@/firebase";

export default {
    name: "ContactUs",
    props: ["navbarFillHexColor"],
    data() {
        return {
            contactUsSettings: [],
        };
    },
    components: { PagesBackground },
    methods: {
        async getAllCOntactUsSettings() {
            const db = getFirestore(app);
            const docSnap = await getDocs(collection(db, "contactUsSettings"));
            this.contactUsSettings = docSnap.docs.map((e) => {
                return e.data();
            });
        },
    },
    async mounted() {
        await this.getAllCOntactUsSettings();
    },
};
</script>

<style scoped>
.ContactUs {
    position: relative;
}
.contentStart {
    height: 300px;
}
.contentEnd {
    position: relative;
    height: 300px;
}
.contentEnd img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    object-fit: cover;
    z-index: 2;
}
.buttons {
    position: absolute;
    top: 80%;
    right: -10%;
    z-index: 4;
    transform: translate(-20%, -35%);
}
.oneRow {
    border: 1px solid rgb(206, 202, 202);
    border-radius: 17px;
}
.productContent {
    position: relative;
}
.productContent img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.cover:hover .blob {
    cursor: pointer;
    fill: #e30613 !important;

    transition: fill 1.2s ease-in-out;
    overflow: hidden;
}
.layout {
    transition: transform 1s;
}
.layout1 {
    transition: transform 2s;
}
.layout:hover {
    transform: scale(1.15);
    overflow-y: hidden !important;
}
.layout:hover img {
    border-radius: 598px 479px 511px 389px;
    border: dashed;
    border-color: rgb(148, 89, 89);
}
.layout:hover i {
    display: inline-block !important;
    transform: scale(1.35);
}
.productContentBody {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}
svg {
    filter: drop-shadow(0px 0px 27px #ddd8d8);
}
.title {
    color: #e30613;
    font-weight: 900;
    font-size: 65px;
}

.left {
    background-color: aliceblue !important;
    color: black;
    border: 1px solid rgb(209, 204, 204);
    border-radius: 17px;
    font-weight: 900;
    text-align: left;
}
.left:hover {
    background-color: #e30613 !important;
    color: white;
    border: 1px solid #e30613;
}
.left:hover i {
    transform: scale(1.3);
}
.btn-light {
    background-color: white !important;
}

.searchInputSpan {
    position: relative;
}
.searchInput {
    padding: 20px;
    border-radius: 17px;
    border: 1px solid rgb(209, 204, 204);
}
.searchInputSpan i {
    position: absolute;
    top: 50%;
    right: 3%;
    transform: translate(-3%, -50%);
}
.leftt {
    background-color: aliceblue !important;
    color: #e30613;
    border: none;
    padding: 11px;
    border-radius: 24px;
    font-weight: 900;
    text-align: center;
    margin: 12px;
}
.lefttt {
    background-color: #e30613 !important;
    color: white;
    border: none;
    padding: 20px;
    border-radius: 24px;
    font-weight: 900;
    text-align: left;
}
.leftt:hover {
    background-color: #e30613 !important;
    color: white;
}
.box {
    height: 300px;
    position: relative;
}
.box a {
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.collg81 {
    background-image: url(../assets/images/img1.jpg);
    background-size: cover;
    border-radius: 0 17px 17px 0;
}
.collg82 {
    background-image: url(../assets/images/img2.jpg);
    background-size: cover;
    border-radius: 17px;
}
@media only screen and (max-width: 600px) {
    .ContactUs {
        overflow-x: hidden;
    }
    .title {
        font-size: 35px;
    }
    .productFilter {
        display: inline-flex;
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 0 !important;
    }
    .productFilter a {
        margin-right: 10px !important;
        min-width: 230px;
        padding-top: 40px !important;
        padding-bottom: 40px !important;
        border-radius: 25px;
    }
    .buttons {
        position: absolute;
        top: 80%;
        right: -44%;
        z-index: 4;
        transform: translate(-50%, -35%);
    }
    .roundedStart {
        border-bottom-left-radius: 0px !important;
        border-top-right-radius: 17px !important;
        border-top-left-radius: 17px !important;
    }
}
.card {
    border-radius: 17px !important;
}
.roundedStart {
    border-bottom-left-radius: 17px;
    border-top-left-radius: 17px;
}
</style>
