<template>
    <section class="HealthCareList">
        <div class="container">
            <h2 class="my-4 title">{{ $t("healthCare") }}</h2>
            <div class="row d-flex justify-content-center">
                <div
                    class="col-lg-12 d-flex justify-content-center"
                    v-if="healthcare.length < 1"
                >
                    <br /><br /><br />
                    <h1>{{ $t("noProducts") }}</h1>
                </div>
            </div>
            <div class="row d-flex justify-content-center">
                <div
                    class="col-lg-3 my-2"
                    v-for="(item, index) in healthcare.slice(_start, _end)"
                    :key="index"
                >
                    <router-link
                        :to="{
                            name: 'HealthCareShow',
                            params: { id: item.id },
                        }"
                    >
                        <div class="card">
                            <div class="sayda">
                                <img
                                    :src="$filters.getImageUrl(item.image)"
                                    class="card-img-top"
                                    alt="pImage"
                                />
                            </div>
                            <div class="card-body">
                                <h5 class="card-title">
                                    {{
                                        item[
                                            "title" +
                                                $filters.ucFirst(
                                                    $store.getters[
                                                        "language/language"
                                                    ]
                                                )
                                        ]
                                    }}
                                </h5>
                                <p
                                    v-html="
                                        $filters.postDesLenght(
                                            item[
                                                'desc' +
                                                    $filters.ucFirst(
                                                        $store.getters[
                                                            'language/language'
                                                        ]
                                                    )
                                            ]
                                        )
                                    "
                                    class="card-text"
                                ></p>
                                <div class="card-footer">
                                    <p
                                        v-if="
                                            $store.getters[
                                                'language/language'
                                            ] !== 'en'
                                        "
                                        class="text-start"
                                    >
                                        {{ $t("seeMore") }}
                                    </p>
                                    <p
                                        v-if="
                                            $store.getters[
                                                'language/language'
                                            ] === 'en'
                                        "
                                        class="text-end"
                                    >
                                        {{ $t("seeMore") }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="row my-5">
                <div
                    class="col-lg-6 d-flex justify-content-center text-center col-6"
                >
                    <button
                        class="btn btn-danger"
                        :disabled="_start == 0"
                        @click="prevPagination"
                    >
                        {{ $t("prev") }}
                    </button>
                </div>
                <div
                    class="col-lg-6 d-flex justify-content-center text-center col-6"
                >
                    <button
                        class="btn btn-danger"
                        :disabled="_end >= healthcare.length"
                        @click="nextPagination"
                    >
                        {{ $t("next") }}
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {
    doc,
    getDocs,
    getFirestore,
    collection,
    deleteDoc,
    orderBy,
} from "firebase/firestore";
import { app } from "@/firebase";

export default {
    name: "HealthCareList",
    data() {
        return {
            healthcare: [],
            _start: 0,
            _end: 12,
            perPage: 12,
        };
    },
    async mounted() {
        const db = getFirestore(app);
        const docSnap = await getDocs(collection(db, "healthcare"));
        this.healthcare = docSnap.docs.map((e) => {
            return e.data();
        });
        this.healthcare.sort((b, a) =>
            a.date > b.date ? 1 : b.date > a.date ? -1 : 0
        );
    },
    methods: {
        async deleteHealthCare(e) {
            this.isLoading = true;
            const db = getFirestore(app);
            const docRef = doc(db, "healthcare", e);
            const docSnap = await deleteDoc(docRef)
                .then(async () => {
                    this.isLoading = false;

                    const db = getFirestore(app);
                    const docSnap = await getDocs(collection(db, "healthcare"));
                    if (docSnap.docs != undefined) {
                        this.healthcare = docSnap.docs.map((e) => {
                            return e.data();
                        });
                        this.healthcare.sort((b, a) =>
                            a.date > b.date ? 1 : b.date > a.date ? -1 : 0
                        );
                    } else {
                        this.healthcare = [];
                    }
                })
                .catch((err) => {
                    console.error(err);
                    this.isLoading = false;
                    this.error = true;
                });
        },
        nextPagination() {
            this._start += this.perPage;
            this._end = this._start + this.perPage;
        },
        prevPagination() {
            this._start -= this.perPage;
            this._end -= this.perPage;
        },
    },
};
</script>

<style scoped>
.title {
    color: #e30613;
    font-weight: 900;
    font-size: 95px;
}
.card {
    height: 406px;
    border: none;
    position: relative;
}
.card-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.sayda img {
    height: 202px;
    object-fit: cover;
    border-radius: 25px;
}
.card-footer {
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    background-color: #ffdae2;
    border: none;
}
.card-body {
    color: black !important;
}
@media only screen and (max-width: 600px) {
    .title {
        font-size: 35px;
    }
}
</style>
