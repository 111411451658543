import { createI18n } from "vue-i18n/index";
import messages from "@/translations";
import store from "../store";
// console.log(store.getters["language/language"]);

export const i18n = createI18n({
    lazy: true,
    silentTranslationWar: process.env.NODE_ENV === "development",
    //   localStorage.setItem('language', 'ku ar en')
    // locale: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
    locale: store.getters["language/language"]
        ? store.getters["language/language"]
        : "en",

    fallbackLocale: ["en", "ar", "ku"],
    messages,
});
