import logo from "@/assets/images/logo.png";

export default {
    timeConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp * 1000);
        var months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + " " + month;
        //   date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
        return time;
    },
    postDesLenght(text, lenght = 80) {
        return text.length > lenght
            ? text.substr(0, lenght) + " ..."
            : text.substr(0, lenght);
    },
    titleLenght(text, lenght = 18) {
        return text.length > lenght
            ? text.substr(0, lenght) + " ..."
            : text.substr(0, lenght);
    },
    ucFirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getImageUrl(url = null) {
        if (url) {
            if (url.startsWith("http://") || url.startsWith("https://")) {
                return url;
            } else {
                return process.env.VUE_APP_BASE_URL + url.replaceAll("\\", "/");
            }
        }
        return logo;
    },
};
