<template>
    <section class="NewsList">
        <div class="container">
            <h2 class="my-4 title">{{ $t("news") }}</h2>
            <div class="row d-flex justify-content-center">
                <div
                    class="col-lg-12 d-flex justify-content-center"
                    v-if="news.length < 1"
                >
                    <br /><br /><br />
                    <h1>{{ $t("noNews") }}</h1>
                </div>
            </div>
            <div class="row d-flex justify-content-center">
                <div
                    class="col-lg-3 my-2"
                    v-for="(item, index) in news.slice(_start, _end)"
                    :key="index"
                >
                    <router-link
                        :to="{
                            name: 'NewsShow',
                            params: { id: item.id },
                        }"
                    >
                        <div class="card">
                            <div class="sayda">
                                <img
                                    :src="$filters.getImageUrl(item.image)"
                                    class="card-img-top"
                                    alt="pImage"
                                />
                            </div>
                            <div class="card-body">
                                <h5 class="card-title">
                                    {{
                                        item[
                                            "title" +
                                                $filters.ucFirst(
                                                    $store.getters[
                                                        "language/language"
                                                    ]
                                                )
                                        ]
                                    }}
                                </h5>
                                <p
                                    v-html="
                                        $filters.postDesLenght(
                                            item[
                                                'desc' +
                                                    $filters.ucFirst(
                                                        $store.getters[
                                                            'language/language'
                                                        ]
                                                    )
                                            ],
                                            50
                                        )
                                    "
                                    class="card-text"
                                ></p>
                            </div>
                            <!-- <div class="card-footer">
                <p>{{ $t("seeMore") }} <i class="fa fa-arrow-right"></i></p>
              </div> -->
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="row my-5">
                <div
                    class="col-lg-6 d-flex justify-content-center text-center col-6"
                >
                    <button
                        class="btn btn-danger"
                        :disabled="_start == 0"
                        @click="prevPagination"
                    >
                        {{ $t("prev") }}
                    </button>
                </div>
                <div
                    class="col-lg-6 d-flex justify-content-center text-center col-6"
                >
                    <button
                        class="btn btn-danger"
                        :disabled="_end >= news.length"
                        @click="nextPagination"
                    >
                        {{ $t("next") }}
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {
    doc,
    getDocs,
    getFirestore,
    collection,
    orderBy,
} from "firebase/firestore";
import { app } from "@/firebase";

export default {
    name: "NewsList",
    data() {
        return {
            news: [],
            _start: 0,
            _end: 12,
            perPage: 12,
        };
    },
    async mounted() {
        const db = getFirestore(app);
        const docSnap = await getDocs(collection(db, "news"));
        this.news = docSnap.docs.map((e) => {
            return e.data();
        });
        this.news.sort((b, a) =>
            a.date > b.date ? 1 : b.date > a.date ? -1 : 0
        );
    },
    methods: {
        loaded() {
        },
        nextPagination() {
            this._start += this.perPage;
            this._end = this._start + this.perPage;
        },
        prevPagination() {
            this._start -= this.perPage;
            this._end -= this.perPage;
        },
    },
};
</script>

<style scoped>
.card {
    border: none;
    height: 436px;
}
.card a {
    text-decoration: none;
    color: black;
}
.card-text p {
    text-decoration: none;
    color: black;
}
.card img {
    height: 280px;
    object-fit: cover;
    border-radius: 25px;
    width: 100%;
}

.card-img-top {
    transition: transform 0.8s ease !important;
}
.sayda {
    border-radius: 25px;
    border: none;
    background-color: transparent;
    overflow: hidden;
    min-height: 300px;
}
.card:hover .card-img-top {
    transform: scale(1.35);
}
.imgCard {
    min-height: 325px;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.title {
    color: #e30613;
    font-weight: 900;
    font-size: 95px;
}
.card-body {
    color: black !important;
}
@media only screen and (max-width: 600px) {
    .title {
        font-size: 35px;
    }
}
</style>
