<template>
    <div class="Edit">
        <div class="container">
            <div
                class="row d-flex justify-content-center my-5 text-center"
                v-if="noData"
            >
                <h1 style="height: 290px">{{ $t("noData") }}</h1>
                <router-link to="/" class="btn btn-danger w-100">{{
                    $t("backToHome")
                }}</router-link>
            </div>
            <div v-else class="row d-flex justify-content-center">
                <div class="col-lg-8">
                    <br /><br /><br /><br /><br />
                    <h1>{{ $t("editNews") }}</h1>
                    <form @submit.prevent="edit">
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("newsTitleKu") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleKu"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("newsTitleAr") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleAr"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("newsTitleEn") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleEn"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>

                        <div class="mb-3">
                            <label for="editKu">{{ $t("newsDescrKu") }}</label>
                            <div id="editKu">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descKu"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="editAr">{{ $t("newsDescrAr") }}r</label>
                            <div id="editAr">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descAr"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="editEn">{{ $t("newsDescrEn") }}</label>
                            <div id="editEn">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descEn"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>
                        <div class="mb-3">
                            <!-- <div v-show="showImageUpload"> -->
                            <label for="image" class="form-label">{{
                                $t("newsImg")
                            }}</label>
                            <input
                                type="file"
                                @change="onFileChange"
                                class="form-control"
                                id="image"
                            />
                            <!-- </div> -->
                        </div>
                        <button
                            type="submit"
                            class="btn btn-primary w-100"
                            :disabled="isLoading"
                        >
                            {{ $t("editNews") }}
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                        <br /><br /><br />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { app } from "@/firebase";
import { uid } from "uid";
//login
import {
    getFirestore,
    collection,
    getDoc,
    doc,
    Timestamp,
    setDoc,
} from "firebase/firestore/lite";
import { getStorage, uploadBytes, getDownloadURL, ref } from "firebase/storage";

export default {
    name: "NewsEdit",
    data() {
        return {
            titleKu: null,
            titleAr: null,
            titleEn: null,
            descKu: "",
            descAr: "",
            descEn: "",
            img: null,
            image: null,
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            isLoading: false,
            error: false,
            noData: false,
        };
    },
    methods: {
        async edit() {
            this.isLoading = true;
            // console.log("bsjbsudgbughdushgdusdhsudhsui", this.img);

            const storage = getStorage(app);
            const id = this.$route.params.id;
            if (this.img != null && this.img) {
                const storageRef = ref(storage, "/News Images/" + id + ".png");
                let snapshot = await uploadBytes(storageRef, this.img);
                this.image = await getDownloadURL(snapshot.ref);
                // console.log("Uploaded a blob or file!", this.image);
            }

            const db = getFirestore(app);
            const groceriesColRef = doc(collection(db, "news"), id);
            return setDoc(groceriesColRef, {
                id: id,
                titleKu: this.titleKu,
                titleAr: this.titleAr,
                titleEn: this.titleEn,
                descKu: this.descKu,
                descAr: this.descAr,
                descEn: this.descEn,
                image: this.image,
                date: Timestamp.now(),
            })
                .then(() => {
                    this.isLoading = false;
                    this.$router.push({
                        name: "NewsList",
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.isLoading = false;
                    this.error = false;
                });
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.img = files[0];
        },
    },
    async mounted() {
        const db = getFirestore(app);

        const docRef = doc(db, "news", this.$route.params.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            this.titleKu = data.titleKu;
            this.titleEn = data.titleEn;
            this.titleAr = data.titleAr;
            this.descKu = data.descKu;
            this.descEn = data.descEn;
            this.descAr = data.descAr;
            this.image = data.image;
        } else {
            // doc.data() will be undefined in this case
            // console.log("No such document!");
            this.noData = true;
        }
    },
};
</script>

<style scoped>
.card-body {
    color: black !important;
}
</style>
