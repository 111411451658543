<template>
    <section class="categories pb-1 pt-1">
        <div class="container">
            <span class="spanTitle" data-aos="fade-up">
                <router-link
                    :to="{
                        name: 'CategoryList',
                    }"
                    class="my-4 title"
                >
                <b>{{ $t("categoryU") }}</b>
                    <!-- {{
                        categoriesSetting[0] && categoriesSetting[0][
                            "titleDesign" +
                                $filters.ucFirst(
                                    $store.getters["language/language"]
                                )
                        ]
                    }} -->
                </router-link>
            </span>
            <br />
            <br />
            <div class="row d-flex justify-content-center align-items-center">
                <div
                    class="justify-content-end px-5 align-items-center d-sm-block d-lg-none"
                    :class="categories.length < 1 ? 'col-lg-12' : 'col-lg-6'"
                >
                    <h2
                        class="text-center my-2"
                        style="font-weight: 900"
                        data-aos="fade-up"
                    >
                        <!-- {{ $t("productText1") }} -->
                        {{
                            categoriesSetting[0] && categoriesSetting[0][
                                "subTitleDesign" +
                                    $filters.ucFirst(
                                        $store.getters["language/language"]
                                    )
                            ]
                        }}
                    </h2>
                    <p class="text-center my-2" data-aos="fade-up">
                        <!-- {{ $t("productText2") }} -->
                        {{
                            categoriesSetting[0] && categoriesSetting[0][
                                "descDesign" +
                                    $filters.ucFirst(
                                        $store.getters["language/language"]
                                    )
                            ]
                        }}
                    </p>
                </div>
                <!-- 2 random categories -->
                <div
                    class="col-lg-3 justify-content-start col-6"
                    data-aos="fade-up"
                    v-for="(item, index) in tows"
                    :key="index"
                >
                    <OneHomeCardCategory :category="item" />
                </div>
                <div
                    class="justify-content-end px-5 align-items-center d-none d-md-block d-lg-block"
                    :class="categories.length < 1 ? 'col-lg-12' : 'col-lg-6'"
                >
                    <!-- <br /><br /><br /><br /> -->
                    <h2
                        class="text-end my-5"
                        style="font-weight: 900"
                        data-aos="fade-right"
                    >
                        {{ $t("productBio") }}
                    </h2>
                    <p class="text-end my-4" data-aos="fade-down">
                        {{ $t("productBioDesc") }}
                    </p>
                </div>
            </div>
        </div>
        <!-- -fluid -->
        <div class="container">
            <div class="row">
                <div
                    class="col-lg-12 d-flex justify-content-center"
                    v-if="categories.length < 1"
                >
                    <h1>{{ $t("noProducts") }}</h1>
                </div>
            </div>
            <div class="row">
                <div
                    class="col-lg-3 col-6"
                    data-aos="fade-up"
                    v-for="(item, index) in categories.slice(_start, _end)"
                    :key="index"
                >
                    <OneHomeCardCategory :category="item" />
                </div>
            </div>
            <div class="row my-5">
                <div
                    class="col-lg-6 d-flex justify-content-center text-center col-6"
                >
                    <button
                        class="btn btn-danger"
                        data-aos="fade-right"
                        style="border-radius: 40px"
                        :disabled="_start == 0"
                        @click="prevPagination"
                    >
                        &lt;
                    </button>
                </div>
                <div
                    class="col-lg-6 d-flex justify-content-center text-center col-6"
                >
                    <button
                        class="btn btn-danger"
                        style="border-radius: 40px"
                        data-aos="fade-left"
                        :disabled="_end >= categories.length"
                        @click="nextPagination"
                    >
                        >
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { getDocs, getFirestore, collection } from "firebase/firestore";
import { app } from "@/firebase";
import OneHomeCardCategory from "./OneHomeCardCategory.vue";

export default {
    name: "Categories",
    components: {
        OneHomeCardCategory,
    },
    data() {
        return {
            tows: [],
            categories: [],
            categoriesSetting: [],
            _start: 0,
            _end: 12,
            perPage: 12,
        };
    },
    async mounted() {
        await this.getAllCategoriesSetting();
        await this.getAllCategories();
    },
    methods: {
        async getAllCategories() {
            const db = getFirestore(app);
            const docSnap = await getDocs(collection(db, "category"));
            this.categories = docSnap.docs.map((e) => {
                return e.data();
            });
            this.categories.sort((b, a) =>
                a.date > b.date ? 1 : b.date > a.date ? -1 : 0
            );
            this.getRandomTwoProducts(docSnap.docs.length - 1);
        },
        async getAllCategoriesSetting() {
            const db = getFirestore(app);
            const docSnap = await getDocs(collection(db, "categorySettings"));
            this.categoriesSetting = docSnap.docs.map((e) => {
                return e.data();
            });
        },
        getRandomTwoProducts(maxNumber, minNumber = 0) {
            let min = Math.ceil(minNumber);
            let max = Math.floor(maxNumber);
            let twoNums = Math.floor(Math.random() * (max - min + 1)) + min;
            if (twoNums - 1 >= maxNumber) {
                twoNums = twoNums - 1;
            }
            //remove random categories from categories set
            this.tows = this.categories.splice(twoNums, 2);
        },
        nextPagination() {
            this._start += this.perPage;
            this._end = this._start + this.perPage;
        },
        prevPagination() {
            this._start -= this.perPage;
            this._end -= this.perPage;
        },
    },
};
</script>

<style src="../assets/css/home/categories.css" scoped></style>
