<template>
    <div class="add">
        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-8">
                    <br /><br /><br /><br /><br />
                    <h1>{{ $t("addSubCategory") }}</h1>
                    <form @submit.prevent="add">
                        <div class="mb-3">
                            <label for="categorySO" class="form-label">{{
                                $t("category")
                            }}</label>
                            <Select2
                                v-model="myValue"
                                :options="myOptions"
                                :settings="{
                                    settingOption: value,
                                    settingOption: value,
                                }"
                                @change="myChangeEvent($event)"
                                @select="mySelectEvent($event)"
                                id="categorySO"
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("subCategoryTitleKu") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleKu"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("subCategoryTitleAr") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleAr"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("subCategoryTitleEn") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleEn"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>

                        <!-- image upload  -->
                        <!-- <div class="mb-3">
              <label for="image" class="form-label">{{ $t("newsImg") }}</label>
              <input
                type="file"
                @change="onFileChange"
                class="form-control"
                id="image"
              />
            </div> -->

                        <!-- loading and errors show here -->
                        <button
                            type="submit"
                            class="btn btn-danger w-100"
                            :disabled="isLoading"
                        >
                            {{ $t("addSubCategory") }}
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                        <br /><br /><br />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import app from "@/firebase";
import { uid } from "uid";
//login
import {
    getFirestore,
    collection,
    Timestamp,
    doc,
    setDoc,
    getDocs,
    query,
    where,
    deleteDoc,
} from "firebase/firestore/lite";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default {
    name: "subCategoryAdd",
    components: { Select2 },
    data() {
        return {
            titleKu: null,
            titleAr: null,
            titleEn: null,
            img: null,
            isLoading: false,
            error: false,
            duplicate: false,
            categoryId: null,
            myValue: "",
            myOptions: [],
        };
    },
    async mounted() {
        const db = getFirestore(app);
        const docSnap = await getDocs(collection(db, "category"));
        this.myOptions = docSnap.docs.map((e) => {
            return {
                text:
                    e.data().titleEn +
                    ` / ` +
                    e.data().titleKu +
                    ` / ` +
                    e.data().titleAr,
                id: e.data().id,
            };
        });

        // this.catEn.sort((b, a) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
    },
    methods: {
        async checkNull() {
            const db = getFirestore(app);
            const citiesRef = collection(db, "subCategories");

            const q = query(citiesRef, where("titleEn", "==", this.titleEn));

            const docSnap = await getDocs(q);
            console.log(docSnap.docs.length);
            if (docSnap.docs.length > 0) {
                this.duplicate = true;
            } else {
                this.duplicate = false;
            }
            return docSnap.docs.length;
        },
        async add() {
            await this.checkNull();
            if (this.duplicate) {
                console.log("This item is already exist");
            } else {
                this.isLoading = true;
                let image = null;
                const storage = getStorage(app);
                const id = uid(16);
                if (this.img != null && this.img) {
                    const storageRef = ref(storage, id + ".png");
                    let snapshot = await uploadBytes(storageRef, this.img);
                    image = await getDownloadURL(snapshot.ref);
                    // console.log("Uploaded a blob or file!", image);
                }

                const db = getFirestore(app);
                const groceriesColRef = doc(
                    collection(db, "subCategories"),
                    id
                );
                return setDoc(groceriesColRef, {
                    id: id,
                    categoryId: this.categoryId,
                    titleKu: this.titleKu,
                    titleAr: this.titleAr,
                    titleEn: this.titleEn,
                    date: Timestamp.now(),
                })
                    .then(() => {
                        this.isLoading = false;
                        this.$router.push({
                            name: "subCategoryList",
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.isLoading = false;
                        this.error = false;
                    });
            }
        },
        myChangeEvent(val) {
            console.log(val);
        },
        mySelectEvent({ id, text }) {
            // console.log("id = ", id);
            // console.log("text = ", text);
            this.categoryId = id;
        },
        // onFileChange(e) {
        //   var files = e.target.files || e.dataTransfer.files;
        //   if (!files.length) return;
        //   this.img = files[0];
        // },
    },
};
</script>

<style></style>
