export default {
    alen: "ئالین",
    // navbar (navbar && MobileNavbar)
    menu: "لیست",
    close: "داخستن",
    home: "ماڵەوە",
    menuText:
        "ئێمە لە ساڵی ١٩٤٤ەوە زەردەخەنە دەخەینە سەر ڕووخساری هەمووان، ژەمە خواردنی سووك و  کەم لە یەک کاتدا، دەتەوێت چیرۆکەکەمان بدۆزیتەوە؟",
    followUs: "هاوڕێمان بە ",
    news: "هەواڵەكان",
    recipes: "ڕێچکەکان",
    healthCare: "چاودێری تەندروستی",
    careers: "كارەكان",
    products: "بەرهەمەكان",
    contactUs: "پەیوەندیمان پێوە بكە",
    aboutUs: "ئێمە كێین",

    // Note => (U = upper case)
    //products (Products & ProductList && ProductAdd && ProductEdit && ProductShow)
    productsU: "بەرهەمەكان",
    productText1:
        "ئالین كۆمپانیایەكی پێشەنگی جیهانیە لە کەرتی خۆراک و پیشەسازی",
    productText2:
        "ئێمە لە خانەوادەی ئالین باشترین کەسانی پسپۆڕ لە وڵاتانی ترەوە بۆ لای خۆمان ڕادەکێشین و باشترین کەرەستەی خاو لە جیهاندا بەکاردەهێنین بۆ بەدەستهێنانی باشترین جۆری خۆراک، هەروەها کارمەندەکانمان بەردەوام کاردەکەن بۆ دابینکردنی بەرزترین ئاستی کوالێتی و بەرهەمهێنان.",
    allProducts: "هەمو بەرهەمەكان",
    searchForProducts: "گەران بە دوای بەرهەمەكان",
    resetFilters: "گەڕاندنەوەی فلتەرەكان",
    backToHome: "گەڕانەوە بۆ پەرەی سەرەتا",
    qrCode: "كیو ئار كۆد",
    editProduct: "دەستكاری كردنی بەرهەم",
    deleteProduct: "سڕینەوەی بەرهەم",
    productBarcode: "باركۆدی بەرهەم",
    productTitleKu: "ناونیشانی بەرهەم بە كوردی",
    productTitleAr: "ناونیشانی بەرهەم بە عەرەبی",
    productTitleEn: "ناونیشانی بەرهەم بە ئینگلیزی",
    productDescrKu: "باسكردنی بەرهەم بە كوردی",
    productDescrAr: "باسكردنی بەرهەم بە عەرەبی",
    productDescrEn: "باسكردنی بەرهەم بە ئینلگیزی",
    productImgHD: "وێنەی بەرهەمەكان (HD)",

    //recipes (underProductSection & RecipesList && RecipesAdd && RecipesEdit && RecipesShow )
    recipesU: "رەچەتەكان",
    recipesText1: "ئالین شتێکی بۆ هەمووان هەیە!",
    recipesText2:
        "ئالێن بە سەدان جۆری جیاوازی بەرهەم لەخۆ دەگرێت. بۆ هەموو ساتێک شیرینی بۆ ژیانت زیاد بکە،خێزانە گەورەکەمان بناسە",
    editRecipes: "چاككردنی ڕەچەتەكان",
    deleteRecipes: "سڕینەوەی ڕەچەتەكان",
    recipesTitleKu: "ناونیشانی ڕەچەتەكان بە كوردی",
    recipesTitleAr: "ناونیشانی ڕەچەتەكان بە عەرەبی",
    recipesTitleEn: "ناونیشانی ڕەچەتەكان بە ئینگلیزی",
    recipesDescrKu: "وەسفكردنی ڕەچەتەكان بە كوردی",
    recipesDescrAr: "وەسفكردنی ڕەچەتەكان بە عەرەبی",
    recipesDescrEn: "وەسفكرجنی ڕەچەتەكان بە ئینگلیزی",
    recipesImg: "بەرزركردنەوەی وێنە",
    productsUsedInRecipes:
        "ئەو بەرهەمانە هەڵبژێرە کە لە ڕەچەتەكاندا بەکارهێنراون",
    recipesDesignSubTitleKu: "دیزاینی ژێرنووسی ڕەچەتەی کوردی",
    recipesDesignSubTitleAr: "ژێرنووسی دیزاینی ڕەچەتەی عەرەبی",
    recipesDesignSubTitleEn: "دیزاینی ژێرنووسی ڕەچەتەی ئینگلیزی",
    recipesDesignDescKu: "وەسفی دیزاینی ڕەچەتەی کوردی",
    recipesDesignDescAr: "وەسفی دیزاینی ڕەچەتەی عەرەبی",
    recipesDesignDescEn: "وەسفی دیزاینی ڕەچەتەی ئینگلیزی",
    topAttachment: "هاوپێچی سەرەوە",
    bottomAttachment: "هاوپێچی خوارەوە",

    //news (News && NewsList && NewsAdd && NewsEdit && NewsShow)
    newsU: "هەواڵەكان",
    seeOur: "سەیری كۆتا هەواڵەكانمان بكە.",
    otherNews: "هەواڵی تر",
    editNews: "چاككردنی هەواڵ",
    deleteNews: "سڕینەوەی هەواڵ",
    newsTitleKu: "سەردێڕی هەواڵ بە كوردی",
    newsTitleAr: "سەردێری هەواڵ بە عەرەبی",
    newsTitleEn: "سەردێری هەواڵ بە ئینگلیزی",
    newsDescrKu: ",وەسفكردنی هەواڵەكان بە كوردی",
    newsDescrAr: "وەسفكردنی هەواڵەكان بە عەرەبی",
    newsDescrEn: "وەسفكردنی هەواڵەكان بە ئینگلیزی",
    newsImg: "وێنەی هەواڵ",

    //videos (UnderNewsSection && UnderNewsMobile && VideoAdd && VideoEdit)
    videos: "ڤیدیۆكان",
    otherVideos: "ڤیدیۆی تر ",
    editVideo: "چاككردنی ڤیدیۆ ",
    deleteVideo: "سرینەوەی ڤیدیۆ",
    videoBarcode: "باركۆدی ڤیدیۆ",
    videoTitleKu: "نانویشانی ڤیدیۆ بە كوردی",
    videoTitleAr: "ناونیشانی ڤیدیۆ بە عەرەبی",
    videoTitleEn: "ناونیشانی ڤیدیۆ بەئینگلیزی",
    videoURL: "  ڤیدیۆURL ",

    //health care (infoSection && HealthCareList && HealthCareAdd && HealthCareEdit && HealthCareShow)
    healthCareU: "چاودێری تەندروستی",
    healthCareText1:
        "لەسەرەتای دامەزراندنیمانەوە ، ئێمە بەردەوامیمان وەک بەشێکی دانەبڕاو لە کارەکەمان بینیوە لە ڕێگەی تێگەیشتنمان لە وەی بەختەوەری ببێتە بەختەوەری. ئێمە شانبەشانی هەموو لایەنە پەیوەندیدارەکانمان لە زنجیرە بەهاکانماندا کاردەکەین بۆ پەرەسەندن و گەشەکردن. کارەکانمان لەم بوارەدا دەتوانرێت لە ژێر شەش پایەدا پۆلێن بکرێت: ژینگە، زنجیرە بەها، خەڵک، داهێنان، بەرپرسیارێتی کۆمەڵایەتی، و سەرکردایەتی.",
    healthCareText2:
        "لەم گەشتەدا ئاماژە بەو ئامانجانە دەکەین کە بۆ ساڵی ٢٠٢٤ دامانناوە و بنەماکانی بەردەوامی کە لە ژێر ئەم شەش پایەدا پەرەمان پێداوە. ئێمە لە نزیکەوە چاودێری ئەجێندا نیشتمانی و نێودەوڵەتییەکان و ڕەوتە جیهانییەکان دەکەین بە خواستی بەشداریکردن لە ئامانجەکانی گەشەپێدانی بەردەوامی نەتەوە یەکگرتووەکان بۆ ساڵی ٢٠٣٠. ئێمە پێمان وایە هەوڵەکانی بەردەوامی تەنها لەو کاتەدا سەرکەوتوو دەبن کە هەموو کۆمپانیاکان و کارمەندان بتوانن لە باوەشیان بگرن.",
    editHealthCare: "چاككردنی چاودێری تەندروستی",
    deleteHealthCare: "سڕینەوەی چاودێری تەندروستی",
    healthcareTitleKu: "ناونیشانی چاودێری تەندروستی بە كوردی",
    healthcareTitleAr: "ناونیشانی چاودێری تەندروستی بە عەرەبی",
    healthcareTitleEn: "نانویشانی چاودێری تەندروستی بە ئینگلیزی",
    healthcareDescrKu: "وەسفكردنی چاودێری تەندروستی بە كوردی",
    healthcareDescrAr: "وەسفكردنی چاودێری تەندروستی بە عەرەبی",
    healthcareDescrEn: "وەسفكردنی چاودێری تەندروستی بە ئینگلیزی",
    healthcareImg: "بەرزكردنەوەی وێنە",
    healthCareDesignSubTitleKu: "ناونیشانی دیزاینی چاودێری تەندروستی بە كوردی",
    healthCareDesignSubTitleAr: "ناونیشانی دیزاینی چاودێری تەندروستی بە عەرەبی",
    healthCareDesignSubTitleEn: "ناونیشانی دیزاینی چاودێری تەندروستی بە ئینگلیزی",
    healthCareDesignDescKu: "وەسفكردنی دیزاینی چاودێری تەندروستی بە كوردی",
    healthCareDesignDescAr: "وەسفكردنی دیزاینی چاودێری تەندروستی بە عەرەبی",
    healthCareDesignDescEn: "وەسفكردنی دیزاینی چاودێری تەندروستی بە ئینگلیزی",

    //footer (Footer && MobileFooter)
    footerText:
        "ئالين ناونیشانی سەرکەوتن",
    cr: "2022 TechEye هەموو مافێكی پارێزراوە بۆ",

    //dashboard
    dashboardText: "بەخێری بێ ی سەرپەرشتیاری ئازیز",
    category: "پۆل",
    jobs: "كارەكان",
    sliders: "سلایدەكان",
    subCategory: "پۆلینی لاوەكی",
    addProduct: "زیادكردنی بەرهەم",
    listProducts: "لیستی بەرهەمەكان",
    addNews: "زیادكردنی هەواڵ",
    listNews: "لیستی هەواڵەكان",
    addRecipes: "زیادكردنی رەچەتەكان",
    listRecipes: "لیستی رەچەتەكان",
    addCategory: "زیادكردنی پۆلێنەكان",
    listCategories: "لیستی پۆلێنەكان",
    addJob: "زیادكردنی كار",
    listJobs: "لیستی كارەكان",
    addSlider: "زیادکردنی سلایدەر",
    listSliders: "لیستی زیادکردنی سلایدەر",
    addSubCategory: "زیادكردنی پۆلینی لاوەكی",
    listSubCategories: "لیستی پۆلینە لاوەكیەكان",
    addVideo: "زیادكردنی ڤیدیۆ",
    listVideos: "لیستی ڤیدیۆكان",
    addHealthCare: "زیادكردنی چاودێری تەندروستی",
    listHealthCares: "لیستی چاودێری تەندروستی",

    //Category (categoryList && categoryAdd && categoryEdit)
    categoryU: "پۆلینەكان",
    addCategory: "زیادكردنی پۆلێن",
    categoryBackgroundColor: "رەنگی دواوەی پۆلێەكان",
    categoryTextColor: "رەنگی نوسینی پۆلێنەكان",
    demoOfYourColors: "دیمۆی ڕەنگەکانت",
    editCategory: "چاككردنی پۆلینەكان",
    deleteCategory: "سڕینەوەی پۆلێنەكان",
    categoryTitleKu: "ناونیشانی پۆلێنەكان بە كوردی",
    categoryTitleAr: "ناونیشانی پۆلێنەكان بە عەرەبی",
    categoryTitleEn: "ناونیشانی پۆلێنەكان بە ئینگلیزی",
    categoryDesignSubTitleKu: "ناونیشانی دیزاینی پۆلێنەكان بە كوردی",
    categoryDesignSubTitleAr: "ناونیشانی دیزاینی پۆلێنەكان بە كوردی",
    categoryDesignSubTitleEn: "ناونیشانی دیزاینی پۆلێنەكان بە كوردی",
    categoryDesignDescKu: "وەسفی دیزاینی پۆلێنەكان بە كوردی",
    categoryDesignDescAr: "وەسفی دیزاینی پۆلێنەكان بە كوردی",
    categoryDesignDescEn: "وەسفی دیزاینی پۆلێنەكان بە كوردی",

    //subCategory (subCategoryList && subCategoryAdd && subCategoryEdit)
    subCategoryU: "پۆلە لاوەكەكان",
    addSubCategory: "زیادكردنی پۆلە لاوەكەكان",
    editSubCategory: "دەستكاریكردنی پۆلە لاوەكەكان",
    deleteSubCategory: "سڕینەوەی پۆلە لاوەكەكان",
    subCategoryTitleKu: "ناونیشانی پۆلە لاوەكەكان بە كوردی",
    subCategoryTitleAr: "ناونیشانی پۆلە لاوەكەكان بە عەەبی",
    subCategoryTitleEn: "ناونیشانی پۆلە لاوەكەكان بە ئینگلیزی",

    //Slider (SliderList && SliderAdd && SliderEdit)
    sliderU: "سلایدەرەكان",
    addSlider: "زیادكرنی سلایدەرەكان",
    editSlider: "دەستكاریكردی سلایدەرەكان",
    deleteSlider: "سڕینەوەی سلایدەرەكان",
    sliderPosition: "شوێنی سلایدەرەكان",
    sliderImg: "هاوپێچی سلایدەرەكان",
    sliderTitleKu: "ناونیشانی سلاید بە كوردی",
    sliderTitleAr: "ناونیشانی سلاید بە عەرەبی",
    sliderTitleEn: "ناونیشانی سلاید بە ئینگلیزی",
    sliderDescrKu: "وەسفكردنی سلاید بە كوردی",
    sliderDescrAr: "وەسف كردنی سلاید بە عەرەبی",
    sliderDescrEn: "وەسفكردنی سلاید بە ئینگلیزی",

    //Job (JobList && JobAdd && JobEdit)
    jobU: "كارەكان",
    addJob: "زید كردنی كار",
    editJob: "دەستكاریكردنی كار",
    deleteJob: "سڕینەوەی كار",
    jobTitleKu: "جۆری كارەكە بە كوردی",
    jobTitleAr: "اجۆری كارەكە بە عەربی",
    jobTitleEn: "جۆری كارەكە بە ئینگلیزی",
    jobDescrKu: "وەسفی كارەكە بە زمانی كوردی",
    jobDescrAr: "وەسفی كارەكە بە زمانی عەرەبی",
    jobDescrEn: "وەسفی كارەكە بە زمانی ئینگلیزی",
    jobImg: "هاوپێچی كار",

    //Password words
    resetPassword: "ڕێکخستنەوەی ووشەی نهێنی",
    resetPasswordSend: "لینکی ڕێکخستنەوە بۆ ئیمەیڵەکەم بنێرە",
    resetPasswordSuccess:
        "تکایە ئیمەیڵەکەت بپشکنە (ئەگەر لە ئیمەیڵدا بوونی نەبوو سپام بپشکنە)",
    resetPasswordFail: "ئیمەیڵ دروست نییە",
    changePassword: "وشەی نهێنی بگۆڕە",
    newPassword: "وشەی نهێنی نوێ",
    changePasswordSuccess: "وشەی نهێنی بە سەرکەوتوویی گۆڕدرا",
    email: "ئیمەیڵ",
    password: "ووشەی نهێنی",

    //global words
    seeMore: "زیاتر ببینە",
    next: "دواتر",
    prev: "پێشتر",
    noData: "داتا بەردەست نیە",
    error4: "ببورە ، جارێكی تر هەوڵ بدەرەوە",
    thumbnail: "وێنەی بچووك كراوە",
    loading: "دابەزاندن ...",
    no: "#",
    logout: "چوونە دەرەوە",
    attachment: "وێنە",
    language: "زمان",
    settings: "رێکخستنەکان",
    saveSettings: "هەڵگرتنی رێکخستنەکان",
    pages: "لاپەڕە",
    barcode: "بارکۆد",

    //awanay dway nardn dastkary krawn

    //aboutUS
    alenFamilly: "خانەوادەی ئالین",
    aboutUsPart1:
        "ئێمە لە بنەماڵەی ئیلین باشترین کەسانی پسپۆڕ لە وڵاتانی دیکەوە ڕادەکێشین و باشترین کەرەستەی خاو لە جیهان بەکاردەهێنین بۆ بەدەستهێنانی باشترین جۆرەکانی خۆراک, ستافەکەمان بەردەوام کاردەکەن بۆ دابینکردنی بەرزترین کوالێتی و بەرهەمهێنان. کۆگاکانمان بە شێوەیەکی ئایدیاڵ دیزاین کراون بۆ پاراستنیان. بەرزترین پلەی پاراستن بۆ ئەو بەرهەمانەی بەرهەم دەهێنرێن بە هەموو ئامێرێکی پێویست لە ژێر چاودێری باشترین پسپۆڕان بریکاری دابەشکردنمان هەیە لە سەرتاسەری عێراق .لەم دواییانەدا دەستمان کرد بە هەناردەکردن بۆ ئەوروپا ئامانجمان ئەوەیە بگەینە هەموو چێشتخانەیەک و دەست لە دڵی هەموو خۆشەویستانی خۆراک بدەین بە باشترین تام.",
    newsDetails: "وردەکاری هەواڵەکان",
    healthCareDetails: "وردەکارییەکانی چاودێری تەندروستی",
    recipesDetails: "وردەکاری ڕێچکەکان",
    office: "ئۆفیس",
    ourLocation: "شوێنی ئێمە",
    productBio: "ئالێن تامێکی دڵخوازی هەیە بۆ هەموو کەسێک!",
    productBioDesc:
        "ئالێن جۆری جیاوازی تام دەگرێت لەگەڵ دەیان براند لە هەموو ساتێکدا ژیانت شیرین دەکەن. خێزانە گەورەکەمان بناسە.",
    address: "عێراق, هەولێر, جادەی کەرکوک",
    noProducts: "هیچ بەرهەمێک بەردەست نییە",
    noHealthcare: "هیچ چاودێرییەکی تەندروستی بەردەست نییە",
    noJobs: "هیچ هەلی کارێک بەردەست نییە",
    noNews: "هیچ هەواڵێک لەبەردەستدا نییە",
    noRecipes: "هیچ ڕێچکەیەک بەردەست نییە",
    noVideos: "هیچ ڤیدیۆیەک بەردەست نییە",
    spiceImage: "وێنەی بەهارات",
};
