<template>
    <div class="login">
        <br /><br /><br /><br />
        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-3">
                    <form
                        class="loginForm shadow"
                        @submit.prevent="loginFunction"
                    >
                        <div class="mb-1">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("email") }}</label
                            >
                            <input
                                type="email"
                                v-model="email"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-1">
                            <label
                                for="exampleInputPassword1"
                                class="form-label"
                                >{{ $t("password") }}</label
                            >
                            <input
                                type="password"
                                v-model="password"
                                class="form-control"
                                id="exampleInputPassword1"
                            />
                        </div>
                        <router-link
                            :to="{
                                name: 'ResetPassword',
                            }"
                            class="mb-2"
                        >
                            {{ $t("resetPassword") }}
                        </router-link>
                        <button
                            type="submit"
                            class="btn btn-primary w-100"
                            :disabled="isLoading"
                        >
                            Login
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                    </form>
                    <br /><br /><br /><br /><br /><br /><br />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Login",
    data() {
        return {
            email: "",
            password: "",
            isLoading: false,
            error: false,
        };
    },
    methods: {
        loginFunction() {
            this.isLoading = true;
            this.$store
                .dispatch("auth/LOGIN", {
                    email: this.email,
                    password: this.password,
                })
                .then(() => {
                    this.isLoading = false;
                    this.$router.push("/");
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoading = false;
                    this.error = true;
                });
        },
    },
};
</script>

<style scoped>
.loginForm {
    background-color: #e90a0a4f;
    padding: 20px;
    border-radius: 10px;
    position: relative;
}
</style>
