<template>
  <div class="laptopShape">
    <svg
      width="1903"
      height="937"
      viewBox="0 0 1903 937"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_51_2)">
        <path
          d="M410.54 281.629L-212 220.023L-0.252079 909.416L381.683 544.32C381.683 544.32 522.857 395.197 509.646 342.345C496.434 289.493 410.54 281.629 410.54 281.629Z"
          :fill="hexColor || '#e30613'"
        />
      </g>
      <defs>
        <clipPath id="clip0_51_2">
          <rect width="1903" height="937" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
  <div class="mobileShape">
    <svg
      width="300"
      height="300"
      viewBox="0 0 1903 1719"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_36_45)">
        <path
          d="M370.54 516.629L-252 455.023L-40.2521 1144.42L341.683 779.32C341.683 779.32 482.857 630.197 469.646 577.345C456.434 524.493 370.54 516.629 370.54 516.629Z"
          :fill="hexColor || '#e30613'"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_36_45"
          x1="-49"
          y1="504"
          x2="452"
          y2="1144"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#e30613" />
          <stop offset="1" stop-color="#e30613" stop-opacity="0" />
        </linearGradient>
        <clipPath id="clip0_36_45">
          <rect width="1903" height="1719" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: "PagesBackground",
  props: ["hexColor"],
};
</script>

<style scoped>
.laptopShape {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}
.mobileShape {
  display: none;
}
@media only screen and (max-width: 600px) {
  .mobileShape {
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
  }
  .laptopShape {
    display: none;
  }
}
</style>
