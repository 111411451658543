<template>
    <div class="add">
        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-8">
                    <br /><br /><br /><br /><br />
                    <h1>{{ $t("addProduct") }}</h1>
                    <form @submit.prevent="add">
                        <div class="mb-3">
                            <label for="exapleInputText" class="form-label">{{
                                $t("productBarcode")
                            }}</label>
                            <input
                                type="number"
                                required
                                v-model="barcode"
                                class="form-control"
                                id="exapleInputText"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <div class="row">
                                <div class="col-6">
                                    <label for="category" class="form-label">{{
                                        $t("category")
                                    }} <span class="text-danger">*</span></label>
                                    <Select2
                                        v-model="myCategoryValue"
                                        style="width: 100% !important"
                                        :options="myCategoryOptions"
                                        @change="myCategoryChangeEvent($event)"
                                        @select="myCategorySelectEvent($event)"
                                        id="category"
                                    />
                                </div>
                                <div class="col-6">
                                    <label
                                        for="subCategory"
                                        class="form-label"
                                        >{{ $t("subCategory") }} <span class="text-danger">*</span></label
                                    >
                                    <Select2
                                        v-model="myValue"
                                        style="width: 100% !important"
                                        :options="myOptions"
                                        @change="myChangeEvent($event)"
                                        @select="mySelectEvent($event)"
                                        id="subCategory"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="exapleInputText" class="form-label">{{
                                $t("productTitleKu")
                            }}</label>
                            <input
                                type="text"
                                required
                                v-model="titleKu"
                                class="form-control"
                                id="exapleInputText"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="exapleInputText" class="form-label">{{
                                $t("productTitleAr")
                            }}</label>
                            <input
                                type="text"
                                required
                                v-model="titleAr"
                                class="form-control"
                                id="exapleInputText"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="exapleInputText" class="form-label">{{
                                $t("productTitleEn")
                            }}</label>
                            <input
                                type="text"
                                required
                                v-model="titleEn"
                                class="form-control"
                                id="exapleInputText"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="addKu">{{
                                $t("productDescrKu")
                            }}</label>
                            <div id="addKu">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descKu"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="addAr">{{
                                $t("productDescrAr")
                            }}</label>
                            <div id="addAr">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descAr"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="addEn">{{
                                $t("productDescrEn")
                            }}</label>
                            <div id="addEn">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descEn"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="row">
                                <div class="col-4">
                                    <label for="images" class="form-label">{{
                                        $t("productImgHD")
                                    }}</label>
                                    <input
                                        type="file"
                                        @change="onFileChange"
                                        class="form-control"
                                        multiple="multiple"
                                        id="images"
                                    />
                                </div>
                                <div class="col-4">
                                    <label
                                        for="thumbImages"
                                        class="form-label"
                                        >{{ $t("thumbnail") }}</label
                                    >
                                    <input
                                        type="file"
                                        @change="onThumbFileChange"
                                        class="form-control"
                                        id="thumbImages"
                                    />
                                </div>
                                <div class="col-4">
                                    <label
                                        for="spiceImage"
                                        class="form-label"
                                        >{{ $t("spiceImage") }}</label
                                    >
                                    <input
                                        type="file"
                                        @change="onSpiceImageChange"
                                        class="form-control"
                                        id="spiceImage"
                                    />
                                </div>
                            </div>
                        </div>
                        <button
                            type="submit"
                            class="btn btn-danger w-100"
                            :disabled="isLoading && disabled"
                        >
                            {{ $t("addProduct") }}
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error2">
                            please check all required fields
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                        <br /><br /><br />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import app from "@/firebase";
import { uid } from "uid";
//login
import {
    getFirestore,
    getDocs,
    collection,
    Timestamp,
    doc,
    setDoc,
    query,
    where,
    deleteDoc,
} from "firebase/firestore/lite";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default {
    name: "ProductAdd",
    components: { Select2 },
    // watch: {},
    data() {
        return {
            subCategoryId: null,
            categoryId: null,
            titleKu: null,
            titleAr: null,
            titleEn: null,
            descKu: "",
            descAr: "",
            descEn: "",
            barcode: null,
            duplicate: false,
            fileImages: [],
            thumbFileImage: null,
            spiceImage: null,
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            isLoading: false,
            error: false,
            error2: false,
            myValue: "",
            myOptions: [],
            myCategoryValue: "",
            myCategoryOptions: [],
        };
    },
    methods: {
        async add() {
            if (this.categoryId != null) {
                this.error2 = false;
                const docId = uid(16);

                await this.checkIfBarcodeExist();
                if (this.duplicate) {
                    // console.log("This item is already exist");
                } else {
                    this.isLoading = true;
                    // console.log("fileImages = ", this.fileImages);
                    let images = [];
                    const storage = getStorage(app);
                    if (this.fileImages != null && this.fileImages) {
                        for (let i = 0; i < this.fileImages.length; i++) {
                            const id = uid(16);

                            let storageRef = ref(
                                storage,
                                "/Products Image/" + id + ".png"
                            );
                            let snapshot = await uploadBytes(
                                storageRef,
                                this.fileImages[i]
                            );
                            let url = await getDownloadURL(snapshot.ref);
                            images.push({
                                order: i + 1,
                                url: url,
                            });
                        }
                    }
                    let thumbImage;
                    if (this.thumbFileImage != null && this.thumbFileImage) {
                        // console.log("thumbImage = ", this.thumbFileImage);
                        const id = docId + "thumbnail";
                        let storageRef = ref(
                            storage,
                            "/Products Images/" + id + ".png"
                        );
                        let snapshot = await uploadBytes(
                            storageRef,
                            this.thumbFileImage
                        );
                        let url = await getDownloadURL(snapshot.ref);
                        thumbImage = url;
                    }
                    let spiceImage;
                    if (this.spiceImage != null && this.spiceImage) {
                        const id = docId + "spiceImage";

                        let storageRef = ref(
                            storage,
                            "/Products Images/" + id + ".png"
                        );
                        let snapshot = await uploadBytes(
                            storageRef,
                            this.spiceImage
                        );
                        let url = await getDownloadURL(snapshot.ref);
                        spiceImage = url;
                    }

                    const db = getFirestore(app);
                    const groceriesColRef = doc(
                        collection(db, "products"),
                        docId
                    );
                    return setDoc(groceriesColRef, {
                        id: docId,
                        barcode: this.barcode,
                        categoryId: this.categoryId,
                        subCategoryId: this.subCategoryId,
                        titleKu: this.titleKu,
                        titleAr: this.titleAr,
                        titleEn: this.titleEn,
                        descKu: this.descKu,
                        descAr: this.descAr,
                        descEn: this.descEn,
                        images: images ?? null,
                        thumbImage: thumbImage ?? null,
                        spiceImage: spiceImage ?? null,
                        date: Timestamp.now(),
                    })
                        .then(() => {
                            this.isLoading = false;
                            this.$router.push({
                                name: "ProductsList",
                            });
                        })
                        .catch((err) => {
                            console.log(err);
                            this.isLoading = false;
                            this.error = false;
                        });
                }
            } else {
                this.error2 = true;
            }
        },
        async checkIfBarcodeExist() {
            const db = getFirestore(app);
            const barcodeRef = collection(db, "products");
            const q = query(barcodeRef, where("barcode", "==", this.barcode));
            const docSnap = await getDocs(q);
            if (docSnap.docs.length > 0) {
                this.duplicate = true;
            } else {
                this.duplicate = false;
            }
            return docSnap.docs.length;
        },
        myChangeEvent(val) {
            console.log(val);
        },
        mySelectEvent({ id, text }) {
            // console.log("id = ", id);
            // console.log("text = ", text);
            this.subCategoryId = id;
        },
        myCategoryChangeEvent(val) {
            console.log(val);
        },
        async myCategorySelectEvent({ id, text }) {
            // console.log("id = ", id);
            // console.log("text = ", text);
            this.categoryId = id;

            const db = getFirestore(app);
            const subCategoriesRef = collection(db, "subCategories");

            const subCats = query(
                subCategoriesRef,
                where("categoryId", "==", id)
            );

            const docSnap = await getDocs(subCats);

            if (docSnap.docs.length > 0) {
                this.myOptions = docSnap.docs.map((e) => {
                    return {
                        text:
                            e.data().titleEn +
                            ` / ` +
                            e.data().titleKu +
                            ` / ` +
                            e.data().titleAr,
                        id: e.data().id,
                    };
                });
            } else {
                this.myOptions = [];
            }
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.fileImages = files;
        },
        onThumbFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.thumbFileImage = files[0];
        },
        onSpiceImageChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.spiceImage = files[0];
        },
        async getAllCategories() {
            const db = getFirestore(app);
            const docSnap = await getDocs(collection(db, "category"));
            this.myCategoryOptions = docSnap.docs.map((e) => {
                return {
                    text:
                        e.data().titleEn +
                        ` / ` +
                        e.data().titleKu +
                        ` / ` +
                        e.data().titleAr,
                    id: e.data().id,
                };
            });
        },
    },
    async mounted() {
        await this.getAllCategories();
        const db = getFirestore(app);
        const docSnap = await getDocs(collection(db, "subCategories"));
        this.myOptions = docSnap.docs.map((e) => {
            return {
                text:
                    e.data().titleEn +
                    ` / ` +
                    e.data().titleKu +
                    ` / ` +
                    e.data().titleAr,
                id: e.data().id,
            };
        });
    },
};
</script>

<style></style>
