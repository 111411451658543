<template>
    <div class="Edit">
        <div class="container">
            <div
                class="row d-flex justify-content-center my-5 text-center"
                v-if="noData"
            >
                <h1 style="height: 290px">{{ $t("noData") }}</h1>
                <router-link to="/" class="btn btn-danger w-100">{{
                    $t("backToHome")
                }}</router-link>
            </div>
            <div v-else class="row d-flex justify-content-center">
                <div class="col-lg-8">
                    <br /><br /><br /><br /><br />
                    <h1>{{ $t("editRecipes") }}</h1>
                    <form @submit.prevent="edit">
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("recipesTitleKu") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleKu"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("recipesTitleAr") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleAr"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("recipesTitleEn") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleEn"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="multiSelect" class="form-label">{{
                                $t("productsUsedInRecipes")
                            }}</label>
                            <Multiselect
                                v-model="selectedValue"
                                mode="tags"
                                :object="true"
                                :close-on-select="false"
                                :searchable="true"
                                :create-option="false"
                                :options="myOptions"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="editKu">{{
                                $t("recipesDescrKu")
                            }}</label>
                            <div id="editKu">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descKu"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="editAr">{{
                                $t("recipesDescrAr")
                            }}</label>
                            <div id="editAr">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descAr"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="editEn">{{
                                $t("recipesDescrEn")
                            }}</label>
                            <div id="editEn">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descEn"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="image" class="form-label">{{
                                $t("recipesImg")
                            }}</label>
                            <input
                                type="file"
                                @change="onFileChange"
                                class="form-control"
                                id="image"
                            />
                            <!-- </div> -->
                        </div>
                        <button
                            type="submit"
                            class="btn btn-primary w-100"
                            :disabled="isLoading"
                        >
                            {{ $t("editRecipes") }}
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                        <br /><br /><br />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { app } from "@/firebase";
import { uid } from "uid";
//login
import {
    getFirestore,
    collection,
    getDoc,
    doc,
    Timestamp,
    setDoc,
    getDocs,
    query,
    where,
} from "firebase/firestore/lite";
import { getStorage, uploadBytes, getDownloadURL, ref } from "firebase/storage";
import Multiselect from "@vueform/multiselect";

export default {
    name: "RecipesEdit",
    components: {
        Multiselect,
    },
    data() {
        return {
            titleKu: null,
            titleAr: null,
            titleEn: null,
            descKu: "",
            descAr: "",
            descEn: "",
            img: null,
            image: null,
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            isLoading: false,
            error: false,
            noData: false,
            productsUsed: [],
            myOptions: [],
            selectedValue: [],
        };
    },
    methods: {
        async edit() {
            this.isLoading = true;
            const storage = getStorage(app);
            const id = this.$route.params.id;
            if (this.img != null && this.img) {
                const storageRef = ref(storage, id + ".png");
                let snapshot = await uploadBytes(storageRef, this.img);
                this.image = await getDownloadURL(snapshot.ref);
                // console.log("Uploaded a blob or file!", this.image);
            }

            const db = getFirestore(app);
            const groceriesColRef = doc(collection(db, "recipes"), id);
            return setDoc(groceriesColRef, {
                id: id,
                productsUsed: this.selectedValue.map((e) => {
                    return e.value;
                }),
                titleKu: this.titleKu,
                titleAr: this.titleAr,
                titleEn: this.titleEn,
                descKu: this.descKu,
                date: Timestamp.now(),
                descAr: this.descAr,
                descEn: this.descEn,
                image: this.image,
            })
                .then(() => {
                    this.isLoading = false;
                    this.$router.push({
                        name: "Dashboard",
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.isLoading = false;
                    this.error = false;
                });
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.img = files[0];
        },
        async getOne() {
            const db = getFirestore(app);

            const docRef = doc(db, "recipes", this.$route.params.id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                this.getAllProductsUsed(docSnap.data().productsUsed);
                this.titleKu = data.titleKu;
                this.titleEn = data.titleEn;
                this.titleAr = data.titleAr;
                this.image = data.image;
                this.descKu = data.descKu;
                this.descEn = data.descEn;
                this.descAr = data.descAr;
            } else {
                this.noData = true;
            }
        },
        async getAllProductsUsed(id) {
            const db = getFirestore(app);
            const productsRef = collection(db, "products");

            const productsList = query(productsRef, where("id", "in", id));

            const docSnap = await getDocs(productsList);

            this.productsUsed = docSnap.docs.map((e) => {
                return {
                    label:
                        e.data().titleEn +
                        ` / ` +
                        e.data().titleKu +
                        ` / ` +
                        e.data().titleAr,
                    value: e.data().id,
                };
            });
            this.selectedValue = docSnap.docs.map((e) => {
                return {
                    label:
                        e.data().titleEn +
                        ` / ` +
                        e.data().titleKu +
                        ` / ` +
                        e.data().titleAr,
                    value: e.data().id,
                };
            });
        },
        async getAllProducts() {
            const db = getFirestore(app);
            const docSnap = await getDocs(collection(db, "products"));
            this.myOptions = docSnap.docs.map((e) => {
                return {
                    label:
                        e.data().titleEn +
                        ` / ` +
                        e.data().titleKu +
                        ` / ` +
                        e.data().titleAr,
                    value: e.data().id,
                };
            });
        },
    },
    async mounted() {
        this.getOne();
        this.getAllProducts();
    },
};
</script>

<style></style>
