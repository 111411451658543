<template>
    <section
        class="pt-2"
        style="direction: ltr"
        data-aos="fade-down"
        :class="[
            $store.getters['language/language'] === 'ar'
                ? 'underPostCompMobileAr'
                : $store.getters['language/language'] === 'ku'
                ? 'underPostCompMobileKu'
                : 'underPostCompMobile',
        ]"
    >
        <div class="mobileCarousel">
            <swiper
                :slidesPerView="1"
                :grid="{
                    rows: 2,
                }"
                :spaceBetween="30"
                :pagination="{
                    clickable: true,
                }"
                :modules="modules"
                class="mySwiper"
                data-aos="fade-up"
            >
                <swiper-slide v-for="(item, index) in videos" :key="index">
                    <div class="row d-flex justify-content-center">
                        <div
                            class="col-lg-6 justify-content-center"
                            data-aos="fade-left"
                        >
                            <iframe
                                width="280"
                                height="200"
                                frameborder="0"
                                allowfullscreen
                                :src="`https://www.youtube.com/embed/${
                                    item && item.videoID
                                }`"
                            ></iframe>
                        </div>
                        <div
                            class="col-lg-6 justify-content-start align-items-center"
                        >
                            <router-link
                                :to="{
                                    name: 'VideoList',
                                }"
                            >
                                <h2
                                    v-html="
                                        $filters.titleLenght(
                                            item[
                                                'desc' +
                                                    $filters.ucFirst(
                                                        $store.getters[
                                                            'language/language'
                                                        ]
                                                    )
                                            ],
                                            100
                                        )
                                    "
                                    data-aos="fade-up"
                                    class="pt-2"
                                ></h2>
                            </router-link>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/grid";
import "swiper/css/pagination";

// import required modules
import { Pagination, Grid } from "swiper";
import {
    doc,
    getDocs,
    getFirestore,
    collection,
    orderBy,
} from "firebase/firestore";
import { app } from "@/firebase";

export default {
    name: "UnderNewsMobile",
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Pagination, Grid],
        };
    },
    data() {
        return {
            videos: [],
        };
    },
    async mounted() {
        const db = getFirestore(app);
        const docSnap = await getDocs(collection(db, "videos"));
        this.videos = docSnap.docs.map((e) => {
            return e.data();
        });
        this.videos.sort((b, a) =>
            a.date > b.date ? 1 : b.date > a.date ? -1 : 0
        );
    },
};
</script>

<style src="../assets/css/home/underNewsMobile.css" scoped></style>
