const language = {
    namespaced: true,
    state: {
        language: "en",
    },
    mutations: {
        SET_LANGUAGE(state, value) {
            return (state.language = value);
        },
    },
    actions: {
        CHANGE_LANGUAGE({ commit }, e) {
            return new Promise((resolve, reject) => {
                if (e != "" || e != null) {
                    commit("SET_LANGUAGE", e);
                    localStorage.setItem("lang", e);
                    if (e === "ku" || e === "ar") {
                        document
                            .querySelector("html")
                            .setAttribute("dir", "rtl");
                        // let allTextStarts = document.querySelectorAll(".text-start");
                        // for (const allTextStart of allTextStarts) {
                        //   allTextStart.classList.remove("text-start");
                        //   allTextStart.classList.add("text-end");
                        // }
                        // let allBtnPrimaries = document.querySelectorAll(
                        //   ".myMobNav .btn-primary"
                        // );
                        // for (const allBtnPrimary of allBtnPrimaries) {
                        //   allBtnPrimary.classList.remove("btn-primary");
                        //   allBtnPrimary.classList.add("btn-primary-rtl");
                        // }
                    } else {
                        document.querySelector("html").removeAttribute("dir");
                        // let allTextStarts = document.querySelectorAll(".text-end");
                        // for (const allTextStart of allTextStarts) {
                        //   allTextStart.classList.add("text-end");
                        //   allTextStart.classList.remove("text-end");
                        // }
                        // let allBtnPrimaries = document.querySelectorAll(
                        //   ".myMobNav .btn-primary-rtl"
                        // );
                        // for (const allBtnPrimary of allBtnPrimaries) {
                        //   allBtnPrimary.classList.remove("btn-primary-rtl");
                        //   allBtnPrimary.classList.add("btn-primary");
                        // }
                    }
                    resolve(e);
                } else {
                    reject(e);
                }
            });
        },
    },
    getters: {
        language: (state) => state.language,
    },
};

export default language;
