<template>
    <div class="add">
        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-8">
                    <br /><br /><br /><br /><br />
                    <h1>{{ $t("addVideo") }}</h1>
                    <form @submit.prevent="add">
                        <div class="mb-3">
                            <label for="addKu">{{ $t("videoTitleKu") }}</label>
                            <div id="addKu">
                                <input
                                    type="text"
                                    required
                                    v-model="descKu"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                />
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="addAr">{{ $t("videoTitleAr") }}</label>
                            <div id="addAr">
                                <input
                                    type="text"
                                    required
                                    v-model="descAr"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                />
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="addEn">{{ $t("videoTitleEn") }}</label>
                            <div id="addEn">
                                <input
                                    type="text"
                                    required
                                    v-model="descEn"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                />
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="link" class="form-label">{{
                                $t("videoURL")
                            }}</label>
                            <input
                                type="url"
                                v-model="videoURL"
                                class="form-control"
                                id="link"
                            />
                        </div>
                        <button
                            type="submit"
                            class="btn btn-danger w-100"
                            :disabled="isLoading"
                        >
                            {{ $t("addVideo") }}
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                        <br /><br /><br />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import app from "@/firebase";
import { uid } from "uid";
//login
import {
    getFirestore,
    collection,
    Timestamp,
    doc,
    setDoc,
    deleteDoc,
} from "firebase/firestore/lite";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default {
    name: "VideoAdd",
    data() {
        return {
            descKu: "",
            descAr: "",
            descEn: "",
            videoURL: "",
            img: null,
            isLoading: false,
            error: false,
        };
    },
    methods: {
        async add() {
            this.isLoading = true;

            var rx =
                /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
            const a = this.videoURL.match(rx);

            let link = null;
            const storage = getStorage(app);
            const id = uid(16);
            if (this.img != null && this.img) {
                const storageRef = ref(storage, id + ".png");
                let snapshot = await uploadBytes(storageRef, this.img);
                link = await getDownloadURL(snapshot.ref);
                // console.log("Uploaded a blob or file!", link);
            }

            const db = getFirestore(app);
            const groceriesColRef = doc(collection(db, "videos"), id);
            return setDoc(groceriesColRef, {
                id: id,
                date: Timestamp.now(),
                descKu: this.descKu,
                descAr: this.descAr,
                descEn: this.descEn,
                videoID: a[1],
            })
                .then(() => {
                    this.isLoading = false;
                    this.$router.push({
                        name: "VideoList",
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoading = false;
                    this.error = false;
                });
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.img = files[0];
        },
    },
};
</script>

<style></style>
