<template>
    <div class="add">
        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-8">
                    <br /><br /><br /><br /><br />
                    <h1>{{ $t("settings") }}</h1>
                    <br /><br />
                    <form @submit.prevent="save">
                        <!-- <div class="mb-3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-lg-4">
                                    <label
                                        for="titleDesignKu"
                                        class="form-label"
                                        >{{
                                            $t("aboutUsDesignTitleKu")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="titleDesignKu"
                                        class="form-control"
                                        id="titleDesignKu"
                                    />
                                </div>

                                <div class="col-lg-4">
                                    <label
                                        for="titleDesignAr"
                                        class="form-label"
                                        >{{
                                            $t("aboutUsDesignTitleAr")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="titleDesignAr"
                                        class="form-control"
                                        id="titleDesignAr"
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <label
                                        for="titleDesignEn"
                                        class="form-label"
                                        >{{
                                            $t("aboutUsDesignTitleEn")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="titleDesignEn"
                                        class="form-control"
                                        id="titleDesignEn"
                                    />
                                </div>
                            </div>
                        </div> -->
                        <div class="mb-3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-lg-4">
                                    <label
                                        for="subTitleDesignKu"
                                        class="form-label"
                                        >{{
                                            $t("aboutUsDesignSubTitleKu")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="subTitleDesignKu"
                                        class="form-control"
                                        id="subTitleDesignKu"
                                    />
                                </div>

                                <div class="col-lg-4">
                                    <label
                                        for="subTitleDesignAr"
                                        class="form-label"
                                        >{{
                                            $t("aboutUsDesignSubTitleAr")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="subTitleDesignAr"
                                        class="form-control"
                                        id="subTitleDesignAr"
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <label
                                        for="subTitleDesignEn"
                                        class="form-label"
                                        >{{
                                            $t("aboutUsDesignSubTitleEn")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="subTitleDesignEn"
                                        class="form-control"
                                        id="subTitleDesignEn"
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div class="mb-3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-lg-12">
                                    <label for="image" class="form-label"
                                        >{{ $t('attachment') }}</label
                                    >
                                    <input
                                        type="file"
                                        @change="onFIleChange"
                                        class="form-control"
                                        id="image"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- loading and errors show here -->
                        <button
                            type="submit"
                            class="btn btn-danger w-100"
                            :disabled="isLoading"
                        >
                            {{ $t("saveSettings") }}
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                        <br /><br /><br />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { app } from "@/firebase";
import {
    getFirestore,
    collection,
    getDoc,
    doc,
    Timestamp,
    setDoc,
} from "firebase/firestore/lite";
import { getStorage, uploadBytes, getDownloadURL, ref } from "firebase/storage";

export default {
    name: "AboutUsSettings",
    data() {
        return {
            // titleDesignKu: "",
            // titleDesignAr: "",
            // titleDesignEn: "",
            subTitleDesignKu: "",
            subTitleDesignAr: "",
            subTitleDesignEn: "",
            Img: null,
            Image: null,
            isLoading: false,
            error: false,
            noData: false,
        };
    },
    methods: {
        async save() {
            const id = this.$route.params.id;
            this.isLoading = true;
            const storage = getStorage(app);
            let topImageSendForDatabase = this.Image;

            if (this.Img != null && this.Img) {
                const id = this.$route.params.id + "Image";
                const storageRef = ref(
                    storage,
                    "/About Us Design Images/" + id + ".png"
                );
                let topSnapshot = await uploadBytes(storageRef, this.Img);
                topImageSendForDatabase = await getDownloadURL(topSnapshot.ref);
            }
            
            const db = getFirestore(app);
            const groceriesColRef = doc(collection(db, "aboutUsSettings"), id);
            return setDoc(groceriesColRef, {
                id: id,
                // titleDesignKu: this.titleDesignKu,
                // titleDesignAr: this.titleDesignAr,
                // titleDesignEn: this.titleDesignEn,
                subTitleDesignKu: this.subTitleDesignKu,
                subTitleDesignAr: this.subTitleDesignAr,
                subTitleDesignEn: this.subTitleDesignEn,
                Image: topImageSendForDatabase ?? null,
                date: Timestamp.now(),
            })
                .then(() => {
                    this.isLoading = false;
                    this.$router.push({
                        name: "AboutUs",
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.isLoading = false;
                    this.error = false;
                });
        },
        onFIleChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.Img = files[0];
        },
    },
    async mounted() {
        const db = getFirestore(app);

        const docRef = doc(db, "aboutUsSettings", this.$route.params.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            // this.titleDesignKu = data.titleDesignKu;
            // this.titleDesignAr = data.titleDesignAr;
            // this.titleDesignEn = data.titleDesignEn;
            this.subTitleDesignKu = data.subTitleDesignKu;
            this.subTitleDesignAr = data.subTitleDesignAr;
            this.subTitleDesignEn = data.subTitleDesignEn;
            this.Image = data.Image;
        } else {
            this.noData = true;
        }
    },
};
</script>

<style></style>
