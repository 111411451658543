<template>
    <section class="allProducts pt-1 pb-5">
        <!-- <PagesBackground :hexColor="navbarFillHexColor" /> -->
        <div class="container p-1">
            <div class="d-flex justify-content-between">
                <h1 class="my-4 title d-inline-block">
                    {{ $t("allProducts") }}
                </h1>
                <h1 class="my-4 title d-inline-block">
                    <button
                        class="btn btn-secondary"
                        style="position: relative; z-index: 8"
                        @click="resetFilters"
                    >
                        {{ $t("resetFilters") }}
                    </button>
                </h1>
            </div>

            <div class="row">
                <div class="col-lg-3 text-center">
                    <div class="productFilter">
                        <div
                            v-for="(catItem, index) in categories"
                            :key="index"
                            class="me-3"
                        >
                            <OneCategoryCradForProductList
                                :category="catItem"
                                :categoryAllTextColor="categoryAllTextColor"
                                :selectedCatId="$route.query.id"
                                @click="emitSelectedCategory(catItem.id)"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="my-3">
                        <div
                            :class="
                                $store.getters['language/language'] === 'en'
                                    ? 'searchInputSpan'
                                    : 'searchInputSpanRTL'
                            "
                        >
                            <input
                                v-model="searchVal"
                                type="text"
                                required
                                class="form-control shadow-lg searchInput"
                                id="exampleFormControlInput1"
                                :placeholder="$t('searchForProducts')"
                                @input="search"
                            />
                            <i class="fa-solid fa-magnifying-glass"></i>
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="col-lg-12"> -->
                        <div class="subCat">
                            <router-link
                                v-for="(subCatItem, index) in subCategories"
                                :key="index"
                                :to="{
                                    name: 'ProductsList',
                                    query: {
                                        subCatID: subCatItem.id,
                                        id: subCatItem.categoryId,
                                    },
                                }"
                                @click="
                                    emitSelectedCategory(subCatItem.categoryId)
                                "
                                class="btn btn-secondary mx-2"
                                style="
                                    border-radius: 17px;
                                    border-color: transparent !important;
                                "
                                :style="[
                                    $route.query.subCatID == subCatItem.id
                                        ? `background-color: ${navbarFillHexColor} !important;color: ${categoryAllTextColor} !important;`
                                        : 'background-color: #f2d6d8; color:#d23e61',
                                ]"
                            >
                                {{
                                    subCatItem[
                                        "title" +
                                            $filters.ucFirst(
                                                $store.getters[
                                                    "language/language"
                                                ]
                                            )
                                    ]
                                }}
                            </router-link>
                        </div>

                        <!-- </div> -->
                    </div>
                    <!-- products -->
                    <div class="row">
                        <div
                            class="col-lg-12 d-flex justify-content-center"
                            v-if="products.length < 1"
                        >
                            <br /><br /><br />
                            <img
                                src="../../assets/images/noData.png"
                                width="400"
                                alt="noData"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div
                            class="col-lg-3 col-6"
                            v-for="(item, index) in products.slice(
                                _start,
                                _end
                            )"
                            :key="index"
                        >
                            <OneCardProduct
                                :product="item"
                                :navbarFillHexColor="navbarFillHexColor"
                            />
                        </div>
                    </div>
                    <br />
                    <center><hr width="75%" /></center>
                    <div class="row my-4" v-if="products.length > 1">
                        <div
                            class="col-lg-5 d-flex justify-content-center text-center col-4"
                        >
                            <button
                                class="btn btn-danger"
                                :disabled="_start == 0"
                                @click="prevPagination"
                            >
                                {{ $t("prev") }}
                            </button>
                        </div>
                        <div
                            class="col-lg-2 d-flex justify-content-center text-danger text-center col-4"
                        >
                            <p class="h5 my-2">
                                <b> {{ pageNumber }} / {{ numberOfPages }} </b>
                            </p>
                            &nbsp;&nbsp;
                            <small class="my-2">{{ $t("pages") }}</small>
                        </div>
                        <div
                            class="col-lg-5 d-flex justify-content-center text-center col-4"
                        >
                            <button
                                class="btn btn-danger"
                                :disabled="_end >= originalProducts.length"
                                @click="nextPagination"
                            >
                                {{ $t("next") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {
    getDocs,
    getFirestore,
    collection,
    query,
    where,
} from "firebase/firestore";
import { app } from "@/firebase";
import OneCardProduct from "../../components/OneCardProduct.vue";
import OneCategoryCradForProductList from "@/components/OneCategoryCradForProductList.vue";

export default {
    name: "ProductsList",
    props: ["navbarFillHexColor", "categoryAllTextColor"],
    components: {
        OneCardProduct,
        OneCategoryCradForProductList,
    },
    watch: {
        "$route.query.id": {
            async handler(catID) {
                if (
                    catID != null &&
                    catID != undefined &&
                    catID != "" &&
                    (this.$route.query.subCatID == null ||
                        this.$route.query.subCatID == undefined ||
                        this.$route.query.subCatID == "" ||
                        !this.$route.query.subCatID)
                ) {
                    await this.getAllProductsByCategoryId(catID);
                    await this.getAllSubCategoriesOnSelectCategory(catID);
                }
            },
            deep: true,
        },
        "$route.query.subCatID": {
            async handler(newSubCatID) {
                if (
                    newSubCatID != null &&
                    newSubCatID != undefined &&
                    newSubCatID != ""
                ) {
                    await this.getAllProductsBySubCategoryId(newSubCatID);
                }
            },
            deep: true,
        },
    },
    data() {
        return {
            products: [],
            originalProducts: [],
            categories: [],
            subCategories: [],
            searchVal: "",
            checked: true,
            _start: 0,
            _end: 12,
            perPage: 12,
            numberOfPages: 0,
            pageNumber: 1,
        };
    },
    methods: {
        emitSelectedCategory(categoryId) {
            const selectedCategory = this.categories.find(
                (e) => e.id === categoryId
            );
            this.$emit("changeColor", {
                backColor: selectedCategory.categoryColor,
                textColor: selectedCategory.categoryTextColor,
            });
        },
        async getAll() {
            const db = getFirestore(app);
            const docSnap = await getDocs(collection(db, "products"));
            this.originalProducts = docSnap.docs.map((e) => {
                return e.data();
            });
            this.originalProducts.sort((b, a) =>
                a.date > b.date ? 1 : b.date > a.date ? -1 : 0
            );
            this.search();
            this.calculateNumberOfPages(docSnap.docs.length);
        },
        async getAllProductsByCategoryId(catID) {
            console.log("getAllProductsByCategoryId worked");
            this.originalProducts = [];
            const db = getFirestore(app);
            const productsRef = collection(db, "products");
            const productById = query(
                productsRef,
                where("categoryId", "==", catID)
            );
            const docSnap = await getDocs(productById);
            if (docSnap.docs.length > 0) {
                this.originalProducts = docSnap.docs.map((e) => {
                    return e.data();
                });
                this.originalProducts.sort((b, a) =>
                    a.date > b.date ? 1 : b.date > a.date ? -1 : 0
                );
                this.search();
                this.calculateNumberOfPages(docSnap.docs.length);
            } else {
                this.originalProducts = [];
                this.search();
                this.calculateNumberOfPages(0);
            }
        },
        async getAllCategories() {
            const db = getFirestore(app);
            const docSnap = await getDocs(collection(db, "category"));
            this.categories = docSnap.docs.map((e) => {
                return e.data();
            });
            this.categories.sort((b, a) =>
                a.date > b.date ? 1 : b.date > a.date ? -1 : 0
            );
        },
        async getAllProductsBySubCategoryId(newSubCatID) {
            console.log("getAllProductsBySubCategoryId worked");
            // if you need to remove just one parameter from query use this below
            // let query = Object.assign({}, this.$route.query);
            // delete query.id;
            // delete query.newSubCatID;
            // this.$router.replace({ query });
            //but if you need to remove all parameters from query use this below
            // this.$router.replace({});
            this.originalProducts = [];
            const db = getFirestore(app);
            const productsRef = collection(db, "products");
            const productById = query(
                productsRef,
                where("subCategoryId", "==", newSubCatID)
            );
            const docSnap = await getDocs(productById);
            if (docSnap.docs.length > 0) {
                this.originalProducts = docSnap.docs.map((e) => {
                    return e.data();
                });
                this.originalProducts.sort((b, a) =>
                    a.date > b.date ? 1 : b.date > a.date ? -1 : 0
                );
                this.search();
                this.calculateNumberOfPages(docSnap.docs.length);
            } else {
                this.originalProducts = [];
                this.search();
                this.calculateNumberOfPages(0);
            }
        },
        async getAllSubCategories() {
            const db = getFirestore(app);
            const docSnap = await getDocs(collection(db, "subCategories"));
            this.subCategories = docSnap.docs.map((e) => {
                return e.data();
            });
            this.subCategories.sort((b, a) =>
                a.date > b.date ? 1 : b.date > a.date ? -1 : 0
            );
        },
        async getAllSubCategoriesOnSelectCategory(catID) {
            console.log("getAllSubCategoriesOnSelectCategory worked");
            this.subCategories = [];
            const db = getFirestore(app);
            const subCatOnSelRef = collection(db, "subCategories");
            const subCatById = query(
                subCatOnSelRef,
                where("categoryId", "==", catID)
            );
            const docSnap = await getDocs(subCatById);
            if (docSnap.docs.length > 0) {
                this.subCategories = docSnap.docs.map((e) => {
                    return e.data();
                });
                this.subCategories.sort((b, a) =>
                    a.date > b.date ? 1 : b.date > a.date ? -1 : 0
                );
            } else {
                this.subCategories = [];
            }
        },
        search() {
            this.products = this.originalProducts.filter((element) => {
                if (
                    element.titleEn
                        ?.toUpperCase()
                        .includes(this.searchVal?.toUpperCase()) ||
                    element.titleKu
                        ?.toUpperCase()
                        .includes(this.searchVal?.toUpperCase()) ||
                    element.titleAr
                        ?.toUpperCase()
                        .includes(this.searchVal?.toUpperCase())
                ) {
                    return true;
                }
            });
            this.calculateNumberOfPages(this.products.length);
        },
        resetFilters() {
            // if you need to remove just one parameter from query use this below
            // let query = Object.assign({}, this.$route.query);
            // delete query.newSubCatID;
            // this.$router.replace({ query });
            //but if you need to remove all parameters from query use this below
            this.$router.replace({});
            this.getAll();
            this.getAllSubCategories();
            this._start = 0;
            this._end = 12;
            this.$emit("changeColor", {
                backColor: "",
                textColor: "#FFFFFF",
            });
            this.calculateNumberOfPages(0);
        },
        nextPagination() {
            if (
                this.pageNumber !== this.numberOfPages &&
                this.pageNumber < this.numberOfPages
            ) {
                this._start += this.perPage;
                this._end = this._start + this.perPage;
                this.pageNumber += 1;
            }
        },
        prevPagination() {
            if (this.pageNumber <= this.numberOfPages && this.pageNumber > 1) {
                this._start -= this.perPage;
                this._end -= this.perPage;
                this.pageNumber -= 1;
            }
        },
        calculateNumberOfPages(length) {
            this.numberOfPages = Math.ceil(length / this.perPage);
        },
    },
    async mounted() {
        await this.getAll();
        await this.getAllCategories();
        await this.getAllSubCategories();
        if (this.$route.query.id) {
            this.getAllProductsByCategoryId(this.$route.query.id);
            this.getAllSubCategoriesOnSelectCategory(this.$route.query.id);
            this.emitSelectedCategory(this.$route.query.id);
        }
        if (this.$route.query.subCatID) {
            this.getAllProductsBySubCategoryId(this.$route.query.subCatID);
        }
    },
};
</script>

<style scoped>
.allProducts {
    position: relative;
}

.title {
    color: #e30613;
    font-weight: 900;
    font-size: 65px;
}
.left {
    background-color: aliceblue !important;
    color: black;
    border: 1px solid rgb(209, 204, 204);
    border-radius: 17px;
    font-weight: 900;
    /* text-align: left; */
}
.left:hover {
    background-color: #e30613 !important;
    color: white;
    border: 1px solid #e30613;
}
.left:hover i {
    transform: scale(1.3);
}
.btn-light {
    background-color: #ffffff75 !important;
}
.subCat a {
    min-width: max-content;
    margin-top: 5px;
    margin-bottom: 5px;
}
.searchInputSpan {
    position: relative;
}
.searchInput {
    padding: 20px;
    border-radius: 17px;
    border: 1px solid rgb(209, 204, 204);
}
.searchInputSpan i {
    position: absolute;
    top: 50%;
    right: 3%;
    transform: translate(-3%, -50%);
}
.searchInputSpanRTL {
    position: relative;
}
.searchInput {
    padding: 20px;
    border-radius: 17px;
    border: 1px solid rgb(209, 204, 204);
}
.searchInputSpanRTL i {
    position: absolute;
    top: 50%;
    left: 3%;
    transform: translate(-3%, -50%);
}

@media only screen and (max-width: 600px) {
    .allProducts {
        overflow-x: hidden;
    }
    .title {
        font-size: 35px;
    }
    .productFilter {
        display: inline-flex;
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 0 !important;
    }
    /* .productFilter a {
    margin-right: 10px !important;
    min-width: 230px;
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    border-radius: 25px;
  } */
    .subCat {
        display: inline-flex;
        width: 100% !important;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 0 !important;
        padding-bottom: 17px !important;
        height: 60px;
    }
    .subCat a {
        min-width: max-content;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}
</style>
