<template>
    <div class="MobileJumbotron">
        <swiper
            :spaceBetween="30"
            :effect="'fade'"
            :loop="false"
            :pagination="{
                clickable: true,
            }"
            :autoplay="{
                delay: 6500,
                disableOnInteraction: false,
            }"
            :modules="modules"
            @slideChange="onSlideChange"
            class="mySwiper"
        >
            <swiper-slide v-for="(item, index) in sliders" :key="index">
                <div class="layerGradient"></div>

                <div
                    class="jumbotronContent"
                    :style="[
                        $store.getters['language/language'] !== 'en'
                            ? 'direction: rtl !important;'
                            : '',
                    ]"
                >
                    <h1
                        class="my-1 mobJumbotronHead animate__animated animate__fadeInLeft animate__slow"
                    >
                        {{
                            item[
                                "title" +
                                    $filters.ucFirst(
                                        $store.getters["language/language"]
                                    )
                            ]
                        }}
                    </h1>
                    <h6
                        class="my-4 w-100 mobJumbotronDesc animate__animated animate__fadeInLeft animate__slower"
                        v-html="
                            $filters.postDesLenght(
                                item[
                                    'desc' +
                                        $filters.ucFirst(
                                            $store.getters['language/language']
                                        )
                                ],
                                90
                            )
                        "
                    ></h6>
                </div>
                <img
                    :src="$filters.getImageUrl(item.image)"
                    alt="alen company product"
                />
            </swiper-slide>
        </swiper>
        <br /><br />
    </div>
</template>

<script>
import AOS from "aos";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/effect-fade";
import "swiper/css/pagination";

// import required modules
import { EffectFade, Pagination, Autoplay } from "swiper";
import {
    doc,
    getDocs,
    getFirestore,
    collection,
    deleteDoc,
    orderBy,
} from "firebase/firestore";
import { app } from "@/firebase";

export default {
    name: "MobileJumbotron",
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [EffectFade, Pagination, Autoplay],
        };
    },
    data: () => {
        return {
            sliders: [],
        };
    },
    methods: {
        onSlideChange() {
            let head = document.querySelectorAll(".mobJumbotronHead");
            let desc = document.querySelectorAll(".mobJumbotronDesc");

            if (this.$store.getters["language/language"] === "en") {
                for (let i = 0; i < head.length; i++) {
                    head[i].classList.remove("animate__fadeInLeft");
                    head[i].classList.add("animate__fadeOutLeft");
                    setTimeout(() => {
                        head[i].classList.remove("animate__fadeOutLeft");
                        head[i].classList.add("animate__fadeInLeft");
                    }, 1500);
                }
                for (let j = 0; j < desc.length; j++) {
                    desc[j].classList.remove("animate__fadeInLeft");
                    desc[j].classList.add("animate__fadeOutLeft");
                    setTimeout(() => {
                        desc[j].classList.remove("animate__fadeOutLeft");
                        desc[j].classList.add("animate__fadeInLeft");
                    }, 1500);
                }
            } else {
                for (let i = 0; i < head.length; i++) {
                    head[i].classList.remove("animate__fadeInRight");
                    head[i].classList.add("animate__fadeOutRight");
                    setTimeout(() => {
                        head[i].classList.remove("animate__fadeOutRight");
                        head[i].classList.add("animate__fadeInRight");
                    }, 1500);
                }
                for (let j = 0; j < desc.length; j++) {
                    desc[j].classList.remove("animate__fadeInRight");
                    desc[j].classList.add("animate__fadeOutRight");
                    setTimeout(() => {
                        desc[j].classList.remove("animate__fadeOutRight");
                        desc[j].classList.add("animate__fadeInRight");
                    }, 1500);
                }
            }
        },
    },
    async mounted() {
        AOS.init();
        const db = getFirestore(app);
        const docSnap = await getDocs(collection(db, "slider"));
        this.sliders = docSnap.docs.map((e) => {
            return e.data();
        });
        this.sliders.sort((a, b) => {
            if (a.slidePositionNum > b.slidePositionNum) {
                return 1;
            } else if (b.slidePositionNum > a.slidePositionNum) {
                return -1;
            } else if (a.slidePositionNum == b.slidePositionNum) {
                if (a.date > b.date) {
                    return -1;
                } else if (b.date > a.date) {
                    return 1;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        });
    },
};
</script>

<style src="../assets/css/home/mobileJumbotron.css" scoped></style>
