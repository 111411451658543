<template>
    <router-link
        :to="{
            name: 'ProductsList',
            query: { id: category.id },
        }"
    >
        <div class="cover layout layout1 justify-content-center text-center">
            <div class="productContent" @mouseenter="changeCategoryImage"
                @mouseleave="changeCategoryImage">
                <svg viewBox="0 0 190 190" xmlns="http://www.w3.org/2000/svg">
                    <path
                        class="blob"
                        fill="#FFFFFF"
                        d="M40.6,-16.6C46,3.4,39.2,24,22.3,37.9C5.5,51.8,-21.4,59.2,-37.7,48.2C-54,37.2,-59.7,8,-51.7,-15.6C-43.6,-39.3,-21.8,-57.3,-2.1,-56.6C17.6,-55.9,35.2,-36.6,40.6,-16.6Z"
                        transform="translate(100 100)"
                    />
                </svg>
                <img
                    :src="$filters.getImageUrl(category.thumbImage)"
                    v-show="!categoryImageShow"
                    class="centerXY imgThumbnail animate__animated animate__fadeIn animated__fast"
                    :alt="categoryImageShow.titleEn"
                    width="110"
                />
                <img
                    :src="$filters.getImageUrl(category.image)"
                    v-show="categoryImageShow"
                    class="centerXY imgRecipes animate__animated animate__fadeIn animated__fast"
                    :alt="category.titleEn"
                    width="110"
                />

                <div class="productContentBody">
                    <p>
                        {{
                            $filters.titleLenght(
                                category[
                                    "title" +
                                        $filters.ucFirst(
                                            $store.getters["language/language"]
                                        )
                                ]
                            )
                        }}
                        &nbsp;<i
                            v-if="$store.getters['language/language'] === 'en'"
                            class="fas fa-arrow-right d-none"
                        ></i>
                        &nbsp;<i
                            v-if="$store.getters['language/language'] !== 'en'"
                            class="fas fa-arrow-left d-none"
                        ></i>
                    </p>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "OneHomeCardCategory",
    props: ["category"],
    data() {
        return {
            categoryImageShow: false,
        };
    },
    methods: {
        changeCategoryImage() {
            this.categoryImageShow = !this.categoryImageShow;
        },
    },
};
</script>

<style src="../assets/css/home/oneHomeCardProduct.css" scoped></style>
