import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyD8n8q3aHTceR-VhJ-MwZVbB1HMxldcg8M",
  authDomain: "alen-d6cdf.firebaseapp.com",
  projectId: "alen-d6cdf",
  storageBucket: "alen-d6cdf.appspot.com",
  
  messagingSenderId: "1005812254447",
  appId: "1:1005812254447:web:bfa8b5c0aaf44d5344fd1a",
  measurementId: "G-RRDLQ9HEKT"
};

export const app = initializeApp(firebaseConfig);
