<template>
    <div class="Edit">
        <div class="container">
            <div
                class="row d-flex justify-content-center my-5 text-center"
                v-if="noData"
            >
                <h1 style="height: 290px">{{ $t("noData") }}</h1>
                <router-link to="/" class="btn btn-danger w-100">{{
                    $t("backToHome")
                }}</router-link>
            </div>
            <div v-else class="row d-flex justify-content-center">
                <div class="col-lg-8">
                    <br /><br /><br /><br /><br />
                    <h1>{{ $t("editProduct") }}</h1>
                    <div>
                        <div class="mb-3">
                            <label for="exapleInputText" class="form-label">{{
                                $t("productBarcode")
                            }}</label>
                            <input
                                type="text"
                                required
                                v-model="barcode"
                                class="form-control"
                                id="exapleInputText"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <div class="row">
                                <div class="col-6">
                                    <label for="category" class="form-label">{{
                                        $t("category")
                                    }}</label>
                                    <select
                                        class="form-select"
                                        v-model="categoryId"
                                        aria-label="category"
                                        @change="myCategorySelectEvent"
                                    >
                                        <option
                                            v-for="(
                                                item, index
                                            ) in myCategoryOptions"
                                            :value="item.id"
                                            :selected="item.id === categoryId"
                                        >
                                            {{ item.text }}
                                        </option>
                                    </select>
                                    <!-- <Select2
                                        v-model="myCategoryValue"
                                        style="width: 100% !important"
                                        :options="myCategoryOptions"
                                        @change="myCategoryChangeEvent($event)"
                                        @select="myCategorySelectEvent($event)"
                                        id="category"
                                    /> -->
                                </div>
                                <div class="col-6">
                                    <label
                                        for="subCategory"
                                        class="form-label"
                                        >{{ $t("subCategory") }}</label
                                    >
                                    <select
                                        class="form-select"
                                        v-model="subCategoryId"
                                        aria-label="subCategory"
                                    >
                                        <option
                                            v-for="(item, index) in myOptions"
                                            :value="item.id"
                                            :selected="
                                                item.id === subCategoryId
                                            "
                                        >
                                            {{ item.text }}
                                        </option>
                                    </select>
                                    <!-- <Select2
                                        v-model="myValue"
                                        style="width: 100% !important"
                                        :options="myOptions"
                                        @change="myChangeEvent($event)"
                                        @select="mySelectEvent($event)"
                                        id="subCategory"
                                    /> -->
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("productTitleKu") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleKu"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("productTitleAr") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleAr"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("productTitleEn") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleEn"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>

                        <div class="mb-3">
                            <label for="editKu">{{
                                $t("productDescrKu")
                            }}</label>
                            <div id="editKu">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descKu"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="editAr">{{
                                $t("productDescrAr")
                            }}</label>
                            <div id="editAr">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descAr"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="editEn">{{
                                $t("productDescrEn")
                            }}</label>
                            <div id="editEn">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descEn"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="row">
                                <div class="col-4">
                                    <label for="images" class="form-label">{{
                                        $t("productImgHD")
                                    }}</label>
                                    <input
                                        type="file"
                                        @change="onFileChange"
                                        class="form-control"
                                        multiple="multiple"
                                        id="images"
                                    />
                                </div>
                                <div class="col-4">
                                    <label
                                        for="thumbImages"
                                        class="form-label"
                                        >{{ $t("thumbnail") }}</label
                                    >
                                    <input
                                        type="file"
                                        @change="onThumbFileChange"
                                        class="form-control"
                                        id="thumbImages"
                                    />
                                </div>
                                <div class="col-4">
                                    <label
                                        for="spiceImage"
                                        class="form-label"
                                        >{{ $t("spiceImage") }}</label
                                    >
                                    <input
                                        type="file"
                                        @change="onSpiceImageChange"
                                        class="form-control"
                                        id="spiceImage"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div
                                class="col-lg-3 justify-content-center col-6"
                                v-for="(item, index) in images"
                                :key="index"
                            >
                                <div class="allImages shadow-lg">
                                    <img
                                        :src="$filters.getImageUrl(item.url)"
                                        :alt="titleEn"
                                        width="180"
                                    />
                                    <input
                                        type="text"
                                        required
                                        v-model="item.order"
                                        class="form-control"
                                        id="imagePosition"
                                    />
                                    <button
                                        class="btn btn-danger my-2"
                                        @click="deleteOneImage(item)"
                                    >
                                        Remove
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button
                            type="button"
                            @click="edit"
                            class="btn btn-primary w-100"
                            :disabled="isLoading"
                        >
                            {{ $t("editProduct") }}
                        </button>
                        <button
                            @click="deleteProduct"
                            class="btn btn-danger w-100 my-3"
                            style="display: block"
                            :disabled="isLoading"
                            v-if="this.$store.getters['auth/isLoggedIn']"
                        >
                            {{ $t("deleteProduct") }}
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                        <br /><br /><br />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { app } from "@/firebase";
import { uid } from "uid";
//login
import {
    getFirestore,
    collection,
    getDoc,
    doc,
    Timestamp,
    deleteDoc,
    setDoc,
    getDocs,
} from "firebase/firestore/lite";
import {
    getStorage,
    uploadBytes,
    getDownloadURL,
    ref,
    deleteObject,
} from "firebase/storage";
import { query, where } from "@firebase/firestore";
import Select2 from "vue3-select2-component";

export default {
    name: "ProductEdit",
    components: {
        Select2,
    },
    data() {
        return {
            barcode: null,
            titleKu: null,
            titleAr: null,
            titleEn: null,
            descKu: "",
            thumbImage: "",
            descAr: "",
            descEn: "",
            fileImages: [],
            images: [],
            thumbFileImage: null,
            spiceImage: null,
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            isLoading: false,
            error: false,
            noData: false,
            duplicate: false,
            myValue: "myValue",
            myOptions: [],
            myCategoryValue: "",
            myCategoryOptions: [],
            subCategoryId: null,
            categoryId: null,
        };
    },
    methods: {
        async edit() {
            await this.checkIfBarcodeExist();
            if (this.duplicate) {
                console.log("This item is already exist");
            } else {
                this.isLoading = true;
                // console.log("bsjbsudgbughdushgdusdhsudhsui", this.fileImages);

                const storage = getStorage(app);
                const id = this.$route.params.id;

                this.isLoading = true;
                let imagesTemp = this.images ?? [];

                let isAllUploaded = true;
                for (let i = 0; i < this.images.length; i++) {
                    if (this.images[i].file && this.images[i].isNew === true) {
                        try {
                            const id = uid(16);

                            let storageRef = ref(
                                storage,
                                "/Products Images/" + id + ".png"
                            );
                            let snapshot = await uploadBytes(
                                storageRef,
                                this.images[i].file
                            );
                            let url = await getDownloadURL(snapshot.ref);
                            imagesTemp[i] = { order: i + 1, url: url };
                            this.images[i] = { order: i + 1, url: url };
                        } catch (error) {
                            isAllUploaded = false;
                        }
                    }
                }
                if (isAllUploaded == false) {
                    return;
                }

                let thumbImage = this.thumbImage;
                if (this.thumbFileImage != null && this.thumbFileImage) {
                    const id = this.$route.params.id + "thumbnail";

                    let storageRef = ref(
                        storage,
                        "/Products Images/" + id + ".png"
                    );
                    let snapshot = await uploadBytes(
                        storageRef,
                        this.thumbFileImage
                    );
                    let url = await getDownloadURL(snapshot.ref);
                    thumbImage = url;
                }

                let spiceImage = this.spiceImage;
                if (this.spiceImage != null && this.spiceImage) {
                    const id = this.$route.params.id + "spiceImage";

                    let storageRef = ref(
                        storage,
                        "/Products Images/" + id + ".png"
                    );
                    let snapshot = await uploadBytes(
                        storageRef,
                        this.spiceImage
                    );
                    let url = await getDownloadURL(snapshot.ref);
                    spiceImage = url;
                }
                const db = getFirestore(app);
                const groceriesColRef = doc(collection(db, "products"), id);
                return setDoc(groceriesColRef, {
                    id: id,
                    barcode: this.barcode,
                    categoryId: this.categoryId,
                    subCategoryId: this.subCategoryId,
                    titleKu: this.titleKu,
                    titleAr: this.titleAr,
                    titleEn: this.titleEn,
                    descKu: this.descKu,
                    descAr: this.descAr,
                    descEn: this.descEn,
                    images: imagesTemp ?? null,
                    thumbImage: thumbImage ?? null,
                    spiceImage: spiceImage ?? null,
                    date: Timestamp.now(),
                })
                    .then(() => {
                        this.isLoading = false;
                        this.$router.push({
                            name: "Dashboard",
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                        this.isLoading = false;
                        this.error = true;
                    });
            }
        },
        async checkIfBarcodeExist() {
            const db = getFirestore(app);
            const barcodeRef = collection(db, "products");
            const q = query(
                barcodeRef,
                where("barcode", "==", this.barcode),
                where("id", "!=", this.$route.params.id)
            );
            const docSnap = await getDocs(q);
            if (docSnap.docs.length > 0) {
                this.duplicate = true;
            } else {
                this.duplicate = false;
            }
            return docSnap.docs.length;
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.fileImages = files;
            for (let a = 0; a < files.length; a++) {
                this.images.push({
                    order: a + 1,
                    isNew: true,
                    url: URL.createObjectURL(files[a]),
                    file: files[a],
                });
            }
        },
        onThumbFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.thumbFileImage = files[0];
        },
        onSpiceImageChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.spiceImage = files[0];
        },
        async myCategorySelectEvent() {
            this.myOptions = null;
            // console.log("text = ", text);
            // this.categoryId = categoryID;

            const db = getFirestore(app);
            const subCategoriesRef = collection(db, "subCategories");

            const subCats = query(
                subCategoriesRef,
                where("categoryId", "==", this.categoryId)
            );

            const docSnap = await getDocs(subCats);

            if (docSnap.docs.length > 0) {
                this.myOptions = docSnap.docs.map((e) => {
                    return {
                        text:
                            e.data().titleEn +
                            ` / ` +
                            e.data().titleKu +
                            ` / ` +
                            e.data().titleAr,
                        id: e.data().id,
                    };
                });
            }
        },
        async getAllCategories() {
            const db = getFirestore(app);
            const docSnap = await getDocs(collection(db, "category"));
            this.myCategoryOptions = docSnap.docs.map((e) => {
                return {
                    text:
                        e.data().titleEn +
                        ` / ` +
                        e.data().titleKu +
                        ` / ` +
                        e.data().titleAr,
                    id: e.data().id,
                    // selected: e.data().id == this.categoryId ? true : false,
                };
            });
        },
        async getAllSubCategories() {
            const db = getFirestore(app);
            const docSnap = await getDocs(collection(db, "subCategories"));
            this.myOptions = docSnap.docs.map((e) => {
                return {
                    text:
                        e.data().titleEn +
                        ` / ` +
                        e.data().titleKu +
                        ` / ` +
                        e.data().titleAr,
                    id: e.data().id,
                    selected: e.data().id == this.subCategoryId ? true : false,
                };
            });
        },
        mySelectEvent() {
            // console.log("id = ", id);
            // console.log("text = ", text);
            // this.subCategoryId = id;
        },
        async deleteOneImage(e) {
            if (e.isNew == true) {
                this.images = this.images.filter((x) => x.url != e.url);
                return;
            }
            try {
                const storage = getStorage(app);
                await deleteObject(ref(storage, e.url));
            } catch (error) {
                this.images = this.images.filter((i) => i.url != e.url);
                return;
            }
            this.images = this.images.filter((i) => {
                return i.url !== e.url;
            });
            const id = this.$route.params.id;
            const db = getFirestore(app);
            const groceriesColRef = doc(collection(db, "products"), id);
            setDoc(groceriesColRef, {
                id: id,
                barcode: this.barcode,
                titleKu: this.titleKu,
                titleAr: this.titleAr,
                titleEn: this.titleEn,
                descKu: this.descKu,
                descAr: this.descAr,
                descEn: this.descEn,
                images: this.images,
                thumbImage: this.thumbImage,
                spiceImage: this.spiceImage,
                date: Timestamp.now(),
            });
            await this.getOneProduct();
        },
        async deleteProduct() {
            const db = getFirestore(app);
            const docRef = doc(db, "products", this.$route.params.id);
            this.isLoading = true;
            for (let i = 0; i < this.images.length; i++) {
                try {
                    const storage = getStorage(app);
                    await deleteObject(ref(storage, this.images[i].url));
                } catch (error) {}
            }
            try {
                const storage = getStorage(app);
                await deleteObject(ref(storage, this.thumbImage));
            } catch (error) {}
            const docSnap = await deleteDoc(docRef)
                .then(() => {
                    this.isLoading = false;
                    this.$router.push({
                        name: "ProductsList",
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.isLoading = false;
                    this.error = true;
                });
        },
        async getOneProduct() {
            const db = getFirestore(app);
            const docRef = doc(db, "products", this.$route.params.id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const data = docSnap.data();
                this.barcode = data.barcode;
                this.categoryId = data.categoryId;
                this.subCategoryId = data.subCategoryId;
                this.titleKu = data.titleKu;
                this.titleEn = data.titleEn;
                this.titleAr = data.titleAr;
                this.descKu = data.descKu;
                this.descEn = data.descEn;
                this.descAr = data.descAr;
                this.thumbImage = data.thumbImage;
                (this.images = data.images.sort((b, a) => b.order - a.order)),
                    (this.thumbFileImage = data.thumbFileImage);
            } else {
                this.noData = true;
            }
        },
    },
    async mounted() {
        await this.getOneProduct();
        await this.getAllCategories();
        await this.getAllSubCategories();
        await this.myCategorySelectEvent();
    },
};
</script>

<style scoped>
.allImages {
    margin: 5px 0px;
    background-color: white;
    height: 235px;
    border-radius: 18px;
    padding: 10px;
    text-align: center;
}
.allImages img {
    width: 144px;
    height: 130px;
    object-fit: contain;
}
.allImages input {
    border-radius: 15px;
    text-align: center;
}
</style>
