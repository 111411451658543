<template>
    <div class="ResetPassword">
        <div class="container">
            <div class="row">
                <div class="col-12 p-5">
                    <form @submit.prevent="sendEmail">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label"
                                >Email address</label
                            >
                            <input
                                type="email"
                                required
                                v-model="email"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <button type="submit" class="btn btn-primary">
                            {{ $t("resetPasswordSend") }}
                        </button>
                        <div
                            v-if="error || success"
                            class="alert my-4"
                            :class="success ? 'alert-success' : 'alert-danger'"
                            role="alert"
                        >
                            {{
                                success
                                    ? $t("resetPasswordSuccess")
                                    : $t("resetPasswordFail")
                            }}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { app } from "@/firebase";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default {
    name: "ResetPassword",
    data() {
        return {
            email: "",
            success: false,
            error: false,
            errorString: "",
        };
    },
    methods: {
        async sendEmail() {
            try {
                await sendPasswordResetEmail(getAuth(app), this.email);
                this.success = true;
                this.error = false;
            } catch (error) {
                this.success = false;
                this.error = true;
                this.errorString = error.message;
            }
        },
    },
};
</script>

<style scoped></style>
