<template>
    <section class="underProductSection pt-0">
        <div class="height"></div>
        <div class="container pb-5">
            <span class="spanTitle" data-aos="fade-left">
                <router-link
                    :to="{
                        name: 'RecipesList',
                    }"
                    class="my-4 title"
                >
                    {{ $t("recipesU") }}
                    <!-- {{
                        recipesSettings[0] &&
                        recipesSettings[0][
                            "titleDesign" +
                                $filters.ucFirst(
                                    $store.getters["language/language"]
                                )
                        ]
                    }} -->
                </router-link>
            </span>
            <div class="row my-5">
                <br /><br />
                <!-- static -->
                <div class="col-lg-12 justify-content-start pe-5">
                    <h4
                        class="py-5"
                        style="font-weight: 900"
                        data-aos="fade-up"
                    >
                        <!-- {{ $t("recipesText1") }} -->
                        {{
                            recipesSettings[0] &&
                            recipesSettings[0][
                                "subTitleDesign" +
                                    $filters.ucFirst(
                                        $store.getters["language/language"]
                                    )
                            ]
                        }}
                    </h4>
                    <p data-aos="fade-left">
                        <!-- {{ $t("recipesText2") }} -->
                        {{
                            recipesSettings[0] &&
                            recipesSettings[0][
                                "descDesign" +
                                    $filters.ucFirst(
                                        $store.getters["language/language"]
                                    )
                            ]
                        }}
                    </p>
                </div>
                <!-- dynamic -->
                <div class="col-lg-12">
                    <div class="row">
                        <div
                            class="col-lg-4 my-2 col-6"
                            data-aos="fade-up"
                            v-for="(item, index) in recipes.slice(0, 6)"
                            :key="index"
                        >
                            <router-link
                                :to="{
                                    name: 'RecipesShow',
                                    params: { id: item.id },
                                }"
                            >
                                <div class="window">
                                    <img
                                        :src="$filters.getImageUrl(item.image)"
                                        class="imgCard"
                                        width="400"
                                        :alt="item.titleEn"
                                    />
                                    <div class="overlay">
                                        <div class="text">
                                            {{
                                                item[
                                                    "title" +
                                                        $filters.ucFirst(
                                                            $store.getters[
                                                                "language/language"
                                                            ]
                                                        )
                                                ]
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <br /><br />
                </div>
            </div>
        </div>
        <div class="height"></div>
        <div class="shape1">
            <img src="../assets/images/shape1.png" alt="shape1" />
            <div class="inShape1Img" data-aos="fade-left">
                <img :src="$filters.getImageUrl(recipesSettings[0] && recipesSettings[0].topImage)" alt="inShape1Img" />
            </div>
        </div>

        <div
            v-if="$store.getters['language/language'] !== 'en'"
            class="shape2"
            style="direction: ltr"
        >
            <img src="../assets/images/shape2.png" alt="shape2" />
            <div class="inShape2Img" data-aos="fade-right">
                <img :src="$filters.getImageUrl(recipesSettings[0] && recipesSettings[0].bottomImage)" alt="inShape2Img" />
            </div>
        </div>
        <div
            v-if="$store.getters['language/language'] === 'en'"
            class="shape2"
            style="direction: ltr"
        >
            <img src="../assets/images/shape2.png" alt="shape2" />
            <div class="inShape2Img" data-aos="fade-down">
                <img :src="$filters.getImageUrl(recipesSettings[0] && recipesSettings[0].bottomImage)" alt="inShape2Img" />
            </div>
        </div>
    </section>
</template>

<script>
import {
    getDocs,
    getFirestore,
    collection,
    query,
    where,
} from "firebase/firestore";
import { app } from "@/firebase";

export default {
    name: "UnderProductSection",
    data() {
        return {
            recipes: [],
            productsUsed: [],
            recipesSettings: [{
            descDesignEn:"Alen captures different kinds of tastes with hundreds of brands.Add sweetness to your life for every moment.Meet our large family.",
            subTitleDesignEn: "Alen has something for everyone!"
        }],
            noData: false,
        };
    },
    methods: {
        async getOne(id) {
            const db = getFirestore(app);
            const productsRef = collection(db, "products");

            const productsList = query(productsRef, where("id", "==", id));

            const docSnap = await getDocs(productsList);

            this.productsUsed = docSnap.docs.map((e) => {
                return e.data();
            });
            this.productsUsed.sort((b, a) =>
                a.date > b.date ? 1 : b.date > a.date ? -1 : 0
            );
        },
        async getAll() {
            const db = getFirestore(app);
            const docSnap = await getDocs(collection(db, "recipes"));
            this.recipes = docSnap.docs.map((e) => {
                this.getOne(e.data().productsUsed);
                return e.data();
            });
            this.recipes.sort((b, a) =>
                a.date > b.date ? 1 : b.date > a.date ? -1 : 0
            );
        },
        async getAllRecipesSettings() {
            const db = getFirestore(app);
            const docSnap = await getDocs(collection(db, "recipesSettings"));
            this.recipesSettings = docSnap.docs.map((e) => {
                return e.data();
            });
        },
    },
    async mounted() {
        await this.getAllRecipesSettings();
        await this.getAll();
    },
};
</script>

<style src="../assets/css/home/underProductSection.css" scoped></style>
