<template>
    <div class="Edit">
        <div class="container">
            <div
                class="row d-flex justify-content-center my-5 text-center"
                v-if="noData"
            >
                <h1 style="height: 290px">{{ $t("noData") }}</h1>
                <router-link to="/" class="btn btn-danger w-100">{{
                    $t("backToHome")
                }}</router-link>
            </div>
            <div v-else class="row d-flex justify-content-center">
                <div class="col-lg-8">
                    <br /><br /><br /><br /><br />
                    <h1>{{ $t("editCategory") }}</h1>
                    <form @submit.prevent="edit">
                        <div class="row">
                            <div class="col-6">
                                <div class="mb-3">
                                    <label
                                        for="categoryColor"
                                        class="form-label"
                                        >{{
                                            $t("categoryBackgroundColor")
                                        }}</label
                                    >
                                    <input
                                        type="color"
                                        v-model="categoryColor"
                                        class="form-control"
                                        id="categoryColor"
                                        aria-describedby="emailHelp"
                                    />
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="mb-3">
                                    <label
                                        for="categoryTextColor"
                                        class="form-label"
                                        >{{ $t("categoryTextColor") }}</label
                                    >
                                    <input
                                        type="color"
                                        v-model="categoryTextColor"
                                        class="form-control"
                                        id="categoryTextColor"
                                        aria-describedby="emailHelp"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="categoryColor" class="form-label">{{
                                $t("demoOfYourColors")
                            }}</label>
                            <div
                                class="py-3"
                                :style="'background-color:' + categoryColor"
                            >
                                <p
                                    class="h3 text-center"
                                    :style="'color:' + categoryTextColor"
                                >
                                    Alen Spices
                                </p>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("categoryTitleKu") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleKu"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("categoryTitleAr") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleAr"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("categoryTitleEn") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleEn"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>

                        <div class="mb-3">
                            <div class="row">
                                <div class="col-6">
                                    <label for="image" class="form-label"
                                        >{{ $t('attachment') }}</label
                                    >
                                    <input
                                        type="file"
                                        @change="onFileChange"
                                        class="form-control"
                                        id="image"
                                    />
                                </div>
                                <div class="col-6">
                                    <label
                                        for="thumbImages"
                                        class="form-label"
                                        >{{ $t("thumbnail") }}</label
                                    >
                                    <input
                                        type="file"
                                        @change="onThumbFileChange"
                                        class="form-control"
                                        id="thumbImages"
                                    />
                                </div>
                            </div>
                        </div>
                        <button
                            type="submit"
                            class="btn btn-danger w-100"
                            :disabled="isLoading"
                        >
                            {{ $t("editCategory") }}
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                        <br /><br /><br />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { app } from "@/firebase";
import { uid } from "uid";
//login
import {
    getFirestore,
    collection,
    getDoc,
    doc,
    Timestamp,
    setDoc,
} from "firebase/firestore/lite";
import { getStorage, uploadBytes, getDownloadURL, ref } from "firebase/storage";

export default {
    name: "CategoryEdit",
    data() {
        return {
            categoryColor: null,
            categoryTextColor: null,
            titleKu: null,
            titleAr: null,
            titleEn: null,
            img: null,
            image: null,
            thumbFileImage: null,
            thumbImage: "",
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            isLoading: false,
            error: false,
            noData: false,
        };
    },
    methods: {
        async edit() {
            this.isLoading = true;
            const id = this.$route.params.id;
            const storage = getStorage(app);
            if (this.img != null && this.img) {
                const storageRef = ref(
                    storage,
                    "/Category Images/" + id + ".png"
                );
                let snapshot = await uploadBytes(storageRef, this.img);
                this.image = await getDownloadURL(snapshot.ref);
            }
            let thumbImage = this.thumbImage;
                if (this.thumbFileImage != null && this.thumbFileImage) {
                    const id = this.$route.params.id + "thumbnail";

                    let storageRef = ref(
                        storage,
                        "/Category Images/" + id + ".png"
                    );
                    let snapshot = await uploadBytes(
                        storageRef,
                        this.thumbFileImage
                    );
                    let url = await getDownloadURL(snapshot.ref);
                    thumbImage = url;
                }
            const db = getFirestore(app);
            const groceriesColRef = doc(collection(db, "category"), id);
            return setDoc(groceriesColRef, {
                id: id,
                categoryColor: this.categoryColor,
                categoryTextColor: this.categoryTextColor,
                titleKu: this.titleKu,
                titleAr: this.titleAr,
                titleEn: this.titleEn,
                image: this.image,
                thumbImage: thumbImage ?? null,
                date: Timestamp.now(),
            })
                .then(() => {
                    this.isLoading = false;
                    this.$router.push({
                        name: "CategoryList",
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.isLoading = false;
                    this.error = false;
                });
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.img = files[0];
        },
        onThumbFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.thumbFileImage = files[0];
        },
    },
    async mounted() {
        const db = getFirestore(app);

        const docRef = doc(db, "category", this.$route.params.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            this.titleKu = data.titleKu;
            this.titleEn = data.titleEn;
            this.titleAr = data.titleAr;
            this.categoryColor = data.categoryColor;
            this.categoryTextColor = data.categoryTextColor;
            this.image = data.image;
            this.thumbImage = data.thumbImage;
        } else {
            this.noData = true;
        }
    },
};
</script>

<style></style>
