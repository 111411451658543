<template>
    <router-link
        :to="{
            name: 'ProductShow',
            params: { id: product.id },
        }"
    >
        <div
            class="cover layout layout1 justify-content-center text-center"
            @mouseover="changeFill"
            @mouseleave="removeFill"
        >
            <div
                class="productContent"
                @mouseenter="changeProductImage"
                @mouseleave="changeProductImage"
            >
                <svg viewBox="0 0 190 190" xmlns="http://www.w3.org/2000/svg">
                    <path
                        class="blob"
                        :fill="fillBlob"
                        d="M40.6,-16.6C46,3.4,39.2,24,22.3,37.9C5.5,51.8,-21.4,59.2,-37.7,48.2C-54,37.2,-59.7,8,-51.7,-15.6C-43.6,-39.3,-21.8,-57.3,-2.1,-56.6C17.6,-55.9,35.2,-36.6,40.6,-16.6Z"
                        transform="translate(100 100)"
                    />
                </svg>

                <img
                    :src="$filters.getImageUrl(product.thumbImage)"
                    v-show="!spiceImageShow"
                    class="centerXY imgThumbnail animate__animated animate__fadeIn animated__fast"
                    :alt="product.titleEn"
                    width="110"
                />
                <img
                    :src="$filters.getImageUrl(product.spiceImage)"
                    v-show="spiceImageShow"
                    class="centerXY imgRecipes animate__animated animate__fadeIn animated__fast"
                    :alt="product.titleEn"
                    width="110"
                />
                <!-- <svg
          class="product-box-dotted"
          width="100%"
          height="64%"
          viewBox="0 0 231.337000 241.099000"
        >
          <g
            transform="matrix(1 0 0 1 -380.63200000000001 -454.02999999999997)"
          >
            <path
              mask="url(#product-box-mask)"
              d="M515.078000,458.862000C572.561000,477.539000,625.306000,549.315000,606.611000,606.852000C587.916000,664.389000,525.327000,707.900000,467.844000,689.227000C410.361000,670.554000,367.917000,619.013000,386.611000,561.478000C405.305000,503.943000,457.600000,440.184000,515.078000,458.862000Z"
              fill="none"
              stroke="#e3051b"
              stroke-width="3"
              stroke-miterlimit="10"
              stroke-dasharray="10"
            ></path>
          </g>
        </svg> -->
                <div class="productContentBody">
                    <p>
                        {{
                            $filters.titleLenght(
                                product[
                                    "title" +
                                        $filters.ucFirst(
                                            $store.getters["language/language"]
                                        )
                                ]
                            )
                        }}
                    </p>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "OneCardProduct",
    props: ["product", "navbarFillHexColor"],
    data() {
        return {
            fillBlob: "#FFFFFF",
            spiceImageShow: false,
        };
    },
    methods: {
        changeFill() {
            if (!this.navbarFillHexColor) {
                this.fillBlob = "#e30613";
            } else {
                this.fillBlob = this.navbarFillHexColor;
            }
        },
        removeFill() {
            this.fillBlob = "#FFFFFF";
        },
        changeProductImage() {
            this.spiceImageShow = !this.spiceImageShow;
        },
    },
};
</script>

<style src="../assets/css/home/oneCardProduct.css" scoped></style>
