<template>
    <div class="Edit">
        <div class="container">
            <div
                class="row d-flex justify-content-center my-5 text-center"
                v-if="noData"
            >
                <h1 style="height: 290px">{{ $t("noData") }}</h1>
                <router-link to="/" class="btn btn-danger w-100">{{
                    $t("backToHome")
                }}</router-link>
            </div>
            <div v-else class="row d-flex justify-content-center">
                <div class="col-lg-8">
                    <br /><br /><br /><br /><br />
                    <h1>{{ $t("editSlider") }}</h1>
                    <form @submit.prevent="edit">
                        <div class="mb-3">
                            <label for="forLabel" class="form-label">{{
                                $t("sliderPosition")
                            }}</label>
                            <input
                                type="text"
                                required
                                v-model="slidePositionNum"
                                class="form-control"
                                id="forLabel"
                                aria-describedby="emailHelp"
                            />
                        </div>

                        <div class="mb-3">
                            <label for="image" class="form-label">{{
                                $t("sliderImg")
                            }}</label>
                            <input
                                type="file"
                                @change="onFileChange"
                                class="form-control"
                                id="imageInpu"
                            />
                            <br />
                            <img
                                :src="$filters.getImageUrl(image)"
                                width="150"
                                alt="image"
                                id="prevImg"
                            />
                        </div>

                        <div class="mb-3">
                            <label for="forLabel" class="form-label">{{
                                $t("sliderTitleKu")
                            }}</label>
                            <input
                                type="text"
                                required
                                v-model="titleKu"
                                class="form-control"
                                id="forLabel"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="forLabel" class="form-label">{{
                                $t("sliderTitleAr")
                            }}</label>
                            <input
                                type="text"
                                required
                                v-model="titleAr"
                                class="form-control"
                                id="forLabel"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="forLabel" class="form-label">{{
                                $t("sliderTitleEn")
                            }}</label>
                            <input
                                type="text"
                                required
                                v-model="titleEn"
                                class="form-control"
                                id="forLabel"
                                aria-describedby="emailHelp"
                            />
                        </div>

                        <div class="mb-3">
                            <label for="editKu">{{
                                $t("sliderDescrKu")
                            }}</label>
                            <div id="editKu">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descKu"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="editAr"
                                >{{ $t("sliderDescrAr") }}r</label
                            >
                            <div id="editAr">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descAr"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="editEn">{{
                                $t("sliderDescrEn")
                            }}</label>
                            <div id="editEn">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descEn"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>
                        <button
                            type="submit"
                            class="btn btn-danger w-100"
                            :disabled="isLoading"
                        >
                            {{ $t("editSlider") }}
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                        <br /><br /><br />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { app } from "@/firebase";
import { uid } from "uid";
//login
import {
    getFirestore,
    collection,
    getDoc,
    doc,
    Timestamp,
    setDoc,
} from "firebase/firestore/lite";
import { getStorage, uploadBytes, getDownloadURL, ref } from "firebase/storage";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
    name: "SliderEdit",
    data() {
        return {
            slidePositionNum: null,
            img: null,
            image: null,
            isLoading: false,
            error: false,
            noData: false,
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            titleKu: null,
            titleAr: null,
            titleEn: null,
            descKu: "",
            descAr: "",
            descEn: "",
        };
    },
    methods: {
        async edit() {
            this.isLoading = true;
            const storage = getStorage(app);
            const id = this.$route.params.id;
            if (this.img != null && this.img) {
                const storageRef = ref(storage, id + ".png");
                let snapshot = await uploadBytes(storageRef, this.img);
                this.image = await getDownloadURL(snapshot.ref);
            }

            const db = getFirestore(app);
            const groceriesColRef = doc(collection(db, "slider"), id);
            return setDoc(groceriesColRef, {
                id: id,
                slidePositionNum: this.slidePositionNum,
                image: this.image,
                titleKu: this.titleKu,
                titleAr: this.titleAr,
                titleEn: this.titleEn,
                descKu: this.descKu,
                descAr: this.descAr,
                descEn: this.descEn,
                date: Timestamp.now(),
            })
                .then(() => {
                    this.isLoading = false;
                    this.$router.push({
                        name: "SliderList",
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.isLoading = false;
                    this.error = false;
                });
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.img = files[0];
            const [file] = document.getElementById("imageInpu").files;
            if (file) {
                this.image = URL.createObjectURL(file);
            }
            // console.log("image", this.img);
        },
    },
    async mounted() {
        const db = getFirestore(app);

        const docRef = doc(db, "slider", this.$route.params.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            this.slidePositionNum = data.slidePositionNum;
            this.image = data.image;
            this.titleKu = data.titleKu;
            this.titleEn = data.titleEn;
            this.titleAr = data.titleAr;
            this.descKu = data.descKu;
            this.descEn = data.descEn;
            this.descAr = data.descAr;
        } else {
            this.noData = true;
        }
    },
};
</script>

<style></style>
