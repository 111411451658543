<template>
    <div class="add">
        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-8">
                    <br /><br /><br /><br /><br />
                    <h1>{{ $t("addNews") }}</h1>
                    <form @submit.prevent="add">
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("newsTitleKu") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleKu"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("newsTitleAr") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleAr"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                for="exampleInputEmail1"
                                class="form-label"
                                >{{ $t("newsTitleEn") }}</label
                            >
                            <input
                                type="text"
                                required
                                v-model="titleEn"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                            />
                        </div>

                        <div class="mb-3">
                            <label for="addKu">{{ $t("newsDescrKu") }}</label>
                            <div id="addKu">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descKu"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="addAr">{{ $t("newsDescrAr") }}</label>
                            <div id="addAr">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descAr"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="addEn">{{ $t("newsDescrEn") }}</label>
                            <div id="addEn">
                                <ckeditor
                                    :editor="editor"
                                    v-model="descEn"
                                    :config="editorConfig"
                                ></ckeditor>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="image" class="form-label">{{
                                $t("newsImg")
                            }}</label>
                            <input
                                type="file"
                                @change="onFileChange"
                                class="form-control"
                                id="image"
                            />
                        </div>
                        <button
                            type="submit"
                            class="btn btn-danger w-100"
                            :disabled="isLoading"
                        >
                            {{ $t("addNews") }}
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                        <br /><br /><br />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import app from "@/firebase";
import { uid } from "uid";
//login
import {
    getFirestore,
    collection,
    Timestamp,
    doc,
    setDoc,
    deleteDoc,
} from "firebase/firestore/lite";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default {
    name: "NewsAdd",
    data() {
        return {
            titleKu: null,
            titleAr: null,
            titleEn: null,
            descKu: "",
            descAr: "",
            descEn: "",
            img: null,
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
            },
            isLoading: false,
            error: false,
        };
    },
    methods: {
        async add() {
            this.isLoading = true;
            let image = null;
            const storage = getStorage(app);
            const id = uid(16);
            if (this.img != null && this.img) {
                const storageRef = ref(storage, "/News Images/" + id + ".png");
                let snapshot = await uploadBytes(storageRef, this.img);
                image = await getDownloadURL(snapshot.ref);
            }

            const db = getFirestore(app);
            const groceriesColRef = doc(collection(db, "news"), id);
            return setDoc(groceriesColRef, {
                id: id,
                titleKu: this.titleKu,
                titleAr: this.titleAr,
                titleEn: this.titleEn,
                descKu: this.descKu,
                descAr: this.descAr,
                descEn: this.descEn,
                date: Timestamp.now(),
                image: image,
            })
                .then(() => {
                    this.isLoading = false;
                    this.$router.push({
                        name: "NewsList",
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoading = false;
                    this.error = false;
                });
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.img = files[0];
        },
    },
};
</script>

<style></style>
