<template>
    <section class="myMobNav">
        <nav
            class="navbar navbar-expand-lg navbar-light bg-light py-0"
            v-if="this.$store.getters['auth/isLoggedIn']"
        >
            <div class="container">
                <p
                    class="navbar-brand px-2 animate__animated animate__fadeIn animate__slower"
                    style="margin: 0"
                    v-if="this.$store.getters['auth/isLoggedIn']"
                >
                    <router-link
                        :to="{ name: 'Dashboard' }"
                        class="btn btn-success"
                        >{{ $t("dashboard") }}
                    </router-link>
                </p>
                <!-- <button
                        class="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span class="navbar-toggler-icon"></span>
                    </button> -->
                <div
                    class="collapse navbar-collapse"
                    id="navbarSupportedContent"
                >
                    <button
                        v-if="this.$store.getters['auth/isLoggedIn']"
                        class="btn btn-danger px-2 d-flex align-items-center"
                        style="color: white !important"
                        @click="doLogout"
                    >
                        {{ $t("logout") }}
                    </button>
                </div>
            </div>
        </nav>
        <div class="navbarbb">
            <div
                class="collapse"
                id="navbarToggleExternalContents"
                :transition-style="
                    $store.getters['language/language'] === 'en'
                        ? 'in:wipe:bottom-left'
                        : 'in:wipe:bottom-right'
                "
            >
                <!--Waves Container-->
                <div>
                    <svg
                        class="waves"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28"
                        preserveAspectRatio="none"
                        shape-rendering="auto"
                    >
                        <defs>
                            <path
                                id="gentle-wave"
                                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                            />
                        </defs>
                        <g class="parallax">
                            <use
                                xlink:href="#gentle-wave"
                                x="48"
                                y="0"
                                fill="rgba(255,255,255,0.7"
                            />
                            <use
                                xlink:href="#gentle-wave"
                                x="48"
                                y="3"
                                fill="rgba(255,255,255,0.5)"
                            />
                            <use
                                xlink:href="#gentle-wave"
                                x="48"
                                y="5"
                                fill="rgba(255,255,255,0.3)"
                            />
                            <use
                                xlink:href="#gentle-wave"
                                x="48"
                                y="7"
                                fill="#fff"
                            />
                        </g>
                    </svg>
                </div>
                <!--Waves end-->

                <div class="navBG p-4" style="height: 100vh">
                    <div class="container" style="padding-top: 100px">
                        <div class="row" style="background-color: transparent">
                            <div class="col-12">
                                <ul
                                    class="nav nav-tabs pt-3"
                                    id="mobileTab"
                                    role="tablist"
                                >
                                    <p
                                        class="mx-2"
                                        v-if="
                                            this.$store.getters[
                                                'auth/isLoggedIn'
                                            ]
                                        "
                                    >
                                        <router-link
                                            :to="{ name: 'Dashboard' }"
                                            class="btn btn-success"
                                            >{{ $t("dashboardText") }}
                                        </router-link>
                                    </p>
                                    <li class="nav-item" role="presentation">
                                        <button
                                            v-if="
                                                this.$store.getters[
                                                    'auth/isLoggedIn'
                                                ]
                                            "
                                            class="btn btn-warning xx-2 active"
                                            style="color: white !important"
                                            @click="doLogout"
                                        >
                                            {{ $t("logout") }}
                                        </button>
                                    </li>
                                    <li
                                        class="nav-item"
                                        role="presentation"
                                    ></li>
                                </ul>
                            </div>
                            <div class="col-12">
                                <div class="tab-content" id="mobileTabContent">
                                    <div
                                        class="tab-pane fade show active"
                                        id="home-mobile"
                                        role="tabpanel"
                                        aria-labelledby="home-tab"
                                    >
                                        <div class="row">
                                            <div class="col-lg-9 p-2 d-flex">
                                                <div class="content">
                                                    <div class="row">
                                                        <div
                                                            class="col-lg-4 py-1 menuLinks"
                                                            v-for="(
                                                                navIitem, index
                                                            ) in navbarLinks
                                                                .slice()
                                                                .reverse()"
                                                            :key="index"
                                                        >
                                                            <router-link
                                                                v-if="
                                                                    navIitem.hasDropdown ===
                                                                    false
                                                                "
                                                                :to="{
                                                                    name: navIitem.link,
                                                                }"
                                                                class="btn btn-primary shadow px-4 w-100"
                                                            >
                                                                <i
                                                                    :class="`fas ${navIitem.icon} fa-lg`"
                                                                ></i>
                                                                &nbsp;&nbsp;
                                                                {{
                                                                    navIitem.hasDropdown ===
                                                                    true
                                                                        ? $t(
                                                                              navIitem.title
                                                                          ) +
                                                                          " + "
                                                                        : $t(
                                                                              navIitem.title
                                                                          )
                                                                }}
                                                            </router-link>
                                                            <div
                                                                v-else
                                                                true
                                                                class="btn btn-primary shadow px-4 w-100"
                                                            >
                                                                <i
                                                                    :class="`fas ${navIitem.icon} fa-lg`"
                                                                ></i>
                                                                &nbsp;&nbsp;
                                                                {{
                                                                    navIitem.hasDropdown ===
                                                                    true
                                                                        ? $t(
                                                                              navIitem.title
                                                                          ) +
                                                                          " + "
                                                                        : $t(
                                                                              navIitem.title
                                                                          )
                                                                }}
                                                            </div>

                                                            <div
                                                                v-show="
                                                                    navIitem.hasDropdown ===
                                                                    true
                                                                "
                                                            >
                                                                <div
                                                                    class="sub-menu bg-light mt-3 mb-2 animate__animated animate__fadeIn"
                                                                >
                                                                    <div
                                                                        v-for="(
                                                                            category,
                                                                            catIndex
                                                                        ) in categories"
                                                                        :key="
                                                                            catIndex
                                                                        "
                                                                        class="px-5 py-2"
                                                                    >
                                                                        <router-link
                                                                            :to="{
                                                                                name: 'ProductsList',
                                                                                query: {
                                                                                    id: category.id,
                                                                                },
                                                                            }"
                                                                            style="
                                                                                color: black !important;
                                                                            "
                                                                        >
                                                                            -
                                                                            &nbsp;{{
                                                                                category[
                                                                                    "title" +
                                                                                        $filters.ucFirst(
                                                                                            $store
                                                                                                .getters[
                                                                                                "language/language"
                                                                                            ]
                                                                                        )
                                                                                ]
                                                                            }}
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav class="navbar bg-secondary" id="mobileNav">
                <router-link :to="{ name: 'Home' }" class="logoNavbar ps-2">
                    <img
                        class="navbar-brand"
                        width="100"
                        src="../assets/images/logo.png"
                        alt="alen company"
                    />
                </router-link>
                <div
                    class="right d-flex mx-4 navIcon animate__animated animate__fadeIn"
                    style="color: white; align-items: baseline"
                    v-if="!isOpened"
                >
                    <div class="svgMenuBack">
                        <svg
                            version="1.0"
                            xmlns="http://www.w3.org/2000/svg"
                            width="100pt"
                            height="100pt"
                            viewBox="0 0 998.000000 387.000000"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <g
                                transform="translate(0.000000,387.000000) scale(0.100000,-0.100000)"
                                :fill="navbarFillHexColor || '#e30613'"
                                stroke="none"
                            >
                                <path
                                    d="M5870 3859 c-366 -3 -692 -9 -725 -13 -33 -4 -152 -11 -265 -17 -113
-5 -266 -14 -340 -20 -74 -5 -223 -11 -330 -13 -107 -2 -233 -6 -280 -9 -47
-4 -236 -9 -419 -13 -184 -3 -338 -7 -342 -10 -4 -2 -182 -4 -396 -4 -546 0
-975 -25 -1198 -70 -90 -18 -238 -59 -290 -80 -11 -4 -47 -17 -80 -28 -33 -11
-94 -32 -135 -46 -136 -47 -167 -56 -201 -56 -46 0 -149 -32 -149 -46 0 -13
-53 -47 -89 -59 -31 -9 -142 -85 -163 -109 -10 -12 -15 -28 -12 -36 31 -79 24
-144 -20 -191 -16 -17 -21 -30 -15 -36 11 -11 2 -43 -12 -43 -13 0 -11 18 3
22 9 3 9 6 0 12 -7 4 -12 2 -12 -3 0 -6 -8 -11 -17 -11 -15 -1 -14 -4 6 -21
26 -21 27 -15 -17 -106 -8 -18 -21 -33 -29 -33 -7 0 -13 -4 -13 -10 0 -5 5
-10 10 -10 6 0 3 -11 -7 -24 -13 -18 -24 -23 -40 -19 -18 4 -23 1 -23 -16 0
-12 -4 -21 -10 -21 -5 0 -9 -3 -7 -8 1 -4 -10 -21 -25 -37 -16 -16 -28 -36
-28 -43 0 -7 -4 -10 -10 -7 -6 4 -10 -5 -10 -19 0 -16 -6 -26 -15 -26 -14 0
-19 -12 -16 -32 0 -5 -4 -8 -9 -8 -22 0 -20 30 4 67 14 21 26 43 26 50 0 7 6
13 14 13 14 0 66 92 66 116 0 7 4 14 10 16 5 2 13 18 16 37 9 44 -9 35 -34
-17 -23 -48 -45 -86 -53 -92 -4 -3 -10 -16 -14 -30 -8 -28 -50 -94 -77 -122
-23 -23 -58 -91 -81 -158 -17 -48 -18 -71 -13 -180 7 -134 19 -189 48 -232 10
-14 18 -37 18 -50 0 -12 7 -52 16 -88 10 -43 11 -66 4 -68 -5 -2 -10 -24 -10
-48 0 -35 4 -44 17 -44 15 0 15 -1 1 -12 -17 -14 -33 -78 -19 -78 16 0 31 27
25 44 -3 9 -1 16 5 16 14 0 15 47 1 52 -6 2 -6 9 0 18 7 12 10 11 16 -5 4 -11
13 -24 21 -28 7 -4 13 -18 13 -30 0 -13 5 -28 12 -35 9 -9 8 -12 -5 -12 -9 0
-17 -4 -17 -10 0 -5 6 -7 14 -4 8 3 17 0 21 -6 4 -7 -1 -14 -13 -17 -12 -3
-18 -11 -16 -18 3 -7 1 -16 -5 -20 -17 -10 -13 -25 4 -18 13 5 14 2 4 -21 -7
-14 -16 -26 -21 -26 -4 0 -8 -8 -9 -17 0 -13 -3 -12 -10 5 -5 13 -6 35 -3 49
5 18 3 24 -4 19 -7 -4 -9 5 -5 29 5 31 3 35 -12 29 -14 -6 -16 -15 -11 -53 4
-25 8 -57 10 -71 2 -14 4 -31 4 -38 1 -7 7 -10 12 -7 7 4 10 -6 9 -27 -3 -73
13 -142 48 -211 30 -60 36 -83 37 -138 1 -50 -3 -72 -16 -86 -16 -17 -25 -48
-62 -213 -8 -36 -9 -69 -4 -95 5 -22 8 -59 6 -82 -2 -34 0 -43 12 -43 9 0 20
-4 26 -9 16 -14 166 -73 234 -91 36 -10 86 -27 113 -39 26 -12 80 -31 120 -42
39 -11 95 -26 122 -34 28 -8 73 -20 100 -26 28 -6 77 -19 110 -29 33 -11 102
-26 153 -35 51 -9 103 -19 115 -24 21 -7 64 -15 232 -42 86 -14 320 -36 520
-49 157 -10 407 -30 710 -55 480 -41 567 -47 800 -61 193 -11 292 -12 435 -4
102 5 271 15 375 20 185 10 411 30 585 50 96 11 269 24 605 45 127 8 327 21
445 30 118 8 265 15 325 15 61 0 171 4 245 10 74 5 241 14 370 20 240 10 395
21 600 42 63 7 133 12 155 11 53 -1 241 15 338 29 59 8 81 15 92 31 15 22 73
29 107 13 11 -5 23 -1 37 13 20 20 48 29 146 50 121 25 273 64 305 78 70 30
78 36 88 58 9 18 8 25 -4 29 -8 3 -17 -1 -20 -9 -4 -9 -20 -15 -43 -15 -20 0
-45 -7 -55 -15 -23 -17 -64 -19 -102 -5 -19 8 -30 7 -42 -2 -11 -9 -62 -14
-171 -15 -209 -2 -498 -12 -541 -18 -19 -2 -64 -6 -100 -9 -36 -2 -73 -7 -83
-11 -26 -11 -44 10 -27 30 9 11 22 14 38 10 30 -7 62 2 62 20 0 18 19 23 98
30 37 3 83 10 102 15 19 5 76 14 125 20 50 7 106 16 125 20 35 9 90 17 305 45
183 23 205 27 205 38 0 10 -60 5 -92 -8 -13 -5 -18 -2 -18 9 0 11 9 16 33 16
19 0 47 7 64 15 16 8 39 16 51 18 12 2 25 6 28 10 4 4 18 7 31 7 13 0 35 7 48
16 13 8 34 14 47 13 13 -2 38 3 55 10 18 7 47 11 65 8 26 -3 40 2 66 24 22 20
44 29 67 29 18 0 36 4 40 10 3 5 12 7 19 4 8 -3 22 3 32 14 11 11 28 22 39 25
14 3 11 5 -10 6 -38 2 -63 17 -50 30 6 5 33 19 60 30 50 21 50 21 10 13 -69
-12 -315 -5 -331 10 -6 7 29 20 42 16 5 -1 22 9 37 24 28 27 71 37 217 53 30
3 84 15 120 25 95 27 36 25 -145 -5 -176 -30 -295 -27 -295 6 0 24 25 36 87
45 31 4 61 15 78 30 16 13 38 24 51 24 13 0 26 5 29 10 3 5 23 10 43 10 39 1
189 33 199 44 3 3 -4 9 -17 14 -21 8 -19 10 22 25 24 9 51 17 60 17 9 0 20 5
23 10 6 9 -16 12 -138 14 -46 1 -48 11 -4 21 17 4 35 9 40 10 4 1 14 3 22 4 8
0 15 6 15 12 0 12 -176 7 -231 -6 -34 -9 -47 19 -23 46 18 20 6 27 -13 8 -7
-7 -17 -8 -29 -1 -11 5 -47 13 -82 17 -61 7 -63 8 -41 23 12 9 36 17 52 19 16
2 46 10 66 19 20 9 51 22 69 30 18 8 41 14 52 14 10 0 22 4 25 10 3 5 23 10
43 10 46 1 131 23 140 37 8 11 20 14 185 44 103 19 144 32 159 54 11 15 0 19
-17 5 -9 -8 -15 -8 -15 -2 0 8 -10 8 -32 0 -24 -8 -33 -8 -36 0 -2 8 -23 10
-65 5 -62 -6 -62 -6 -44 15 10 11 14 23 9 26 -10 6 -101 0 -149 -10 -20 -4
-33 -3 -33 4 0 5 17 13 38 17 20 3 46 12 57 19 11 6 62 25 113 41 50 16 92 32
92 35 0 3 -24 4 -52 3 -57 -2 -108 15 -86 29 7 4 23 8 36 8 32 0 27 20 -5 20
l-28 1 31 13 c33 15 53 46 29 46 -12 0 -65 -10 -157 -30 -20 -4 -40 -5 -43 -1
-4 3 -17 6 -28 6 -15 0 -23 7 -25 21 -3 22 16 30 98 40 43 5 110 26 110 34 0
5 19 11 43 15 173 27 314 63 347 88 l25 20 -45 -7 c-25 -4 -135 -10 -245 -14
-213 -6 -243 -7 -345 -2 -36 1 -69 1 -75 -1 -5 -2 -10 1 -10 6 0 6 4 9 9 7 4
-1 14 2 21 8 12 10 32 15 170 40 68 12 128 32 140 46 6 6 39 18 74 25 36 8 70
20 78 27 7 7 13 11 13 9 0 -5 58 13 106 33 17 7 36 22 43 32 10 17 7 18 -40
18 -45 0 -50 2 -39 15 7 8 20 15 30 15 9 0 20 3 24 7 4 4 19 9 34 11 15 2 29
6 32 9 6 6 -62 0 -163 -16 -77 -12 -190 -9 -209 6 -7 6 -44 11 -83 12 -38 2
-71 4 -73 6 -9 9 33 33 68 39 30 5 40 11 37 20 -4 10 3 13 29 11 19 -2 32 1
29 6 -4 5 6 9 22 9 24 1 88 27 73 30 -61 13 -60 13 -200 0 -63 -6 -148 -12
-189 -15 -40 -2 -78 -8 -85 -13 -6 -5 -20 -6 -31 -2 -18 7 -16 9 12 15 18 4
40 16 49 26 9 11 29 19 44 19 16 0 30 6 33 14 6 15 155 46 220 46 24 0 44 7
57 20 11 11 43 24 73 29 40 7 17 8 -100 7 -94 0 -153 -5 -153 -11 0 -5 -5 -2
-11 8 -13 22 -11 24 24 31 44 8 70 17 97 36 14 9 51 22 83 29 32 7 57 14 55
16 -5 5 -207 17 -271 16 -74 -1 -66 13 15 28 35 7 70 16 80 21 9 5 51 12 94
16 91 8 156 26 162 44 4 10 -15 12 -89 8 -52 -3 -141 1 -199 7 -58 7 -109 10
-115 6 -5 -3 -52 -6 -103 -8 -82 -1 -93 1 -102 18 -8 13 -7 25 0 39 13 25 -6
35 -50 26 -34 -6 -80 11 -80 31 0 7 8 13 18 13 10 0 22 4 28 9 5 5 26 14 47
21 20 6 37 17 37 25 0 17 23 25 70 25 65 0 215 54 203 74 -4 6 2 4 12 -4 17
-14 18 -13 12 2 -4 11 0 18 11 21 9 2 -3 2 -28 0 -25 -3 -48 -10 -51 -15 -4
-6 -8 -5 -11 3 -4 11 -11 12 -34 4 -18 -7 -51 -8 -89 -3 -33 5 -71 6 -84 3
-33 -8 -182 6 -175 16 3 5 17 9 32 9 15 1 45 8 67 16 45 18 98 30 170 40 108
14 4 21 -246 16 -186 -3 -258 -2 -263 7 -4 6 -4 11 1 12 4 0 24 3 43 5 19 3
64 8 100 12 36 4 63 11 60 17 -3 5 -12 10 -20 11 -8 2 -33 5 -55 9 -22 3 -64
5 -93 5 -29 0 -58 2 -63 6 -25 15 66 69 174 101 26 8 21 23 -8 23 -15 0 -25 6
-25 14 0 8 -11 17 -25 20 -14 4 -23 11 -20 16 10 16 -30 19 -250 24 -115 3
-223 7 -240 9 -16 3 -63 6 -103 6 -41 1 -71 5 -68 10 9 14 104 39 168 45 79 6
95 19 33 27 -27 3 -257 6 -511 7 -309 0 -468 3 -481 10 -13 7 -173 13 -454 16
-409 5 -564 14 -449 28 25 3 99 6 165 8 66 1 125 6 132 12 7 5 20 7 31 4 15
-5 143 -8 202 -5 8 0 93 -1 189 -2 114 -1 175 2 180 9 3 6 29 12 56 14 80 5
-773 4 -1495 -3z m459 -48 c17 -11 10 -13 -93 -31 -110 -19 -205 -9 -164 16 7
5 60 11 118 15 58 3 110 6 115 7 6 1 17 -2 24 -7z m3154 -1108 c-7 -2 -19 -2
-25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-1486 -99 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m-7873 -76 c7 -29 -4 -99 -15 -92 -5 2 -8 -11 -7
-30 1 -19 -4 -41 -10 -48 -7 -9 -8 -19 -2 -28 6 -8 10 -40 10 -70 0 -30 5 -84
12 -120 6 -36 12 -76 12 -90 1 -14 5 -31 10 -39 10 -16 11 -129 1 -161 -3 -11
-13 -20 -21 -20 -10 0 -13 9 -9 35 2 19 0 35 -5 35 -6 0 -10 7 -10 15 0 8 6
12 13 9 20 -7 12 107 -9 123 -13 9 -24 57 -26 113 0 8 -5 21 -10 27 -6 7 -7
21 -3 30 4 10 8 52 10 93 1 41 7 98 14 125 7 28 15 65 18 83 6 37 18 42 27 10z
m9133 -64 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m13 -494 c0
-5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-1190 -40
c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-7945
-140 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z
m9440 -79 c-6 -5 -21 -12 -35 -15 -24 -7 -24 -6 -6 8 21 17 57 23 41 7z m-75
-11 c-8 -5 -19 -10 -25 -10 -5 0 -3 5 5 10 8 5 20 10 25 10 6 0 3 -5 -5 -10z
m-9393 -232 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m9043 -132
c0 -2 -7 -6 -15 -10 -8 -3 -15 -1 -15 4 0 6 7 10 15 10 8 0 15 -2 15 -4z
m-3680 -82 c0 -2 -16 -4 -36 -4 -19 0 -33 4 -30 8 5 8 66 4 66 -4z m657 -1046
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"
                                />
                                <path
                                    d="M7438 3863 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"
                                />
                                <path
                                    d="M9870 3140 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"
                                />
                                <path
                                    d="M9723 3115 c-43 -19 -42 -25 2 -11 19 6 35 14 35 18 0 10 2 10 -37
-7z"
                                />
                                <path
                                    d="M9585 3103 c-11 -3 -24 -9 -29 -14 -17 -15 37 -10 58 6 20 15 12 18
-29 8z"
                                />
                                <path
                                    d="M9445 3070 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"
                                />
                                <path
                                    d="M344 2943 c-26 -24 -57 -73 -50 -80 7 -7 41 32 61 70 18 31 15 34
-11 10z"
                                />
                                <path
                                    d="M295 2830 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"
                                />
                                <path
                                    d="M9008 733 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"
                                />
                                <path
                                    d="M8655 694 c-18 -14 -18 -15 -2 -10 10 3 37 6 59 6 48 0 47 5 -2 14
-26 4 -42 2 -55 -10z"
                                />
                            </g>
                        </svg>
                    </div>
                    <button
                        class="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarToggleExternalContents"
                        aria-controls="navbarToggleExternalContents"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        style="border: none"
                        :style="[
                            navbarAllTextColor
                                ? 'color:' + navbarAllTextColor + '!important'
                                : 'color:' + 'white',
                        ]"
                        @click="openCollapse"
                    >
                        <p class="h5 d-inline-block pe-1">
                            <b>{{ $t("menu") }}</b>
                        </p>
                        &nbsp;<i class="fa fa-bars fontBold fa-md"></i>
                    </button>
                </div>
                <div
                    class="right d-flex pe-2 animate__animated animate__fadeIn"
                    style="align-items: baseline"
                    :style="[
                        navbarAllTextColor
                            ? 'color:' + navbarAllTextColor + '!important'
                            : 'color:' + 'white',
                    ]"
                    v-else
                >
                    <div class="px-1"></div>

                    <img
                        alt="lang"
                        @click.prevent="changeLanguage('en')"
                        class="mx-1 lang"
                        width="18"
                        src="../assets/images/flags/en.svg"
                    />
                    <img
                        alt="lang"
                        @click.prevent="changeLanguage('ar')"
                        class="mx-1 lang"
                        width="18"
                        src="../assets/images/flags/ar.svg"
                    />
                    <img
                        alt="lang"
                        @click.prevent="changeLanguage('ku')"
                        class="mx-1 lang"
                        width="18"
                        src="../assets/images/flags/ku.svg"
                    />
                    <div class="px-1"></div>
                    <button
                        class="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarToggleExternalContents"
                        aria-controls="navbarToggleExternalContents"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        style="border: none"
                        :style="[
                            navbarAllTextColor
                                ? 'color:' + navbarAllTextColor + '!important'
                                : 'color:' + 'white',
                        ]"
                        @click="openCollapse"
                    >
                        <p class="h5 d-inline-block pe-1">
                            <b>{{ $t("close") }}</b>
                        </p>
                        <i class="fa fa-close fa-lg px-2"></i>
                    </button>
                </div>
            </nav>
        </div>
    </section>
</template>

<script>
import { getDocs, getFirestore, collection } from "firebase/firestore";
import { app } from "@/firebase";

export default {
    name: "Navbar",
    components: {},
    props: ["navbarFillHexColor", "navbarAllTextColor"],
    watch: {
        $route() {
            if (
                document
                    .getElementById("navbarToggleExternalContents")
                    .classList.contains("show")
            ) {
                document
                    .getElementById("navbarToggleExternalContents")
                    .classList.remove("show");
                this.openCollapse();
            }
        },
    },
    data() {
        return {
            isOpened: true,
            lang: "EN",
            navbarLinks: [
                {
                    title: "contactUs",
                    hasDropdown: false,
                    icon: "fa-phone",
                    link: "ContactUs",
                },
                {
                    title: "aboutUs",
                    hasDropdown: false,
                    icon: "fa-map",
                    link: "AboutUs",
                },
                // {
                //     title: "careers",
                // hasDropdown: false,
                //     icon: "fa-briefcase",
                //     link: "JobsList",
                // },
                {
                    title: "healthCare",
                    hasDropdown: false,
                    icon: "fa-heartbeat",
                    link: "HealthCareList",
                },
                {
                    title: "videos",
                    hasDropdown: false,
                    icon: "fa-video",
                    link: "VideoList",
                },
                {
                    title: "news",
                    hasDropdown: false,
                    icon: "fa-newspaper",
                    link: "NewsList",
                },
                {
                    title: "recipes",
                    hasDropdown: false,
                    icon: "fa-utensils",
                    link: "RecipesList",
                },
                {
                    title: "products",
                    hasDropdown: true,
                    icon: "fa-list",
                    link: "ProductsList",
                },
                {
                    title: "home",
                    hasDropdown: false,
                    icon: "fa-home",
                    link: "Home",
                },
            ],
            categories: [],
        };
    },
    methods: {
        openCollapse() {
            if (
                this.$store.getters["language/language"] === "ku" ||
                this.$store.getters["language/language"] === "ar"
            ) {
                let allBtnPrimaries = document.querySelectorAll(
                    ".myMobNav .btn-primary"
                );
                for (const allBtnPrimary of allBtnPrimaries) {
                    allBtnPrimary.classList.remove("btn-primary");
                    allBtnPrimary.classList.add("btn-primary-rtl");
                }
            } else {
                let allBtnPrimaries = document.querySelectorAll(
                    ".myMobNav .btn-primary-rtl"
                );
                for (const allBtnPrimary of allBtnPrimaries) {
                    allBtnPrimary.classList.remove("btn-primary-rtl");
                    allBtnPrimary.classList.add("btn-primary");
                }
            }
            var collapse = document.getElementById(
                "navbarToggleExternalContents"
            );
            this.isOpened = !this.isOpened;
            setTimeout(() => {
                // console.log("classs = ", collapse.classList);
                if (collapse.classList.contains("show")) {
                    // document.body.style.overflowY = "hidden";
                    document
                        .getElementById("mobileNav")
                        .classList.remove("bg-secondary");
                    document
                        .getElementById("mobileNav")
                        .classList.add("bg-danger3");
                } else {
                    // document.body.style.overflowY = "auto";
                    document
                        .getElementById("mobileNav")
                        .classList.remove("bg-danger3");
                    document
                        .getElementById("mobileNav")
                        .classList.add("bg-secondary");
                }
            }, 400);
        },
        changeLanguage(e) {
            this.$i18n.locale = e;
            this.$store.dispatch("language/CHANGE_LANGUAGE", e).then(() => {
                this.lang =
                    this.$store.getters["language/language"].toUpperCase();
                if (
                    document
                        .getElementById("navbarToggleExternalContents")
                        .classList.contains("show")
                ) {
                    document
                        .getElementById("navbarToggleExternalContents")
                        .classList.remove("show");
                    this.openCollapse();
                }
            });
            // console.log(this.$t('login'));
            // localStorage.setItem('language', e);
            // window.location.reload();
        },
        doLogout() {
            this.$store
                .dispatch("auth/LOGOUT")
                .then(() => {
                    this.$router.push("/");
                    if (
                        document
                            .getElementById("navbarToggleExternalContents")
                            .classList.contains("show")
                    ) {
                        document
                            .getElementById("navbarToggleExternalContents")
                            .classList.remove("show");
                        this.openCollapse();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async getAllCategories() {
            const db = getFirestore(app);
            const docSnap = await getDocs(collection(db, "category"));
            this.categories = docSnap.docs.map((e) => {
                return e.data();
            });
            this.categories.sort((b, a) =>
                a.date > b.date ? 1 : b.date > a.date ? -1 : 0
            );
        },
    },
    mounted() {
        this.openCollapse();
        this.getAllCategories();
    },
};
</script>

<style src="../assets/css/navbar/mobileNavbar.css" scoped></style>
