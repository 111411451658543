<template>
    <section class="VideoShow pt-1 pb-5">
        <!-- <PagesBackground :hexColor="navbarFillHexColor" /> -->
        <div class="container p-1">
            <h1 class="my-4 title">Video Details</h1>
            <div
                class="row d-flex justify-content-center my-5 text-center"
                v-if="noData"
            >
                <h1 style="height: 290px">{{ $t("noData") }}</h1>
                <router-link to="/" class="btn btn-danger w-100">{{
                    $t("backToHome")
                }}</router-link>
            </div>
            <div class="row" v-if="!isLoading">
                <div class="col-lg-6 my-2 text-center">
                    <div class="postVideo">
                        <!-- <video width="420" height="340" controls>
              <source :src="videos.link" type="video/mp4" />
              Your browser does not support the video tag.
            </video> -->
                        <iframe
                            width="620"
                            height="380"
                            frameborder="0"
                            allowfullscreen
                            :src="`https://www.youtube.com/embed/${
                                videos && videos.videoID
                            }`"
                        ></iframe>
                    </div>
                </div>
                <div class="col-lg-6 my-2 p-3">
                    <div
                        class="admin"
                        v-if="this.$store.getters['auth/isLoggedIn']"
                    >
                        <router-link
                            :to="{
                                name: 'VideoEdit',
                                params: { id: `${this.$route.params.id}` },
                            }"
                            v-if="this.$store.getters['auth/isLoggedIn']"
                            class="btn btn-secondary w-100 mt-2 mb-4"
                            style="display: block"
                            >{{ $t("editVideo") }}</router-link
                        >
                        <button
                            @click="deleteVideo"
                            class="btn btn-danger w-100 mt-2 mb-4"
                            style="display: block"
                            :disabled="isLoading"
                            v-if="this.$store.getters['auth/isLoggedIn']"
                        >
                            {{ $t("deleteVideo") }}
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                    </div>
                    <br />
                    <small>{{ $filters.timeConverter(videos.date) }}</small>
                    <br /><br />
                    <p
                        v-html="
                            videos[
                                'desc' +
                                    $filters.ucFirst(
                                        $store.getters['language/language']
                                    )
                            ]
                        "
                        class="postDesc"
                    ></p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import app from "@/firebase";
//login
import {
    getFirestore,
    collection,
    ref,
    getDoc,
    doc,
    deleteDoc,
    setDoc,
} from "firebase/firestore/lite";
import PagesBackground from "@/components/PagesBackground.vue";

export default {
    name: "VideoShow",
    props: ["navbarFillHexColor"],
    data() {
        return {
            videos: {},
            noData: false,
            isLoading: true,
            error: false,
        };
    },
    methods: {
        async deleteVideo() {
            this.isLoading = true;
            const db = getFirestore(app);
            const docRef = doc(db, "videos", this.$route.params.id);
            const docSnap = await deleteDoc(docRef)
                .then(() => {
                    this.isLoading = false;
                    this.$router.push({
                        name: "Dashboard",
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.isLoading = false;
                    this.error = true;
                });
        },
    },
    async mounted() {
        this.isLoading = true;
        const db = getFirestore(app);
        const docRef = doc(db, "videos", this.$route.params.id);
        const docSnap = await getDoc(docRef);
        this.isLoading = false;
        if (docSnap.exists()) {
            this.videos = docSnap.data();
        } else {
            this.noData = true;
        }
    },
    components: { PagesBackground },
};
</script>

<style scoped>
.NewsShow {
    position: relative;
    overflow-x: none;
}

svg {
    filter: drop-shadow(0px 0px 27px #ddd8d8);
}
.title {
    color: #e30613;
    font-weight: 900;
    font-size: 65px;
}
.postVideo {
    height: 400px;
}
.postVideo video {
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    height: 400px;
}

@media only screen and (max-width: 600px) {
    .NewsShow {
        overflow-x: hidden;
    }
    .title {
        font-size: 35px;
    }
    iframe {
        width: 320px !important;
        height: 280px !important;
    }
}
</style>
