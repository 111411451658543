<template>
    <section
        class="pt-5 pb-5"
        :class="[
            $store.getters['language/language'] === 'ar'
                ? 'sliderSectionAr'
                : $store.getters['language/language'] === 'ku'
                ? 'sliderSectionKu'
                : 'sliderSection',
        ]"
        data-aos="fade-down"
    >
        <div class="container">
            <span class="spanTitle" data-aos="fade-left">
                <router-link
                    :to="{
                        name: 'VideoList',
                    }"
                    class="my-4 title"
                    >{{ $t("videos") }}</router-link
                >
            </span>
        </div>
        <div class="laptop-carousel">
            <div class="height"></div>
            <swiper
                :slidesPerView="1"
                :centeredSlides="true"
                :spaceBetween="60"
                :navigation="true"
                :modules="modules"
                data-aos="fade-up"
                class="mySwiper"
            >
                <swiper-slide v-for="(item, index) in videos" :key="index">
                    <div class="row d-flex">
                        <div
                            class="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-start align-items-center py-2"
                        >
                            <br />
                            <div class="px-5">
                                <!-- <h2
                  v-html="
                    $filters.titleLenght(
                      item[
                        'desc' +
                          $filters.ucFirst($store.getters['language/language'])
                      ],
                      100
                    )
                  "
                ></h2>
                <br /> -->
                                <router-link
                                    v-if="
                                        $store.getters['language/language'] ===
                                        'en'
                                    "
                                    :to="{
                                        name: 'VideoList',
                                        params: { id: item.id },
                                    }"
                                    data-aos="fade-right"
                                    data-aos-anchor-placement="bottom-bottom"
                                    class="btn btn-danger w-100"
                                    style="border-radius: 23px; padding: 16px"
                                >
                                    <i class="fa-brands fa-youtube fa-xl"></i>
                                    &nbsp; <b>{{ $t("otherVideos") }}</b> &nbsp;
                                    <i class="fa fa-arrow-right"></i>
                                </router-link>
                                <router-link
                                    v-if="
                                        $store.getters['language/language'] !==
                                        'en'
                                    "
                                    :to="{
                                        name: 'VideoList',
                                        params: { id: item.id },
                                    }"
                                    data-aos="fade-right"
                                    data-aos-anchor-placement="bottom-bottom"
                                    class="btn btn-danger w-100"
                                    style="
                                        border-radius: 23px;
                                        padding: 16px;
                                        direction: rtl;
                                    "
                                >
                                    <i class="fa-brands fa-youtube fa-xl"></i>
                                    &nbsp; <b>{{ $t("otherVideos") }}</b> &nbsp;
                                    <i class="fa fa-arrow-left"></i>
                                </router-link>
                            </div>
                        </div>
                        <div
                            class="col-lg-9 col-md-12 col-sm-12 d-flex justify-content-center"
                        >
                            <iframe
                                width="900"
                                height="600"
                                frameborder="0"
                                allowfullscreen
                                :src="`https://www.youtube.com/embed/${
                                    item && item.videoID
                                }`"
                            ></iframe>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
            <div class="height"></div>
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/grid";
import "swiper/css/navigation";

// import required modules
import { Navigation, Grid } from "swiper";
import {
    doc,
    getDocs,
    getFirestore,
    collection,
    orderBy,
} from "firebase/firestore";
import { app } from "@/firebase";

export default {
    name: "UnderNewsSection",
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Navigation, Grid],
        };
    },
    data() {
        return {
            videos: [],
        };
    },
    async mounted() {
        const db = getFirestore(app);
        const docSnap = await getDocs(collection(db, "videos"));
        this.videos = docSnap.docs.map((e) => {
            return e.data();
        });
        this.videos.sort((b, a) =>
            a.date > b.date ? 1 : b.date > a.date ? -1 : 0
        );
    },
};
</script>

<style src="../assets/css/home/underNewsSection.css" scoped></style>
