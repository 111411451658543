import { app } from "../../firebase";
//login
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

const auth = {
  namespaced: true,
  state: {
    userInfo: {},
    isLoggedIn: false,
  },
  mutations: {
    SET_USER_INFO(state, value) {
      state.userInfo = value;
    },
    SET_IS_LOGGED_IN(state, value) {
      state.isLoggedIn = value;
    },
  },
  actions: {
    LOGIN({ commit }, { email, password }) {
      return new Promise((resolve, reject) => {
        signInWithEmailAndPassword(getAuth(app), email, password)
          .then((res) => {
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("userInfo", res.user);
            commit("SET_USER_INFO", res.user);
            commit("SET_IS_LOGGED_IN", true);
            resolve(res);
          })
          .catch((err) => {
            commit("SET_USER_INFO", {});
            reject(err);
          });
      });
    },
    LOGIN_CHECK({ commit }) {
      return new Promise((resolve, reject) => {
        onAuthStateChanged(getAuth(app), (e) => {
          if (e && e != null) {
            commit("SET_USER_INFO", e);
            commit("SET_IS_LOGGED_IN", true);
          }
          resolve(e);
        });
      });
    },
    LOGOUT({ commit }) {
      return new Promise((resolve, reject) => {
        signOut(getAuth(app))
          .then((res) => {
            localStorage.removeItem("isLoggedIn");
            localStorage.removeItem("userInfo");
            commit("SET_IS_LOGGED_IN", false);
            commit("SET_USER_INFO", null);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    userInfo: (state) => state.userInfo,
    isLoggedIn: (state) => state.isLoggedIn,
  },
};

export default auth;
