<template>
    <div class="add">
        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-8">
                    <br /><br />
                    <h1>{{ $t("settings") }}</h1>
                    <br /><br />
                    <form @submit.prevent="save">
                        <!-- <div class="mb-3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-lg-4">
                                    <label
                                        for="titleDesignKu"
                                        class="form-label"
                                        >{{
                                            $t("contactUsDesignTitleKu")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="titleDesignKu"
                                        class="form-control"
                                        id="titleDesignKu"
                                    />
                                </div>

                                <div class="col-lg-4">
                                    <label
                                        for="titleDesignAr"
                                        class="form-label"
                                        >{{
                                            $t("contactUsDesignTitleAr")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="titleDesignAr"
                                        class="form-control"
                                        id="titleDesignAr"
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <label
                                        for="titleDesignEn"
                                        class="form-label"
                                        >{{
                                            $t("contactUsDesignTitleEn")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="titleDesignEn"
                                        class="form-control"
                                        id="titleDesignEn"
                                    />
                                </div>
                            </div>
                        </div> -->
                        <h2>Block 1</h2>
                        <hr />
                        <div class="mb-3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-lg-4">
                                    <label
                                        for="subTitleDesignKu"
                                        class="form-label"
                                        >{{
                                            $t("contactUsDesignSubTitleKu")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="subTitleDesignKu"
                                        class="form-control"
                                        id="subTitleDesignKu"
                                    />
                                </div>

                                <div class="col-lg-4">
                                    <label
                                        for="subTitleDesignAr"
                                        class="form-label"
                                        >{{
                                            $t("contactUsDesignSubTitleAr")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="subTitleDesignAr"
                                        class="form-control"
                                        id="subTitleDesignAr"
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <label
                                        for="subTitleDesignEn"
                                        class="form-label"
                                        >{{
                                            $t("contactUsDesignSubTitleEn")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="subTitleDesignEn"
                                        class="form-control"
                                        id="subTitleDesignEn"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-lg-4">
                                    <label
                                        for="subTitleDesignKu"
                                        class="form-label"
                                        >{{
                                            $t("contactUsDesignLocationKu")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="locationDesignKu"
                                        class="form-control"
                                        id="locationDesignKu"
                                    />
                                </div>

                                <div class="col-lg-4">
                                    <label
                                        for="locationDesignAr"
                                        class="form-label"
                                        >{{
                                            $t("contactUsDesignLocationAr")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="locationDesignAr"
                                        class="form-control"
                                        id="locationDesignAr"
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <label
                                        for="locationDesignEn"
                                        class="form-label"
                                        >{{
                                            $t("contactUsDesignLocationEn")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="locationDesignEn"
                                        class="form-control"
                                        id="locationDesignEn"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-lg-4">
                                    <label
                                        for="email"
                                        class="form-label"
                                        >{{
                                            $t("contactUsDesignEmail")
                                        }}</label
                                    >
                                    <input
                                        type="email"
                                        required
                                        v-model="email"
                                        class="form-control"
                                        id="email"
                                    />
                                </div>

                                <div class="col-lg-4">
                                    <label
                                        for="phoneOne"
                                        class="form-label"
                                        >{{
                                            $t("contactUsDesignPhoneOne")
                                        }}</label
                                    >
                                    <input
                                        type="number"
                                        required
                                        v-model="phoneOne"
                                        class="form-control"
                                        id="phoneOne"
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <label
                                        for="phoneTwo"
                                        class="form-label"
                                        >{{
                                            $t("contactUsDesignPhoneTwo")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="phoneTwo"
                                        class="form-control"
                                        id="phoneTwo"
                                    />
                                </div>
                            </div>
                            <div class="mb-3">
                                <div class="row d-flex justify-content-start">
                                    <div class="col-lg-12">
                                        <label for="image" class="form-label">{{
                                            $t("attachment")
                                        }}</label>
                                        <input
                                            type="file"
                                            @change="onFileChangeBlockOne"
                                            class="form-control"
                                            id="image"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br />
                        <h2>Block 2</h2>
                        <hr />
                        <div class="mb-3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-lg-4">
                                    <label
                                        for="subTitleDesignBlockTwoKu"
                                        class="form-label"
                                        >{{
                                            $t("contactUsDesignSubTitleKu")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="subTitleDesignBlockTwoKu"
                                        class="form-control"
                                        id="subTitleDesignBlockTwoKu"
                                    />
                                </div>

                                <div class="col-lg-4">
                                    <label
                                        for="subTitleDesignBlockTwoAr"
                                        class="form-label"
                                        >{{
                                            $t("contactUsDesignSubTitleAr")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="subTitleDesignBlockTwoAr"
                                        class="form-control"
                                        id="subTitleDesignBlockTwoAr"
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <label
                                        for="subTitleDesignBlockTwoEn"
                                        class="form-label"
                                        >{{
                                            $t("contactUsDesignSubTitleEn")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="subTitleDesignBlockTwoEn"
                                        class="form-control"
                                        id="subTitleDesignBlockTwoEn"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-lg-4">
                                    <label
                                        for="descDesignKu"
                                        class="form-label"
                                        >{{
                                            $t("contactUsDesignDescKu")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="descDesignKu"
                                        class="form-control"
                                        id="descDesignKu"
                                    />
                                </div>

                                <div class="col-lg-4">
                                    <label
                                        for="descDesignAr"
                                        class="form-label"
                                        >{{
                                            $t("contactUsDesignDescAr")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="descDesignAr"
                                        class="form-control"
                                        id="descDesignAr"
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <label
                                        for="descDesignEn"
                                        class="form-label"
                                        >{{
                                            $t("contactUsDesignDescEn")
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        required
                                        v-model="descDesignEn"
                                        class="form-control"
                                        id="descDesignEn"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-lg-12">
                                    <label for="image" class="form-label">{{
                                        $t("bottomAttachment")
                                    }}</label>
                                    <input
                                        type="file"
                                        @change="onFileChangeBlockTwo"
                                        class="form-control"
                                        id="image"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- loading and errors show here -->
                        <button
                            type="submit"
                            class="btn btn-danger w-100"
                            :disabled="isLoading"
                        >
                            {{ $t("saveSettings") }}
                        </button>
                        <p class="text-center" v-show="isLoading">
                            {{ $t("loading") }}
                        </p>
                        <p class="text-danger text-center" v-show="error">
                            {{ $t("error4") }}
                        </p>
                        <br /><br /><br />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { app } from "@/firebase";
import {
    getFirestore,
    collection,
    getDoc,
    doc,
    Timestamp,
    setDoc,
} from "firebase/firestore/lite";
import { getStorage, uploadBytes, getDownloadURL, ref } from "firebase/storage";

export default {
    name: "ContactUsSettings",
    data() {
        return {
            // titleDesignKu: "",
            // titleDesignAr: "",
            // titleDesignEn: "",
            
            //block 1
            subTitleDesignKu: "",
            subTitleDesignAr: "",
            subTitleDesignEn: "",
            locationDesignKu: "",
            locationDesignAr: "",
            locationDesignEn: "",
            email: "",
            phoneOne: "",
            phoneTwo: "",
            blockOneImg: null,
            blockOneImage: null,

            //block 2
            subTitleDesignBlockTwoKu: "",
            subTitleDesignBlockTwoAr: "",
            subTitleDesignBlockTwoEn: "",
            descDesignKu: "",
            descDesignAr: "",
            descDesignEn: "",
            blockTwoImg: null,
            blockTwoImage: null,

            //others
            isLoading: false,
            error: false,
            noData: false,
        };
    },
    methods: {
        async save() {
            const id = this.$route.params.id;
            this.isLoading = true;
            const storage = getStorage(app);
            let blockOneImageSendForDatabase = this.blockOneImage;

            if (this.blockOneImg != null && this.blockOneImg) {
                const id = this.$route.params.id + "blockOneImage";
                const storageRef = ref(
                    storage,
                    "/Contact Us Design Images/" + id + ".png"
                );
                let topSnapshot = await uploadBytes(storageRef, this.blockOneImg);
                blockOneImageSendForDatabase = await getDownloadURL(topSnapshot.ref);
            }

            let blockTwoImageSendForDatabase = this.blockTwoImage;
            if (this.blockTwoImg != null && this.blockTwoImg) {
                const id = this.$route.params.id + "blockTwoImage";
                const storageRef = ref(
                    storage,
                    "/Contact Us Design Images/" + id + ".png"
                );
                let bottomSnapshot = await uploadBytes(
                    storageRef,
                    this.blockTwoImg
                );
                blockTwoImageSendForDatabase = await getDownloadURL(
                    bottomSnapshot.ref
                );
            }
            const db = getFirestore(app);
            const groceriesColRef = doc(
                collection(db, "contactUsSettings"),
                id
            );
            return setDoc(groceriesColRef, {
                id: id,
                // titleDesignKu: this.titleDesignKu,
                // titleDesignAr: this.titleDesignAr,
                // titleDesignEn: this.titleDesignEn,
                //block 1
                subTitleDesignKu: this.subTitleDesignKu,
                subTitleDesignAr: this.subTitleDesignAr,
                subTitleDesignEn: this.subTitleDesignEn,
                locationDesignKu: this.locationDesignKu,
                locationDesignAr: this.locationDesignAr,
                locationDesignEn: this.locationDesignEn,
                email: this.email,
                phoneOne: this.phoneOne,
                phoneTwo: this.phoneTwo,
                blockOneImage: blockOneImageSendForDatabase ?? null,
                
                //block 2
                subTitleDesignBlockTwoKu: this.subTitleDesignBlockTwoKu,
                subTitleDesignBlockTwoAr: this.subTitleDesignBlockTwoAr,
                subTitleDesignBlockTwoEn: this.subTitleDesignBlockTwoEn,
                descDesignKu: this.descDesignKu,
                descDesignAr: this.descDesignAr,
                descDesignEn: this.descDesignEn,
                blockTwoImage: blockTwoImageSendForDatabase ?? null,

                //others
                date: Timestamp.now(),
            })
                .then(() => {
                    this.isLoading = false;
                    this.$router.push({
                        name: "ContactUs",
                    });
                })
                .catch((err) => {
                    console.error(err);
                    this.isLoading = false;
                    this.error = false;
                });
        },
        onFileChangeBlockOne(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.blockOneImg = files[0];
        },
        onFileChangeBlockTwo(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.blockTwoImg = files[0];
        },
    },
    async mounted() {
        const db = getFirestore(app);

        const docRef = doc(db, "contactUsSettings", this.$route.params.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            // this.titleDesignKu = data.titleDesignKu;
            // this.titleDesignAr = data.titleDesignAr;
            // this.titleDesignEn = data.titleDesignEn;
            //block 1
            this.subTitleDesignKu = data.subTitleDesignKu;
            this.subTitleDesignAr = data.subTitleDesignAr;
            this.subTitleDesignEn = data.subTitleDesignEn;
            this.locationDesignKu = data.locationDesignKu;
            this.locationDesignAr = data.locationDesignAr;
            this.locationDesignEn = data.locationDesignEn;
            this.email = data.email;
            this.phoneOne = data.phoneOne;
            this.phoneTwo = data.phoneTwo;
            this.blockOneImage = data.blockOneImage;
            
            //block 2
            this.subTitleDesignBlockTwoKu = data.subTitleDesignBlockTwoKu;
            this.subTitleDesignBlockTwoAr = data.subTitleDesignBlockTwoAr;
            this.subTitleDesignBlockTwoEn = data.subTitleDesignEn;
            this.descDesignKu = data.descDesignKu;
            this.descDesignAr = data.descDesignAr;
            this.descDesignEn = data.descDesignEn;
            this.blockTwoImage = data.blockTwoImage;
        } else {
            this.noData = true;
        }
    },
};
</script>

<style></style>
