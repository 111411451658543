<template>
    <section class="AboutUs pt-1 pb-5">
        <!-- <PagesBackground :hexColor="navbarFillHexColor" /> -->
        <div class="container p-1">
            <h1 class="my-4 title">{{ $t("aboutUs") }}</h1>
            <div class="row">
                <!-- <div class="col-lg-3 text-center">
          <div class="productFilter">
            <router-link
              :to="{
                name: 'Home',
              }"
              class="left btn btn-light w-100 px-2 py-4 my-2 d-block"
            >
              <i class="fa fa-headset px-3 fa-lg"></i>
              &nbsp;&nbsp; BAHARAT
            </router-link>
            <router-link
              :to="{
                name: 'Home',
              }"
              class="left btn btn-light w-100 px-2 py-4 my-2 d-block"
            >
              <i class="fa fa-home px-3 fa-lg"></i>
              &nbsp;&nbsp; ALEN
            </router-link>
            <router-link
              :to="{
                name: 'Home',
              }"
              class="left btn btn-light w-100 px-2 py-4 my-2 d-block"
            >
              <i class="fa fa-arrow-right px-3 fa-lg"></i>
              &nbsp;&nbsp; BAHARAT
            </router-link>
            <router-link
              :to="{
                name: 'Home',
              }"
              class="left btn btn-light w-100 px-2 py-4 my-2 d-block"
            >
              <i class="fa fa-bed px-3 fa-lg"></i>
              &nbsp;&nbsp; TEST
            </router-link>
            <router-link
              :to="{
                name: 'Home',
              }"
              class="left btn btn-light w-100 px-2 py-4 my-2 d-block"
            >
              <i class="fa fa-ban px-3 fa-lg"></i>
              &nbsp;&nbsp; LOREM
            </router-link>
            <router-link
              :to="{
                name: 'Home',
              }"
              class="left btn btn-light w-100 px-2 py-4 my-2 d-block"
            >
              <i class="fa fa-arrow-left px-3 fa-lg"></i>
              &nbsp;&nbsp; DOLMA
            </router-link>
            <router-link
              :to="{
                name: 'Home',
              }"
              class="left btn btn-light w-100 px-2 py-4 my-2 d-block"
            >
              <i class="fa fa-bed px-3 fa-lg"></i>
              &nbsp;&nbsp; TEST
            </router-link>
            <router-link
              :to="{
                name: 'Home',
              }"
              class="left btn btn-light w-100 px-2 py-4 my-2 d-block"
            >
              <i class="fa fa-ban px-3 fa-lg"></i>
              &nbsp;&nbsp; LOREM
            </router-link>
            <router-link
              :to="{
                name: 'Home',
              }"
              class="left btn btn-light w-100 px-2 py-4 my-2 d-block"
            >
              <i class="fa fa-arrow-left px-3 fa-lg"></i>
              &nbsp;&nbsp; DOLMA
            </router-link>
            <router-link
              :to="{
                name: 'Home',
              }"
              class="left btn btn-light w-100 px-2 py-4 my-2 d-block"
            >
              <i class="fa fa-bed px-3 fa-lg"></i>
              &nbsp;&nbsp; TEST
            </router-link>
          </div>
        </div> -->
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-12 my-2">
                            <div class="box text-center p-5" :style="{ 'background-image': 'url(' +$filters.getImageUrl(aboutUsSettings[0] && aboutUsSettings[0]['Image']) + ')' }"></div>
                        </div>
                        <div class="col-lg-12 my-3">
                            <div class="boxDescr p-1">
                                <h3>
                                    <b>{{ $t("alenFamilly") }}</b>
                                </h3>
                                <div class="p-4">
                                    <p>
                                        <!-- {{ $t("aboutUsPart1") }} -->
                                        {{
                                            aboutUsSettings[0] &&
                                            aboutUsSettings[0][
                                                "subTitleDesign" +
                                                    $filters.ucFirst(
                                                        $store.getters[
                                                            "language/language"
                                                        ]
                                                    )
                                            ]
                                        }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import PagesBackground from "@/components/PagesBackground.vue";
import { getDocs, getFirestore, collection } from "firebase/firestore";
import { app } from "@/firebase";

export default {
    name: "AboutUs",
    props: ["navbarFillHexColor"],
    data() {
        return {
            aboutUsSettings: [],
        };
    },
    components: { PagesBackground },
    methods: {
        async getAllAboutUsSettings() {
            const db = getFirestore(app);
            const docSnap = await getDocs(collection(db, "aboutUsSettings"));
            this.aboutUsSettings = docSnap.docs.map((e) => {
                return e.data();
            });
        },
    },
    async mounted() {
        await this.getAllAboutUsSettings();
    },
};
</script>

<style scoped>
.AboutUs {
    position: relative;
}

.productContentBody {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}
svg {
    filter: drop-shadow(0px 0px 27px #ddd8d8);
}
.title {
    color: #e30613;
    font-weight: 900;
    font-size: 65px;
}

.left {
    background-color: aliceblue !important;
    color: black;
    border: 1px solid rgb(209, 204, 204);
    border-radius: 17px;
    font-weight: 900;
    text-align: left;
}
.left:hover {
    background-color: #e30613 !important;
    color: white;
    border: 1px solid #e30613;
}
.left:hover i {
    transform: scale(1.3);
}
.btn-light {
    background-color: white !important;
}

.searchInputSpan {
    position: relative;
}
.searchInput {
    padding: 20px;
    border-radius: 17px;
    border: 1px solid rgb(209, 204, 204);
}
.searchInputSpan i {
    position: absolute;
    top: 50%;
    right: 3%;
    transform: translate(-3%, -50%);
}
.box {
    height: 300px;
    position: relative;
    /* background-image: url(../assets/images/img1.jpg); */
    background-size: cover;
    border-radius: 17px;
}

@media only screen and (max-width: 600px) {
    .AboutUs {
        overflow-x: hidden;
    }
    .title {
        font-size: 35px;
    }
    .productFilter {
        display: inline-flex;
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 0 !important;
    }
    .productFilter a {
        margin-right: 10px !important;
        min-width: 230px;
        padding-top: 40px !important;
        padding-bottom: 40px !important;
        border-radius: 25px;
    }
    .buttons {
        position: absolute;
        top: 80%;
        right: -44%;
        z-index: 4;
        transform: translate(-50%, -35%);
    }
    .roundedStart {
        border-bottom-left-radius: 0px !important;
        border-top-right-radius: 17px !important;
        border-top-left-radius: 17px !important;
    }
}
.card {
    border-radius: 17px !important;
}
.roundedStart {
    border-bottom-left-radius: 17px;
    border-top-left-radius: 17px;
}
</style>
