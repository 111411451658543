<template>
    <div class="ChangePassword">
        <div class="container">
            <div class="row">
                <div class="col-12 p-5">
                    <form @submit.prevent="changePassword">
                        <div class="mb-3">
                            <label for="newPasswordLabel" class="form-label"
                                ><b>{{ $t("newPassword") }}</b></label
                            >
                            <input
                                type="password"
                                required
                                v-model="newPassword"
                                class="form-control"
                                id="newPasswordLabel"
                            />
                        </div>
                        <button type="submit" class="btn btn-primary">
                            {{ $t("changePassword") }}
                        </button>
                        <div
                            v-if="error || success"
                            class="alert my-4"
                            :class="success ? 'alert-success' : 'alert-danger'"
                            role="alert"
                        >
                            {{
                                success
                                    ? $t("changePasswordSuccess")
                                    : errorString
                            }}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { app } from "@/firebase";
import { getAuth, updatePassword } from "firebase/auth";

export default {
    name: "ChangePassword",
    data() {
        return {
            newPassword: "",
            success: false,
            error: false,
            errorString: "",
        };
    },
    methods: {
        async changePassword() {
            const user = getAuth(app).currentUser;
            console.log(user);
            try {
                await updatePassword(user, this.newPassword);
                this.success = true;
                this.error = false;
                setTimeout(() => {
                    this.doLogout();
                }, 4000);
            } catch (error) {
                this.success = false;
                this.error = true;
                this.errorString = error.message;
            }
        },
        doLogout() {
            this.$store
                .dispatch("auth/LOGOUT")
                .then(() => {
                    this.$router.push("/");
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style scoped></style>
