<template>
    <div class="info pb-5 pt-4">
        <div class="container">
            <span class="spanTitle" data-aos="fade-up">
                <router-link
                    :to="{
                        name: 'HealthCareList',
                    }"
                    class="my-4 title"
                    >{{ $t("healthCareU") }}</router-link
                >
            </span>
            <div class="row pt-5">
                <div class="col-lg-3 text-center">
                    <p data-aos="fade-left" data-aos-duration="600">
                        <b>{{ $t("healthCareText1") }}</b>
                        <!-- <b>
                            {{
                                healthCareSettings[0] &&
                                healthCareSettings[0][
                                    "titleOne" +
                                        $filters.ucFirst(
                                            $store.getters["language/language"]
                                        )
                                ]
                            }}
                        </b> -->
                    </p>
                    <br />
                    <p data-aos="fade-left" data-aos-duration="800">
                        <!-- <b>{{ $t("healthCareText2") }}</b> -->
                        <b>
                            {{
                                healthCareSettings[0] &&
                                healthCareSettings[0][
                                    "titleTwo" +
                                        $filters.ucFirst(
                                            $store.getters["language/language"]
                                        )
                                ]
                            }}
                        </b>
                    </p>
                    <br /><br />
                    <router-link
                        v-if="$store.getters['language/language'] === 'en'"
                        :to="{
                            name: 'HealthCareList',
                        }"
                        data-aos="fade-left"
                        data-aos-duration="900"
                        class="btn btn-danger w-100"
                        style="border-radius: 23px; padding: 16px"
                    >
                        <b>{{ $t("seeMore") }}</b> &nbsp;
                        <i class="fa fa-arrow-right"></i>
                    </router-link>
                    <router-link
                        v-if="$store.getters['language/language'] !== 'en'"
                        :to="{
                            name: 'HealthCareList',
                        }"
                        class="btn btn-danger w-100"
                        style="border-radius: 23px; padding: 16px"
                    >
                        <b>{{ $t("seeMore") }}</b> &nbsp;
                        <i class="fa fa-arrow-left"></i>
                    </router-link>
                </div>
                <div class="col-lg-9">
                    <div class="row d-flex justify-content-center">
                        <!-- <div > -->
                        <div
                            v-for="(item, index) in healthcares.slice(0, 6)"
                            :key="index"
                            class="col-lg-4 col-6 py-2 d-flex justify-content-center"
                            data-bs-toggle="modal"
                            :data-bs-target="`#exampleModal${item.id}`"
                        >
                            <div
                                class="cover layout layout1 justify-content-center text-center"
                            >
                                <div class="productContent my-3">
                                    <!-- <svg
                                        id="visual"
                                        viewBox="0 0 347 350"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                    >
                                        <g
                                            transform="translate(167.60003655662177 164.3849710298307)"
                                        >
                                            <path
                                                d="M82 -82.6C103.1 -60.8 115.1 -30.4 125.4 10.4C135.8 51.1 144.6 102.2 123.4 128.2C102.2 154.2 51.1 155.1 1.4 153.7C-48.3 152.3 -96.6 148.6 -114.7 122.6C-132.8 96.6 -120.6 48.3 -120.1 0.5C-119.7 -47.4 -130.9 -94.8 -112.8 -116.6C-94.8 -138.4 -47.4 -134.7 -8.5 -126.2C30.4 -117.7 60.8 -104.5 82 -82.6"
                                                fill="#FFFFFF"
                                                class="blob"
                                            ></path>
                                        </g>
                                    </svg> -->
                                    <img
                                        :src="
                                            item.image != null ||
                                            item.image != ''
                                                ? item.image
                                                : '../assets/images/logo.png'
                                        "
                                        class="centerXY"
                                        :alt="item.titleEn"
                                        width="140"
                                    />
                                    <div class="productContentBody">
                                        <h5>
                                            <b>
                                                {{
                                                    item[
                                                        "title" +
                                                            $filters.ucFirst(
                                                                $store.getters[
                                                                    "language/language"
                                                                ]
                                                            )
                                                    ]
                                                }}
                                            </b>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="modal fade"
                                :id="`exampleModal${item.id}`"
                                tabindex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                            >
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <button
                                            type="button"
                                            class="btn-close p-3"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                        <div class="modal-body text-center">
                                            <p
                                                v-html="
                                                    item[
                                                        'desc' +
                                                            $filters.ucFirst(
                                                                $store.getters[
                                                                    'language/language'
                                                                ]
                                                            )
                                                    ]
                                                "
                                                class="postDesc"
                                            ></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>
        <br /><br /><br /><br />
    </div>
</template>

<script>
import {
    doc,
    getDocs,
    getFirestore,
    collection,
    deleteDoc,
    orderBy,
} from "firebase/firestore";
import { app } from "@/firebase";

export default {
    name: "InfoSection",
    data() {
        return {
            healthcares: [],
            healthCareSettings: [],
        };
    },
    methods: {
        async getAllHealthCares() {
            const db = getFirestore(app);
            const docSnap = await getDocs(collection(db, "healthcare"));
            this.healthcares = docSnap.docs.map((e) => {
                return e.data();
            });
            this.healthcares.sort((b, a) =>
                a.date > b.date ? 1 : b.date > a.date ? -1 : 0
            );
        },
        async getAllHealthCareSettings() {
            const db = getFirestore(app);
            const docSnap = await getDocs(collection(db, "healthCareSettings"));
            this.healthCareSettings = docSnap.docs.map((e) => {
                return e.data();
            });
        },
    },
    async mounted() {
        await this.getAllHealthCares();
        await this.getAllHealthCareSettings();
    },
};
</script>

<style src="../assets/css/home/infoSection.css" scoped></style>
