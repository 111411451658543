import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index";

import Home from "../views/Home.vue";

//products
import ProductsList from "../views/products/ProductsList.vue";
import ProductShow from "../views/products/ProductShow.vue";
import ProductShowByBarcode from "../views/products/ProductShowByBarcode.vue";
import ProductAdd from "../components/admin/products/ProductAdd.vue";
import ProductEdit from "../components/admin/products/ProductEdit.vue";

//auth
import Login from "../components/admin/login.vue";
import Dashboard from "../components/admin/dashboard.vue";

//password
import ChangePassword from "../components/admin/password/ChangePassword.vue";
import ResetPassword from "../components/admin/password/ResetPassword.vue";

//videos
import VideoList from "../views/videos/VideoList.vue";
import VideoShow from "../views/videos/VideoShow.vue";
import VideoAdd from "../components/admin/videos/VideoAdd.vue";
import VideoEdit from "../components/admin/videos/VideoEdit.vue";

//news
import NewsList from "../views/news/NewsList.vue";
import NewsShow from "../views/news/NewsShow.vue";
import NewsAdd from "../components/admin/news/NewsAdd.vue";
import NewsEdit from "../components/admin/news/NewsEdit.vue";

//recipes
import RecipesList from "../views/recipes/RecipesList.vue";
import RecipesShow from "../views/recipes/RecipesShow.vue";
import RecipesAdd from "../components/admin/recipes/RecipesAdd.vue";
import RecipesEdit from "../components/admin/recipes/RecipesEdit.vue";
import RecipesSettings from "../components/admin/recipes/RecipesSettings.vue";

//health care
import HealthCareList from "../views/healthcare/HealthCareList.vue";
import HealthCareShow from "../views/healthcare/HealthCareShow.vue";
import HealthCareAdd from "../components/admin/healthcare/HealthCareAdd.vue";
import HealthCareEdit from "../components/admin/healthcare/HealthCareEdit.vue";
import HealthCareSettings from "../components/admin/healthcare/HealthCareSettings.vue";

//about us
import AboutUs from "../views/AboutUs.vue";
import AboutUsSettings from "../components/admin/aboutus/AboutUsSettings.vue";

//contact us
import ContactUs from "../views/ContactUs.vue";
import ContactUsSettings from "../components/admin/contactus/ContactUsSettings.vue";

//jobs
import JobsList from "../views/jobs/JobsList.vue";
import JobShow from "../views/jobs/JobShow.vue";
import JobAdd from "../components/admin/jobs/JobAdd.vue";
import JobEdit from "../components/admin/jobs/JobEdit.vue";

//category
import CategoryList from "../components/admin/category/CategoryList.vue";
import CategoryAdd from "../components/admin/category/CategoryAdd.vue";
import CategoryEdit from "../components/admin/category/CategoryEdit.vue";
import CategorySettings from "../components/admin/category/CategorySettings.vue";

//category
import SliderList from "../components/admin/slider/SliderList.vue";
import SliderAdd from "../components/admin/slider/SliderAdd.vue";
import SliderEdit from "../components/admin/slider/SliderEdit.vue";

//sub category
import subCategoryList from "../components/admin/subCategory/subCategoryList.vue";
import subCategoryAdd from "../components/admin/subCategory/subCategoryAdd.vue";
import subCategoryEdit from "../components/admin/subCategory/subCategoryEdit.vue";


const routes = [
    //admin routes
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            scrollPos: {
                top: 0,
                left: 0,
            },
        },
    },
    {
        path: "/admin",
        name: "Login",
        component: Login,
        meta: {
            guest: true,
        },
    },
    {
        path: "/admin/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
            secure: true,
        },
    },
    //products
    {
        path: "/products-list",
        name: "ProductsList",
        component: ProductsList,
    },
    {
        path: "/product-show/:id",
        name: "ProductShow",
        component: ProductShow,
    },
    {
        path: "/barcode-product-show/:barcode",
        name: "ProductShowByBarcode",
        component: ProductShowByBarcode,
    },
    {
        path: "/admin/product-add",
        name: "ProductAdd",
        component: ProductAdd,
        meta: {
            secure: true,
        },
    },
    {
        path: "/admin/product-edit/:id",
        name: "ProductEdit",
        component: ProductEdit,
        meta: {
            secure: true,
        },
    },

    //recipes
    {
        path: "/recipes-list",
        name: "RecipesList",
        component: RecipesList,
    },
    {
        path: "/recipes-show/:id",
        name: "RecipesShow",
        component: RecipesShow,
    },
    {
        path: "/admin/recipes-add",
        name: "RecipesAdd",
        component: RecipesAdd,
        meta: {
            secure: true,
        },
    },
    {
        path: "/admin/recipes-edit/:id",
        name: "RecipesEdit",
        component: RecipesEdit,
        meta: {
            secure: true,
        },
    },
    {
        path: "/admin/recipes-settings/:id",
        name: "RecipesSettings",
        component: RecipesSettings,
        meta: {
            secure: true,
        },
    },

    //health care
    {
        path: "/health-care-list",
        name: "HealthCareList",
        component: HealthCareList,
    },
    {
        path: "/health-care-show/:id",
        name: "HealthCareShow",
        component: HealthCareShow,
    },
    {
        path: "/admin/health-care-add",
        name: "HealthCareAdd",
        component: HealthCareAdd,
        meta: {
            secure: true,
        },
    },
    {
        path: "/admin/health-care-edit/:id",
        name: "HealthCareEdit",
        component: HealthCareEdit,
        meta: {
            secure: true,
        },
    },
    {
        path: "/admin/health-care-settings/:id",
        name: "HealthCareSettings",
        component: HealthCareSettings,
        meta: {
            secure: true,
        },
    },

    //about us
    {
        path: "/about-us",
        name: "AboutUs",
        component: AboutUs,
    },
    {
        path: "/admin/about-us-settings/:id",
        name: "AboutUsSettings",
        component: AboutUsSettings,
        meta: {
            secure: true,
        },
    },

    //contact us
    {
        path: "/contact-us",
        name: "ContactUs",
        component: ContactUs,
    },
    {
        path: "/admin/contact-us-settings/:id",
        name: "ContactUsSettings",
        component: ContactUsSettings,
        meta: {
            secure: true,
        },
    },

    //news
    {
        path: "/news-list",
        name: "NewsList",
        component: NewsList,
    },
    {
        path: "/news-show/:id",
        name: "NewsShow",
        component: NewsShow,
    },
    {
        path: "/admin/news-add",
        name: "NewsAdd",
        component: NewsAdd,
        meta: {
            secure: true,
        },
    },
    {
        path: "/admin/news-edit/:id",
        name: "NewsEdit",
        component: NewsEdit,
        meta: {
            secure: true,
        },
    },

    //jobs
    {
        path: "/jobs-list",
        name: "JobsList",
        component: JobsList,
    },
    {
        path: "/job-show/:id",
        name: "JobShow",
        component: JobShow,
    },
    {
        path: "/admin/job-add",
        name: "JobAdd",
        component: JobAdd,
        meta: {
            secure: true,
        },
    },
    {
        path: "/admin/job-edit/:id",
        name: "JobEdit",
        component: JobEdit,
        meta: {
            secure: true,
        },
    },

    //videos
    {
        path: "/video-list",
        name: "VideoList",
        component: VideoList,
    },
    {
        path: "/video-show/:id",
        name: "VideoShow",
        component: VideoShow,
    },
    {
        path: "/admin/video-add",
        name: "VideoAdd",
        component: VideoAdd,
        meta: {
            secure: true,
        },
    },
    {
        path: "/admin/video-edit/:id",
        name: "VideoEdit",
        component: VideoEdit,
        meta: {
            secure: true,
        },
    },
    {
        path: "/admin/news-edit/:id",
        name: "NewsEdit",
        component: NewsEdit,
        meta: {
            secure: true,
        },
    },

    //slider
    {
        path: "/admin/slider-list",
        name: "SliderList",
        component: SliderList,
        meta: {
            secure: true,
        },
    },
    {
        path: "/admin/slider-add",
        name: "SliderAdd",
        component: SliderAdd,
        meta: {
            secure: true,
        },
    },
    {
        path: "/admin/slider-edit/:id",
        name: "SliderEdit",
        component: SliderEdit,
        meta: {
            secure: true,
        },
    },
    //categories
    {
        path: "/admin/category-list",
        name: "CategoryList",
        component: CategoryList,
        meta: {
            secure: true,
        },
    },
    {
        path: "/admin/category-add",
        name: "CategoryAdd",
        component: CategoryAdd,
        meta: {
            secure: true,
        },
    },
    {
        path: "/admin/category-edit/:id",
        name: "CategoryEdit",
        component: CategoryEdit,
        meta: {
            secure: true,
        },
    },
    {
        path: "/admin/category-settings/:id",
        name: "CategorySettings",
        component: CategorySettings,
        meta: {
            secure: true,
        },
    },
    //sub categories
    {
        path: "/admin/sub-category-list",
        name: "subCategoryList",
        component: subCategoryList,
        meta: {
            secure: true,
        },
    },
    {
        path: "/admin/sub-category-add",
        name: "subCategoryAdd",
        component: subCategoryAdd,
        meta: {
            secure: true,
        },
    },
    {
        path: "/admin/sub-category-edit/:id",
        name: "subCategoryEdit",
        component: subCategoryEdit,
        meta: {
            secure: true,
        },
    },

    //auth password
    {
        path: "/admin/change-password",
        name: "ChangePassword",
        component: ChangePassword,
        meta: {
            secure: true,
        },
    },
    {
        path: "/admin/reset-password",
        name: "ResetPassword",
        component: ResetPassword,
    },
     
    //404 not found and redirect
    {
        path: "/:catchAll(.*)",
        redirect: "/",
    },
];

const scrollBehavior = (to, from, savedPosition) => {
    if (to.name === from.name) {
        to.meta?.scrollPos && (to.meta.scrollPos.top = 0);
        return { left: 0, top: 0 };
    }
    const scrollpos = savedPosition ||
        to.meta?.scrollPos || { left: 0, top: 0 };
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(scrollpos);
        }, 600);
    });
};

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior,
});

export default router;

//api controlling after and before login
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.secure)) {
        if (localStorage.getItem("isLoggedIn") == "false") {
            return next({
                path: "/admin",
            });
        } else {
            return next();
        }
    }
    if (to.matched.some((record) => record.meta.guest)) {
        if (localStorage.getItem("isLoggedIn") == "false") {
            return next();
        } else {
            return next("/");
        }
    }
    return next();
});
