<template>
    <section class="subcategories pb-5 pt-1">
        <div class="container">
            <div class="d-flex justify-content-between">
                <h1 class="text-start my-5 d-inline-block">
                    {{ $t("subCategoryU") }}
                </h1>
                <h1 class="text-start my-5 d-inline-block">
                    <router-link
                        :to="{
                            name: 'subCategoryAdd',
                        }"
                        class="btn btn-secondary"
                        style="position: relative; z-index: 8"
                    >
                        {{ $t("addSubCategory") }}
                    </router-link>
                </h1>
            </div>
            <div class="row" v-if="subcategories.length < 1">
                <div class="col-lg-12 d-flex justify-content-center">
                    <h1>{{ $t("noData") }}e</h1>
                </div>
            </div>
            <div class="row" v-else>
                <div
                    class="col-lg-12"
                    v-if="this.$store.getters['auth/isLoggedIn']"
                >
                    <!-- <router-link
            :to="{
              name: 'CategoryEdit',
              params: { id: item.id },
            }"
          > -->
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">{{ $t("no") }}</th>
                                    <th scope="col">
                                        {{ $t("subCategoryTitleEn") }}
                                    </th>
                                    <th scope="col">
                                        {{ $t("subCategoryTitleKu") }}
                                    </th>
                                    <th scope="col">
                                        {{ $t("subCategoryTitleAr") }}
                                    </th>
                                    <th scope="col">
                                        {{ $t("editSubCategory") }}
                                    </th>
                                    <th scope="col">
                                        {{ $t("deleteSubCategory") }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(item, index) in subcategories.slice(
                                        _start,
                                        _end
                                    )"
                                    :key="index"
                                >
                                    <th>{{ item.id }}</th>
                                    <td>{{ item.titleEn }}</td>
                                    <td>{{ item.titleKu }}</td>
                                    <td>{{ item.titleAr }}</td>
                                    <td>
                                        <router-link
                                            :to="{
                                                name: 'subCategoryEdit',
                                                params: { id: item.id },
                                            }"
                                            class="btn btn-warning"
                                        >
                                            {{ $t("editSubCategory") }}
                                        </router-link>
                                    </td>
                                    <td>
                                        <button
                                            class="btn btn-danger"
                                            @click="deleteProduct(item.id)"
                                        >
                                            {{ $t("deleteSubCategory") }}
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- </router-link> -->
                </div>
            </div>
            <div class="row my-5">
                <div
                    class="col-lg-6 d-flex justify-content-center text-center col-6"
                >
                    <button
                        class="btn btn-danger"
                        :disabled="_start == 0"
                        @click="prevPagination"
                    >
                        {{ $t("prev") }}
                    </button>
                </div>
                <div
                    class="col-lg-6 d-flex justify-content-center text-center col-6"
                >
                    <button
                        class="btn btn-danger"
                        :disabled="_end >= subcategories.length"
                        @click="nextPagination"
                    >
                        {{ $t("next") }}
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {
    doc,
    getDocs,
    getFirestore,
    collection,
    deleteDoc,
    orderBy,
} from "firebase/firestore";
import { app } from "@/firebase";

export default {
    name: "subCategoryList",
    data() {
        return {
            subcategories: [],
            _start: 0,
            _end: 12,
            perPage: 12,
        };
    },
    async mounted() {
        const db = getFirestore(app);
        const docSnap = await getDocs(collection(db, "subCategories"));
        this.subcategories = docSnap.docs.map((e) => {
            return e.data();
        });
        this.subcategories.sort((b, a) =>
            a.date > b.date ? 1 : b.date > a.date ? -1 : 0
        );
    },
    methods: {
        async deleteProduct(e) {
            this.isLoading = true;
            const db = getFirestore(app);
            const docRef = doc(db, "subCategories", e);
            const docSnap = await deleteDoc(docRef)
                .then(async () => {
                    this.isLoading = false;

                    const db = getFirestore(app);
                    const docSnap = await getDocs(
                        collection(db, "subCategories")
                    );
                    if (docSnap.docs != undefined) {
                        this.subcategories = docSnap.docs.map((e) => {
                            return e.data();
                        });
                        this.subcategories.sort((b, a) =>
                            a.date > b.date ? 1 : b.date > a.date ? -1 : 0
                        );
                    } else {
                        this.subcategories = [];
                    }
                })
                .catch((err) => {
                    console.error(err);
                    this.isLoading = false;
                    this.error = true;
                });
        },
        nextPagination() {
            this._start += this.perPage;
            this._end = this._start + this.perPage;
        },
        prevPagination() {
            this._start -= this.perPage;
            this._end -= this.perPage;
        },
    },
};
</script>

<style scoped>
.title {
    color: #e30613;
    font-weight: 900;
    font-size: 95px;
}
@media only screen and (max-width: 600px) {
    .title {
        font-size: 35px;
    }
}
</style>
