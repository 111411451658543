export default {
    alen: "آلين",
    // navbar (navbar && MobileNavbar)
    menu: "القائمة",
    close: "إغلاق",
    home: "الرئیسیة",
    menuText:
        "لقد وضعنا الابتسامات على وجوه الجميع , وجبة خفيفة واحدة في كل مرة , منذ عام 1944. هل تريد اكتشاف قصتنا؟",
    followUs: "اتبعنا",
    news: "الأخبار",
    recipes: "الوصفات",
    healthCare: "الرعاية الصحية",
    careers: "الوظائف",
    products: "المنتجات",
    contactUs: "اتصل بنا",
    aboutUs: "من نحن",

    // Note => (U = upper case)
    //products (Products & ProductList && ProductAdd && ProductEdit && ProductShow)
    productsU: "المنتجات",
    productText1: "ئالين شركة رائدة عالميا في قطاعي الأغذية والصناعة",
    productText2:
        "نحن في عائلة ئالين نستقطب أفضل الخبراء من البلدان الأخرى ونستخدم أفضل المواد الخام في العالم للحصول على أفضل أنواع الطعام , ويعمل موظفونا باستمرار على توفير أعلى مستويات الجودة والإنتاج.",
    allProducts: "جميع المنتجات",
    searchForProducts: "البحث عن المنتجات",
    resetFilters: "إعادة تعيين الفلاتر",
    backToHome: "العودة إلى الصفحة الرئيسية",
    qrCode: "رمز الاستجابة السريعة",
    editProduct: "تحرير المنتج",
    deleteProduct: "حذف المنتج",
    productBarcode: "الباركود المنتج",
    productTitleKu: "عنوان المنتج بالكردي",
    productTitleAr: "عنوان المنتج بالعربي",
    productTitleEn: "عنوان المنتج باللغة بالإنجليزية",
    productDescrKu: "وصف المنتج بالكردي",
    productDescrAr: "وصف المنتج بالعربي",
    productDescrEn: "وصف المنتج باللغة بالإنجليزية",
    productImgHD: "صورة المنتجات (HD)",

    //recipes (underProductSection & RecipesList && RecipesAdd && RecipesEdit && RecipesShow )
    recipesU: "الوصفات",
    recipesText1: "ئالين لديه شيء للجميع!",
    recipesText2:
        "يلتقط ئالين أنواعا مختلفة من الأذواق مع مئات العلامات التجارية. أضف الحلاوة إلى حياتك لكل لحظة. تعرف على عائلتنا الكبيرة",
    editRecipes: "تحرير وصفات",
    deleteRecipes: "حذف الوصفات",
    recipesTitleKu: "عنوان الوصفات بالكردية",
    recipesTitleAr: "عنوان وصفات بالعربية",
    recipesTitleEn: "عنوان الوصفات بالإنجليزية",
    recipesDescrKu: "وصف الوصفات بالكردية",
    recipesDescrAr: "وصف وصفات بالعربية",
    recipesDescrEn: "وصف الوصفات البإنجليزية",
    recipesImg: "تحميل الصور",
    productsUsedInRecipes: "حدد المنتجات المستخدمة في الوصفات",
    recipesDesignSubTitleKu: "العنوان الفرعي لتصميم الوصفات بالكردية",
    recipesDesignSubTitleAr: "العنوان الفرعي لتصميم الوصفات بالعربية",
    recipesDesignSubTitleEn: "العنوان الفرعي لتصميم الوصفات بالإنجليزية" ,
    recipesDesignDescKu: "وصف تصميم الوصفات بالكردية",
    recipesDesignDescAr: "وصف تصميم وصفات بالعربية",
    recipesDesignDescEn: "وصف تصميم الوصفات بالإنجليزية" ,
    topAttachment: "المرفق العلوي" ,
    bottomAttachment: "المرفق السفلي" ,

    //news (News && NewsList && NewsAdd && NewsEdit && NewsShow)
    newsU: "الأخبار",
    seeOur: "انظر آخر أخبارنا.",
    otherNews: "أخبار أخرى",
    editNews: "تحرير الأخبار",
    deleteNews: "حذف الأخبار",
    newsTitleKu: "عنوان الأخبار بالكردية",
    newsTitleAr: "عنوان الخبر بالعربي",
    newsTitleEn: "عنوان الأخبار بالإنجليزية",
    newsDescrKu: "وصف إخباري بالكردية",
    newsDescrAr: "وصف الأخبار بالعربية",
    newsDescrEn: "وصف الأخبار باللغة بالإنجليزية",
    newsImg: "تحميل الصور",

    //videos (UnderNewsSection && UnderNewsMobile && VideoAdd && VideoEdit)
    videos: "الفيديوهات",
    otherVideos: "فيديوهات أخرى",
    editVideo: "تحرير الفيديو",
    deleteVideo: "حذف الفيديو",
    videoBarcode: "الباركود الفيديو",
    videoTitleKu: "عنوان الفيديو بالكردي",
    videoTitleAr: "عنوان الفيديو بالعربي",
    videoTitleEn: "عنوان الفيديو باللغة بالإنجليزية",
    videoURL: "للفيديو URL ",

    //health care (infoSection && HealthCareList && HealthCareAdd && HealthCareEdit && HealthCareShow)
    healthCareU: "الرعاية الصحية",
    healthCareText1:
        "منذ تأسيسنا , رأينا الاستدامة كجزء لا يتجزأ من أعمالنا من خلال فهمنا لجعل السعادة تصبح سعادة. نحن نعمل جنبا إلى جنب مع جميع أصحاب المصلحة لدينا في سلسلة القيمة لدينا للتطور والنمو. ويمكن تصنيف عملنا في هذا المجال تحت ست ركائز: البيئة, وسلسلة القيمة, والموظفين, والابتكار, والمسؤولية الاجتماعية, والقيادة",
    healthCareText2:
        "في هذه الرحلة , نشير إلى الأهداف التي حددناها لعام 2024 ومبادئ الاستدامة التي طورناها في إطار هذه الركائز الست. نحن نراقب عن كثب جداول الأعمال الوطنية والدولية والاتجاهات العالمية مع الرغبة في المساهمة في أهداف الأمم المتحدة للتنمية المستدامة لعام 2030. ونحن نعتقد أن جهود الاستدامة لن تنجح إلا إذا تمكنت جميع الشركات والموظفين من تبنيها",
    editHealthCare: "تحرير الرعاية الصحية",
    deleteHealthCare: "تحذف الرعاية الصحية",
    healthcareTitleKu: "عنوان الرعاية الصحية بالكردية",
    healthcareTitleAr: "عنوان الرعاية الصحية باللغة بالعربية",
    healthcareTitleEn: "عنوان الرعاية الصحية بالإنجليزية",
    healthcareDescrKu: "وصف الرعاية الصحية بالكردية",
    healthcareDescrAr: "وصف الرعاية الصحية بالعربية",
    healthcareDescrEn: "وصف الرعاية الصحية باللغة بالإنجليزية",
    healthcareImg: "تحميل الصور",
    healthCareDesignSubTitleKu: "عنوان تصميم الرعاية الصحية بالكردية",
    healthCareDesignSubTitleAr: "عنوان تصميم الرعاية الصحية بالعربية",
    healthCareDesignSubTitleEn: "عنوان تصميم الرعاية الصحية بالإنجليزية",
    healthCareDesignDescKu: "وصف تصميم الرعاية الصحية بالكردية",
    healthCareDesignDescAr: "وصف تصميم الرعاية الصحية بالعربية",
    healthCareDesignDescEn: "وصف تصميم الرعاية الصحية بالإنجليزية",

    //footer (Footer && MobileFooter)
    footerText: "ئالين عنوان النجاح",
    cr: "2022 TechEye جميع الحقوق محفوظة",

    //dashboard
    dashboardText: "مرحبا بك عزيزي المشرف",
    category: "الفئة",
    jobs: "وظائف",
    sliders: "سلايد",
    subCategory: "الفئة الفرعية",
    addProduct: "إضافة منتج",
    listProducts: "قائمة المنتجات",
    addNews: "إضافة أخبار",
    listNews: "قائمة الأخبار",
    addRecipes: "إضافة وصفات",
    listRecipes: "قائمة وصفات",
    addCategory: "إضافة فئة",
    listCategories: "فئات القائمة",
    addJob: "إضافة وظيفة",
    listJobs: "قائمة الوظائف",
    addSlider: "إضافة شريط سلايد",
    listSliders: " القائمة سلايد ",
    addSubCategory: "إضافة فئة فرعية",
    listSubCategories: "قائمة الفئات الفرعية",
    addVideo: "إضافة فيديو",
    listVideos: "قائمة مقاطع الفيديو",
    addHealthCare: "إضافة الرعاية الصحية",
    listHealthCares: "قائمة الرعاية الصحية",

    //Category (categoryList && categoryAdd && categoryEdit)
    categoryU: "فئات",
    addCategory: "إضافة فئة",
    categoryBackgroundColor: "لون خلفية الفئة",
    categoryTextColor: "لون نص الفئة",
    demoOfYourColors: "عرض توضيحي للون الخاص بك",
    editCategory: "تعديل الفئة",
    deleteCategory: "حذف الفئة",
    categoryTitleKu: "عنوان الفئة بالكردية",
    categoryTitleAr: "عنوان الفئة بالعربية",
    categoryTitleEn: "عنوان الفئة بالإنجليزية",
    categoryDesignSubTitleKu: "عنوان تصميم الفئة بالكردية",
    categoryDesignSubTitleAr: "عنوان تصميم الفئة بالعربية",
    categoryDesignSubTitleEn: "عنوان تصميم الفئة بالإنجليزية",
    categoryDesignDescKu: "وصف تصميم الفئة بالكردية",
    categoryDesignDescAr: "وصف تصميم الفئة بالعربية",
    categoryDesignDescEn: "وصف تصميم الفئة بالإنجليزية",

    //subCategory (subCategoryList && subCategoryAdd && subCategoryEdit)
    subCategoryU: "الفئات الفرعية",
    addSubCategory: "إضافة فئة فرعية",
    editSubCategory: "تحرير الفئة الفرعية",
    deleteSubCategory: "حذف الفئة الفرعية",
    subCategoryTitleKu: "عنوان الفئة الفرعية بالكردية",
    subCategoryTitleAr: "عنوان الفئة الفرعية بالعربية",
    subCategoryTitleEn: "عنوان الفئة الفرعية باللغة بالإنجليزية",

    //Slider (SliderList && SliderAdd && SliderEdit)
    sliderU: "سلايد",
    addSlider: "إضافة سلايد",
    editSlider: "تحرير سلايد",
    deleteSlider: "حذف سلايد",
    sliderPosition: "مكان سلايد",
    sliderImg: "مرفق سلايد",
    sliderTitleKu: "عنوان السلايد بالكردية",
    sliderTitleAr: "عنوان السلايد بالعربية",
    sliderTitleEn: "عنوان السلايد بالإنجليزية",
    sliderDescrKu: "وصف السلايد بالكردية",
    sliderDescrAr: "وصف السلايد بالعربية",
    sliderDescrEn: "وصف السلايد بالإنجليزية",

    //Job (JobList && JobAdd && JobEdit)
    jobU: "وظائف",
    addJob: "إضافة وظيفة",
    editJob: "تحرير الوظيفة",
    deleteJob: "حذف الوظيفة",
    jobTitleKu: "المسمى الوظيفي بالكردية",
    jobTitleAr: "المسمى الوظيفي باللغة العربية",
    jobTitleEn: "المسمى الوظيفي باللغة الإنجليزية",
    jobDescrKu: "الوصف الوظيفي بالكردية",
    jobDescrAr: "الوصف الوظيفي باللغة بالعربية",
    jobDescrEn: "الوصف الوظيفي باللغة بالإنجليزية",
    jobImg: "صورة الوظيفة",

    //Password words
    resetPassword: "إعادة تعيين كلمة المرور",
    resetPasswordSend: "إرسال بريد إلكتروني لإعادة التعيين",
    resetPasswordSuccess:
        "يرجى التحقق من بريدك الإلكتروني (تحقق من البريد العشوائي إذا لم يكن موجودًا في البريد الوارد)",
    resetPasswordFail: "البريد الإلكتروني غير صالح",
    changePassword: "تغيير كلمة المرور",
    newPassword: "كلمة مرور جديدة",
    changePasswordSuccess: "تم تغيير كلمة المرور بنجاح",
    email: "البريد الالكتروني",
    password: "كلمة المرور",

    //global words
    seeMore: "شاهد المزيد",
    next: "التالي",
    prev: "السابق",
    noData: "لا توجد بيانات متاحة",
    error4: "الخطأ 4: يرجى المحاولة مرة أخرى لاحقا",
    thumbnail: "صورة مصغرة",
    loading: "تحميل ...",
    no: "#",
    logout: "تسجيل الخروج",
    attachment: "الصورة",
    language: "اللغة",
    settings: "الاعدادات",
    saveSettings: "حفظ الاعدادات",
    pages: "صفحات",
    barcode: "الباركود",
    

    //awanay dway nardn dastkary krawn

    //aboutUS
    alenFamilly: "عائلة ئالين",
    aboutUsPart1:
        "نحن في عائلة ئالين نجتذب أفضل الخبراء من البلدان الأخرى ونستخدم أفضل المواد الخام في العالم للحصول على أفضل أنواع الطعام ، يعمل موظفونا باستمرار لتوفير أعلى مستويات الجودة والإنتاج. تم تصميم مستودعاتنا بشكل مثالي للحفاظ على اعلى درجات الحماية للمنتجات المنتجة بجميع الالات اللازمة تحت اشراف افضل الخبراء لدينا وكلاء توزيع في جميع انحاء العراق مؤخرا بدأنا بالتصدير الى اوروبا هدفنا الوصول الى كل مطبخ ولمس قلب كل محبي الطعام أفضل طعم. ",
    newsDetails: "تفاصيل الخبر",
    healthCareDetails: "تفاصيل الرعاية الصحية",
    recipesDetails: "تفاصيل الوصفات",
    office: "المكتب",
    ourLocation: "موقعنا",
    productBio: "لدى ALEN طعم مفضل للجميع!",
    productBioDesc:
        "ئالين يلفت الأنظار بأنواع مختلفة من الأذواق مع العشرات من العلامات التجارية التي تضفي لمسة جمالية على حياتك في كل لحظة. تعرف على عائلتنا الكبيرة.",
    address: "العراق ، أربيل ، طريق كركوك",
    noProducts: "لا توجد منتجات",
    noHealthcare: "لا توجد رعاية صحية متاحة",
    noJobs: "لا توجد وظائف متاحة",
    noNews: "لا توجد أخبار",
    noRecipes: "لا توجد وصفات متاحة",
    noVideos: "لا توجد مقاطع فيديو متاحة",
    spiceImage: "صورة البهار",
};
